import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { api } from "api"
import { FeatureState } from "models/FeatureState"
import { AppThunk, RootState } from "app/store"
import {
  InterviewProfile,
  mapToInterviewProfile,
} from "models/InterviewProfile"
import { FileType, PositionDto, SiteDto } from "api/generated/namecheck"

interface InterviewProfileSingleState {
  featureState: FeatureState
  savingState: FeatureState
  positionsState: FeatureState
  sitesState: FeatureState
  item?: InterviewProfile
  positions: PositionDto[]
  sites: SiteDto[]
  actionState: FeatureState
}

const initialState: InterviewProfileSingleState = {
  featureState: FeatureState.Initial,
  savingState: FeatureState.Initial,
  positionsState: FeatureState.Initial,
  sitesState: FeatureState.Initial,
  item: undefined,
  positions: [],
  sites: [],
  actionState: FeatureState.Initial,
}

const interviewProfileSingleSlice = createSlice({
  name: "InterviewProfileSingle",
  initialState,
  reducers: {
    resetData: () => ({ ...initialState }),
    fetchItemStart(state) {
      state.featureState = FeatureState.Loading
    },
    fetchItemSuccess(state, { payload }: PayloadAction<InterviewProfile>) {
      state.item = payload
      state.featureState = FeatureState.Success
    },
    fetchItemFailure(state) {
      state.featureState = FeatureState.Error
    },
    saveItemStart(state) {
      state.savingState = FeatureState.Loading
    },
    saveItemSuccess(state, { payload }: PayloadAction<InterviewProfile>) {
      state.item = payload
      state.savingState = FeatureState.Success
    },
    saveItemFailure(state) {
      state.savingState = FeatureState.Error
    },
    fetchPositionsStart(state) {
      state.positionsState = FeatureState.Loading
    },
    fetchPositionsSuccess(state, { payload }: PayloadAction<PositionDto[]>) {
      state.positions = payload
      state.positionsState = FeatureState.Success
    },
    fetchPositionsFailure(state) {
      state.positionsState = FeatureState.Error
    },
    fetchSitesStart(state) {
      state.sitesState = FeatureState.Loading
    },
    fetchSitesSuccess(state, { payload }: PayloadAction<SiteDto[]>) {
      state.sites = payload
      state.sitesState = FeatureState.Success
    },
    fetchSitesFailure(state) {
      state.sitesState = FeatureState.Error
    },
    setActionState(state, { payload }: PayloadAction<FeatureState>) {
      state.actionState = payload
    },
  },
})

export const interviewProfileSingleSliceReducer =
  interviewProfileSingleSlice.reducer

/**
 * SELECTORS
 */

const isDataLoading = (state: RootState): boolean =>
  state.interviewProfileSingle.featureState === FeatureState.Loading ||
  state.interviewProfileSingle.positionsState === FeatureState.Loading ||
  state.interviewProfileSingle.sitesState === FeatureState.Loading

const itemSelector = (state: RootState): InterviewProfile | undefined =>
  state.interviewProfileSingle.item

const editIsEnabled = (state: RootState): boolean =>
  state.interviewProfileSingle.item?.isInProgress ?? false

const isCvUploaded = (state: RootState): boolean =>
  !!state.interviewProfileSingle.item?.cv

const isIsUploaded = (state: RootState): boolean =>
  !!state.interviewProfileSingle.item?.interviewSheet

export const profileSingleSliceSelectors = {
  isDataLoading,
  data: itemSelector,
  isCvUploaded,
  isIsUploaded,
  editIsEnabled,
}

/**
 * ACTIONS
 */

const {
  fetchItemStart,
  fetchItemFailure,
  fetchItemSuccess,
  saveItemStart,
  saveItemFailure,
  saveItemSuccess,
  resetData,
  fetchPositionsStart,
  fetchPositionsSuccess,
  fetchPositionsFailure,
  fetchSitesStart,
  fetchSitesSuccess,
  fetchSitesFailure,
  setActionState,
} = interviewProfileSingleSlice.actions

const loadData = (id: number): AppThunk => async dispatch => {
  dispatch(fetchItemStart())

  return api.namecheck.interviewProfile
    .get({ id })
    .then(data => {
      const mappedData = mapToInterviewProfile(data)

      dispatch(fetchItemSuccess(mappedData))
    })
    .catch((error: any) => {
      dispatch(fetchItemFailure())

      return Promise.reject(error)
    })
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const saveData = (data: any): AppThunk<Promise<any>> => async dispatch => {
  dispatch(saveItemStart())

  const profileId = data.id
  const {
    positionId,
    salaryExpectationType,
    salaryExpectationValue,
    siteCode,
  } = data

  return Promise.all([
    api.namecheck.interviewProfile.update({
      id: profileId,
      interviewProfileUpdateDto: {
        positionId,
        salaryExpectationType,
        salaryExpectationValue,
        siteCode,
      },
    }),
    // only upload file if new file is added
    data?.cv?.[0]?.originFileObj
      ? api.namecheck.file.uploadFile({
          profileId,
          type: FileType.CV,
          file: data.cv[0].originFileObj,
        })
      : (Promise.resolve() as any),
    // only upload file if new file is added
    data?.interviewSheet?.[0]?.originFileObj
      ? api.namecheck.file.uploadFile({
          profileId,
          type: FileType.InterviewSheet,
          file: data.interviewSheet[0].originFileObj,
        })
      : (Promise.resolve() as any),
  ])
    .then(([savedProfileData]) => {
      const mappedData = mapToInterviewProfile(savedProfileData)

      dispatch(saveItemSuccess(mappedData))
    })
    .catch((error: any) => {
      dispatch(saveItemFailure())

      return Promise.reject(error)
    })
}

const fetchPositions = (): AppThunk => async dispatch => {
  dispatch(fetchPositionsStart())

  return api.namecheck.position
    .getAllPositions()
    .then(data => {
      dispatch(fetchPositionsSuccess(data))
    })
    .catch((error: any) => {
      dispatch(fetchPositionsFailure())

      return Promise.reject(error)
    })
}

const fetchSites = (): AppThunk => async dispatch => {
  dispatch(fetchSitesStart())

  return api.namecheck.position
    .getAllSites()
    .then(data => {
      dispatch(fetchSitesSuccess(data))
    })
    .catch((error: any) => {
      dispatch(fetchSitesFailure())

      return Promise.reject(error)
    })
}

const finalizeItem = (id: number): AppThunk<Promise<any>> => async dispatch => {
  dispatch(setActionState(FeatureState.Loading))

  return api.namecheck.interviewProfile
    .finalize({ id })
    .then(data => {
      dispatch(setActionState(FeatureState.Success))
    })
    .catch((error: any) => {
      dispatch(setActionState(FeatureState.Error))

      return Promise.reject(error)
    })
}

const deleteItem = (id: number): AppThunk<Promise<any>> => async dispatch => {
  dispatch(setActionState(FeatureState.Loading))

  return api.namecheck.interviewProfile
    ._delete({ id })
    .then(data => {
      dispatch(setActionState(FeatureState.Success))
    })
    .catch((error: any) => {
      dispatch(setActionState(FeatureState.Error))

      return Promise.reject(error)
    })
}

export const profileSingleSliceActions = {
  loadData,
  resetData,
  saveData,
  fetchPositions,
  fetchSites,
  finalizeItem,
  deleteItem,
}
