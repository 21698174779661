import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { api } from "api"
import { FeatureState } from "models/FeatureState"
import { AppThunk, RootState } from "app/store"
import { FeaturePagination } from "models/FeaturePagination"
import {
  GetNameCheckListRequest,
  NameCheckDataDto,
} from "api/generated/namecheck"
import { mapToNameCheckData, NameCheckData } from "models/NameCheckData"
import { NameCheckDataDtoPaginatedResultDto } from "api/generated/namecheck/models/NameCheckDataDtoPaginatedResultDto"
import { getInitialPaginationState } from "utils/common"

interface NameCheckListState {
  featureState: FeatureState
  list: NameCheckData[]
  pagination: FeaturePagination
}

const initialState: NameCheckListState = {
  featureState: FeatureState.Initial,
  list: [],
  pagination: getInitialPaginationState(),
}

const nameCheckListSlice = createSlice({
  name: "nameCheckList",
  initialState,
  reducers: {
    reset: () => ({ ...initialState }),
    fetchNameCheckDataStarted(state) {
      state.featureState = FeatureState.Loading
    },
    fetchNameCheckDataSucceeded(
      state,
      { payload }: PayloadAction<NameCheckData[]>
    ) {
      state.list = payload
      state.featureState = FeatureState.Success
    },
    fetchNameCheckDataFailed(state) {
      state.featureState = FeatureState.Error
    },
    setPagination(state, { payload }: PayloadAction<FeaturePagination>) {
      state.pagination = payload
      state.featureState = FeatureState.Success
    },
  },
})

export const nameCheckListSliceReducer = nameCheckListSlice.reducer

/**
 * SELECTORS
 */

const loadingSelector = (state: RootState): boolean =>
  state.nameCheckList.featureState === FeatureState.Loading

export const nameCheckListSliceSelectors = {
  isLoading: loadingSelector,
}

/**
 * ACTIONS
 */

const {
  fetchNameCheckDataStarted,
  fetchNameCheckDataFailed,
  fetchNameCheckDataSucceeded,
  reset,
  setPagination,
} = nameCheckListSlice.actions

const loadData = (
  params: GetNameCheckListRequest = {}
): AppThunk => async dispatch => {
  dispatch(fetchNameCheckDataStarted())

  return api.namecheck.nameCheck
    .getNameCheckList(params)
    .then((data: NameCheckDataDtoPaginatedResultDto) => {
      const { results, ...pagination } = data
      const mappedData =
        results?.map((item: NameCheckDataDto) => mapToNameCheckData(item)) || []

      dispatch(fetchNameCheckDataSucceeded(mappedData))
      dispatch(setPagination(pagination))
    })
    .catch((error: any) => {
      dispatch(fetchNameCheckDataFailed())
      return Promise.reject(error)
    })
}

export const nameCheckListSliceActions = {
  loadData,
  reset,
}
