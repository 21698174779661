/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MatchResult,
    MatchResultFromJSON,
    MatchResultFromJSONTyped,
    MatchResultToJSON,
} from './';

/**
 * 
 * @export
 * @interface NameCheckResultDto
 */
export interface NameCheckResultDto {
    /**
     * 
     * @type {MatchResult}
     * @memberof NameCheckResultDto
     */
    result?: MatchResult;
    /**
     * 
     * @type {string}
     * @memberof NameCheckResultDto
     */
    nameCheckGuid?: string;
}

export function NameCheckResultDtoFromJSON(json: any): NameCheckResultDto {
    return NameCheckResultDtoFromJSONTyped(json, false);
}

export function NameCheckResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): NameCheckResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'result': !exists(json, 'result') ? undefined : MatchResultFromJSON(json['result']),
        'nameCheckGuid': !exists(json, 'nameCheckGuid') ? undefined : json['nameCheckGuid'],
    };
}

export function NameCheckResultDtoToJSON(value?: NameCheckResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'result': MatchResultToJSON(value.result),
        'nameCheckGuid': value.nameCheckGuid,
    };
}


