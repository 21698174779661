import { PositionDto, SiteDto } from "api/generated/namecheck"

export interface PositionEdit extends Omit<PositionDto, "sites"> {
  sites: string[]
}

export const mapToPositionEdit = (dto: PositionDto): PositionEdit => {
  const data: PositionEdit = {
    ...dto,
    sites:
      dto.sites
        ?.map((site: SiteDto) => site.code ?? "")
        ?.filter((code?: string | null) => !!code) ?? [],
  }
  return data
}

export const mapToPositionDto = (data?: PositionEdit): PositionDto => {
  const dto: PositionDto = {
    ...data,
    sites: data?.sites?.map((code?: string) => ({ code })) ?? [],
  }
  return dto
}
