import { Spin } from "antd"
import styles from "./FullPageSpinner.module.scss"
import classNames from "classnames"

interface FullPageSpinnerProps {
  transparent: boolean
}

export const FullPageSpinner: React.FC<FullPageSpinnerProps> = ({
  transparent = false,
}) => (
  <div
    className={classNames(styles.wrapper, {
      [styles.transparent]: transparent,
    })}
  >
    <Spin size="large" />
  </div>
)
