import moment from "moment"

export enum MomentFormats {
  Date = "YYYY.MM.DD.",
  DateTime = "YYYY.MM.DD. HH:mm",
}

export const convertBackendDateToMoment = (
  date?: Date | null | moment.Moment
): moment.Moment | undefined => (date ? moment(date) : undefined)

export const formatMomentDate = (
  date?: moment.Moment,
  format: MomentFormats = MomentFormats.DateTime
): string => (date ? date.format(format) : "")
