import { InterviewProfileStatus } from "api/generated/namecheck"
import { InterviewProfile } from "models/InterviewProfile"
import { getEnumTranslationKey } from "utils/common"

export const getTagColor = (
  status?: InterviewProfileStatus | string
): string => {
  switch (status) {
    case InterviewProfileStatus.InProgress:
      return "geekblue"
    case InterviewProfileStatus.Finalized:
      return "green"
    default:
      return "red"
  }
}

export const getProfileStatusKey = (data?: InterviewProfile): string =>
  data?.isExpired && data?.status === InterviewProfileStatus.InProgress
    ? "Expired"
    : `${data?.status}`

export const getProfileStatusTranslationKey = (
  data?: InterviewProfile
): string =>
  getEnumTranslationKey("InterviewProfileStatus", getProfileStatusKey(data))
