/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ContactDto,
    ContactDtoFromJSON,
    ContactDtoFromJSONTyped,
    ContactDtoToJSON,
    RegistrationInviteDto,
    RegistrationInviteDtoFromJSON,
    RegistrationInviteDtoFromJSONTyped,
    RegistrationInviteDtoToJSON,
    UserProfileDto,
    UserProfileDtoFromJSON,
    UserProfileDtoFromJSONTyped,
    UserProfileDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface PartnerStatusDto
 */
export interface PartnerStatusDto {
    /**
     * 
     * @type {boolean}
     * @memberof PartnerStatusDto
     */
    isDeactivated?: boolean;
    /**
     * 
     * @type {Array<UserProfileDto>}
     * @memberof PartnerStatusDto
     */
    accounts?: Array<UserProfileDto> | null;
    /**
     * 
     * @type {Array<RegistrationInviteDto>}
     * @memberof PartnerStatusDto
     */
    registrationInvites?: Array<RegistrationInviteDto> | null;
    /**
     * 
     * @type {number}
     * @memberof PartnerStatusDto
     */
    id?: number;
    /**
     * 
     * @type {Array<ContactDto>}
     * @memberof PartnerStatusDto
     */
    readonly contacts?: Array<ContactDto> | null;
    /**
     * 
     * @type {string}
     * @memberof PartnerStatusDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerStatusDto
     */
    comment?: string | null;
}

export function PartnerStatusDtoFromJSON(json: any): PartnerStatusDto {
    return PartnerStatusDtoFromJSONTyped(json, false);
}

export function PartnerStatusDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PartnerStatusDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isDeactivated': !exists(json, 'isDeactivated') ? undefined : json['isDeactivated'],
        'accounts': !exists(json, 'accounts') ? undefined : (json['accounts'] === null ? null : (json['accounts'] as Array<any>).map(UserProfileDtoFromJSON)),
        'registrationInvites': !exists(json, 'registrationInvites') ? undefined : (json['registrationInvites'] === null ? null : (json['registrationInvites'] as Array<any>).map(RegistrationInviteDtoFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'contacts': !exists(json, 'contacts') ? undefined : (json['contacts'] === null ? null : (json['contacts'] as Array<any>).map(ContactDtoFromJSON)),
        'name': json['name'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
    };
}

export function PartnerStatusDtoToJSON(value?: PartnerStatusDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isDeactivated': value.isDeactivated,
        'accounts': value.accounts === undefined ? undefined : (value.accounts === null ? null : (value.accounts as Array<any>).map(UserProfileDtoToJSON)),
        'registrationInvites': value.registrationInvites === undefined ? undefined : (value.registrationInvites === null ? null : (value.registrationInvites as Array<any>).map(RegistrationInviteDtoToJSON)),
        'id': value.id,
        'name': value.name,
        'comment': value.comment,
    };
}


