/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum AccountOrder {
    InvalidEnum = 'InvalidEnum',
    InvitedAt = 'InvitedAt',
    Email = 'Email',
    Name = 'Name'
}

export function AccountOrderFromJSON(json: any): AccountOrder {
    return AccountOrderFromJSONTyped(json, false);
}

export function AccountOrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountOrder {
    return json as AccountOrder;
}

export function AccountOrderToJSON(value?: AccountOrder | null): any {
    return value as any;
}

