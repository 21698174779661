import {
  InterviewProfileDto,
  InterviewProfileStatus,
} from "api/generated/namecheck"
import moment from "moment"
import { convertBackendDateToMoment } from "utils/dateUtils"
import {
  mapToNameCheckDataDto,
  mapToNameCheckData,
  NameCheckData,
} from "./NameCheckData"

export interface InterviewProfile
  extends Omit<
    InterviewProfileDto,
    "createdAt" | "expiresAt" | "finalizedAt" | "nameCheckData"
  > {
  createdAt?: moment.Moment
  expiresAt?: moment.Moment
  finalizedAt?: moment.Moment
  nameCheckData?: NameCheckData
  isInProgress: boolean
}

export const mapToInterviewProfile = (
  dto: InterviewProfileDto
): InterviewProfile => {
  const data: InterviewProfile = {
    ...dto,
    createdAt: convertBackendDateToMoment(dto.createdAt),
    expiresAt: convertBackendDateToMoment(dto.expiresAt),
    finalizedAt: convertBackendDateToMoment(dto.finalizedAt),
    nameCheckData: dto.nameCheckData
      ? mapToNameCheckData(dto.nameCheckData)
      : undefined,
    isInProgress:
      !dto.isExpired && dto.status === InterviewProfileStatus.InProgress,
  }
  return data
}

export const mapToInterviewProfileDto = (
  data?: InterviewProfile
): InterviewProfileDto => {
  const dto: InterviewProfileDto = {
    ...data,
    nameCheckData: mapToNameCheckDataDto(data?.nameCheckData) ?? undefined,
    createdAt: data?.createdAt ? data?.createdAt.toDate() : undefined,
    expiresAt: data?.expiresAt ? data?.expiresAt.toDate() : undefined,
    finalizedAt: data?.finalizedAt ? data.finalizedAt.toDate() : undefined,
  }
  return dto
}
