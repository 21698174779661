import { Error403 } from "components/layout/Error403.page"
import { Error500 } from "components/layout/Error500.page"
import { FullPageLayout } from "components/layout/FullPageLayout"
import Login from "features/auth/Login.page"
import InterviewProfileList from "features/interviewProfile/list/InterviewProfileList.page"
import InterviewProfileSingle from "features/interviewProfile/single/InterviewProfileSingle.page"
import AccountList from "features/accounts/list/AccountList.page"
import NameCheckList from "features/nameCheck/list/NameCheckList.page"
import NameCheckSingle from "features/nameCheck/single/NameCheckSingle.page"
import Partners from "features/partners/Partners.page"
import React from "react"
import { PrivateRoute } from "./PrivateRoute"
import { PublicRoute } from "./PublicRoute"
import AcceptInvite from "features/accounts/AcceptInvite.page"
import {
  getRegistrationPath,
  registrationPathBase,
} from "features/accounts/utils"
import Blacklist from "features/blacklist/list/Blacklist.page"
import PositionsList from "features/positions/Positions.page"
import { UserRole } from "api/generated/namecheck"

enum RouteItemTypes {
  Public = "Public",
  Private = "Private",
}

interface RouteItem {
  path: string
  name: string
  component: React.ElementType
  type: RouteItemTypes
  exact?: boolean
  restricted?: boolean
  layout?: React.ElementType
  roles?: UserRole[]
  title?: string
}

const routesArray: RouteItem[] = [
  {
    path: "",
    name: "home",
    component: NameCheckSingle,
    type: RouteItemTypes.Private,
    exact: true,
    title: undefined,
  },
  {
    path: "namecheck-results",
    name: "namecheck-list",
    component: NameCheckList,
    type: RouteItemTypes.Private,
    exact: true,
    roles: [UserRole.Admin],
    title: "menu.header.namecheck",
  },
  {
    path: "namecheck",
    name: "namecheck-create",
    component: NameCheckSingle,
    type: RouteItemTypes.Private,
    exact: true,
    title: undefined,
  },
  {
    path: "namecheck/:id",
    name: "namecheck-edit",
    component: NameCheckSingle,
    type: RouteItemTypes.Private,
    exact: true,
    title: undefined,
  },
  {
    path: "interview-profiles",
    name: "interview-profiles",
    component: InterviewProfileList,
    type: RouteItemTypes.Private,
    exact: true,
    title: "menu.header.interview-profiles",
  },
  {
    path: "interview-profiles/:id",
    name: "interview-profile",
    component: InterviewProfileSingle,
    type: RouteItemTypes.Private,
    exact: true,
    title: "menu.header.interview-profiles",
  },
  {
    path: "positions",
    name: "positions",
    component: PositionsList,
    type: RouteItemTypes.Private,
    exact: true,
    roles: [UserRole.Admin],
    title: "menu.header.positions",
  },
  {
    path: "partners",
    name: "partners",
    component: Partners,
    type: RouteItemTypes.Private,
    exact: true,
    roles: [UserRole.Admin],
    title: "menu.header.partners",
  },
  {
    path: "accounts",
    name: "accounts",
    component: AccountList,
    type: RouteItemTypes.Private,
    exact: true,
    roles: [UserRole.Admin],
    title: "menu.header.accounts",
  },
  {
    path: "blacklist",
    name: "blacklist",
    component: Blacklist,
    type: RouteItemTypes.Private,
    title: "menu.header.blacklist",
    exact: true,
  },
  {
    path: "login",
    name: "login",
    component: Login,
    type: RouteItemTypes.Public,
    restricted: true, // restricted meaning it can only be accessed, if the user is not logged in
    layout: (props: any) => <FullPageLayout {...props} contentCentered />,
  },
  {
    path: getRegistrationPath(),
    name: registrationPathBase,
    component: AcceptInvite,
    type: RouteItemTypes.Public,
    restricted: true, // restricted meaning it can only be accessed, if the user is not logged in
    layout: (props: any) => <FullPageLayout {...props} contentCentered />,
  },
  {
    path: "error/500",
    name: "error500",
    component: Error500,
    type: RouteItemTypes.Public,
  },
  {
    path: "error/403",
    name: "error403",
    component: Error403,
    type: RouteItemTypes.Public,
  },
]

const routes = routesArray.map(({ type, path, component, ...props }) =>
  type === RouteItemTypes.Private ? (
    <PrivateRoute
      {...props}
      component={component}
      path={`/${path}`}
      key={props.name}
    />
  ) : (
    <PublicRoute
      {...props}
      component={component}
      path={`/${path}`}
      key={props.name}
    />
  )
)

export default routes
