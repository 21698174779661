/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PartnerDto,
    PartnerDtoFromJSON,
    PartnerDtoFromJSONTyped,
    PartnerDtoToJSON,
    UserRole,
    UserRoleFromJSON,
    UserRoleFromJSONTyped,
    UserRoleToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserProfileDto
 */
export interface UserProfileDto {
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    id?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    profileImageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    email?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserProfileDto
     */
    partnerId?: number | null;
    /**
     * 
     * @type {PartnerDto}
     * @memberof UserProfileDto
     */
    partner?: PartnerDto;
    /**
     * 
     * @type {Array<UserRole>}
     * @memberof UserProfileDto
     */
    roles?: Array<UserRole> | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileDto
     */
    fullName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfileDto
     */
    isDeactivated?: boolean;
}

export function UserProfileDtoFromJSON(json: any): UserProfileDto {
    return UserProfileDtoFromJSONTyped(json, false);
}

export function UserProfileDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserProfileDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'profileImageUrl': !exists(json, 'profileImageUrl') ? undefined : json['profileImageUrl'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'partnerId': !exists(json, 'partner_Id') ? undefined : json['partner_Id'],
        'partner': !exists(json, 'partner') ? undefined : PartnerDtoFromJSON(json['partner']),
        'roles': !exists(json, 'roles') ? undefined : (json['roles'] === null ? null : (json['roles'] as Array<any>).map(UserRoleFromJSON)),
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
        'isDeactivated': !exists(json, 'isDeactivated') ? undefined : json['isDeactivated'],
    };
}

export function UserProfileDtoToJSON(value?: UserProfileDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'profileImageUrl': value.profileImageUrl,
        'email': value.email,
        'partner_Id': value.partnerId,
        'partner': PartnerDtoToJSON(value.partner),
        'roles': value.roles === undefined ? undefined : (value.roles === null ? null : (value.roles as Array<any>).map(UserRoleToJSON)),
        'fullName': value.fullName,
        'isDeactivated': value.isDeactivated,
    };
}


