import {
  Divider,
  Button,
  Space,
  Tag,
  Tooltip,
  Typography,
  Table,
  message,
  Form,
  Input,
  Row,
  Col,
  Select,
  Alert,
} from "antd"
import { Trans, useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useCallback, useEffect, useMemo, useState } from "react"
import { RootState } from "app/store"
import {
  getRegistrationStatus,
  getRegistrationLink,
  getTagColor,
  RegistrationStatus,
} from "../utils"
import {
  CheckCircleFilled,
  CheckOutlined,
  CloseCircleFilled,
  CopyOutlined,
  MailOutlined,
  MinusCircleFilled,
} from "@ant-design/icons"
import { ColumnsType, TablePaginationConfig } from "antd/lib/table"
import { getEnumTranslationKey } from "utils/common"
import { accountSliceActions, accountSliceSelectors } from "./AccountSlice"
import {
  InviteStatus,
  RegistrationInviteDto,
  UserRole,
} from "api/generated/namecheck"
import Avatar from "antd/lib/avatar/avatar"
import confirm from "antd/lib/modal/confirm"
import { formatMomentDate } from "utils/dateUtils"
import moment from "moment"
import Modal from "antd/lib/modal/Modal"
import {
  formItemRequired,
  formItemRequiredNoWhitespace,
} from "utils/formItemRules"
import Text from "antd/lib/typography/Text"
import { FeatureState } from "models/FeatureState"
import useTableFilters from "hooks/useTableFilters"
import classNames from "classnames"

const AccountList: React.FC = () => {
  const {
    pagination,
    list,
    partners,
    inviteState,
    generatedInvite,
  } = useSelector((state: RootState) => state.account)

  const loading = useSelector(accountSliceSelectors.isLoading)

  const { t } = useTranslation()

  const [form] = Form.useForm()

  const { filterTableColumnProps } = useTableFilters()

  const onClickCreate = (event: any): void => {
    dispatch(accountSliceActions.resetRegistrationInvite())
    form.resetFields()
    setShowCreateModal(true)
  }

  const onClickCopyToClipboard = (
    generatedInvite: RegistrationInviteDto
  ): void => {
    if (generatedInvite?.invitationCode) {
      const registrationLink = getRegistrationLink(
        generatedInvite.emailAddress ?? "",
        generatedInvite.invitationCode
      )
      navigator.clipboard.writeText(registrationLink)
      message.success(t("pages.Accounts.create.modal.regCodeCopy"), 5)
    }
  }

  const onFinish = (values: RegistrationInviteDto): any => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      const saveResponse = (await dispatch(
        accountSliceActions.createInvite(values)
      )) as RegistrationInviteDto

      const registrationInviteSuccessfullyCreated =
        saveResponse && saveResponse.status === InviteStatus.Pending

      if (registrationInviteSuccessfullyCreated) {
        loadTableData()
        if (saveResponse?.emailSuccessfullySent)
          message.success(t("pages.Accounts.create.modal.responseSuccess"), 5)
        else
          message.warning(t("pages.Accounts.create.modal.responseWarning"), 5)
      }
    })()
  }

  const [showCreateModal, setShowCreateModal] = useState<any>(false)

  const activate = (data: RegistrationInviteDto): void => {
    confirm({
      type: "info",
      title: t("pages.Accounts.activate.modal.title"),
      content: (
        <Trans
          i18nKey="pages.Accounts.activate.modal.content"
          defaults={t("pages.Accounts.activate.modal.content")}
          values={{ accountInfo: data?.emailAddress }}
          components={{ tag: <Tag className="modal-info-tag" /> }}
        />
      ),
      icon: <CheckCircleFilled />,
      okText: t("pages.Accounts.activate.modal.ok"),
      cancelText: t("pages.Accounts.modal.cancel"),
      onOk: async (e: any) => {
        const registrationInvite = (await dispatch(
          accountSliceActions.activate(data.invitationCode)
        )) as RegistrationInviteDto
        if (registrationInvite) {
          message.success(t("pages.Accounts.activate.response"), 5)
          loadTableData()
        }
      },
    })
  }

  const deactivate = (data: RegistrationInviteDto): void => {
    confirm({
      type: "error",
      title: t("pages.Accounts.deactivate.modal.title"),
      content: (
        <Trans
          i18nKey="pages.Accounts.deactivate.modal.content"
          defaults={t("pages.Accounts.deactivate.modal.content")}
          values={{ accountInfo: data?.emailAddress }}
          components={{ tag: <Tag className="modal-info-tag" /> }}
        />
      ),
      icon: <CloseCircleFilled />,
      okText: t("pages.Accounts.deactivate.modal.ok"),
      okButtonProps: { danger: true },
      cancelText: t("pages.Accounts.modal.cancel"),
      cancelButtonProps: { danger: true },
      onOk: async (e: any) => {
        const registrationInvite = (await dispatch(
          accountSliceActions.deactivate(data.invitationCode)
        )) as RegistrationInviteDto
        if (registrationInvite) {
          message.warning(t("pages.Accounts.deactivate.response"), 5)
          loadTableData()
        }
      },
    })
  }

  const columns: ColumnsType<RegistrationInviteDto> = [
    {
      title: null,
      dataIndex: "avatar",
      render: (text: string, record: RegistrationInviteDto) => {
        const status: RegistrationStatus = getRegistrationStatus(record)

        return (
          <Avatar
            src={record?.registeredAccount?.profileImageUrl}
            icon={
              status === RegistrationStatus.Completed ? null : status ===
                RegistrationStatus.Accepted ? (
                <CheckOutlined />
              ) : (
                <MailOutlined />
              )
            }
            className={classNames(
              {
                "avatar-red": status === RegistrationStatus.EmailSendingFailed,
              },
              {
                "avatar-gold": status === RegistrationStatus.EmailSent,
              },
              {
                "avatar-green": status === RegistrationStatus.Accepted,
              }
            )}
          />
        )
      },
      width: 32,
    },
    {
      title: t("labels.Accounts.name"),
      dataIndex: ["registeredAccount", "fullName"],
      render: (text: string, record: RegistrationInviteDto) =>
        record.registeredAccount ? (
          record.registeredAccount.fullName
        ) : (
          <Tag color="gold">
            {t(getEnumTranslationKey("InviteStatus", InviteStatus.Pending))}
          </Tag>
        ),
    },
    {
      title: t("labels.Accounts.email"),
      dataIndex: ["emailAddress"],
      render: (text: string, record: RegistrationInviteDto) =>
        record.registeredAccount?.email ?? record.emailAddress,
      className: "no-wrap",
      ...filterTableColumnProps,
    },
    {
      title: t("labels.Accounts.partner"),
      dataIndex: ["partner", "name"],
      ...filterTableColumnProps,
    },
    {
      title: t("labels.Accounts.role"),
      dataIndex: ["registeredAccount", "roles"],
      render: (text: string, record: RegistrationInviteDto) => {
        const role = record?.registeredAccount?.roles?.some(
          (r: UserRole) => r === UserRole.Admin
        )
          ? UserRole.Admin
          : UserRole.User
        return (
          <Tag color={getTagColor(role)} key={role}>
            {t(getEnumTranslationKey("UserRole", role))}
          </Tag>
        )
      },
    },
    {
      title: t("labels.NameCheckData.invitedBy"),
      dataIndex: "createdBy",
      render: (text: string, record: RegistrationInviteDto) => (
        <>
          <span>{record?.createdBy?.email || record?.createdBy?.fullName}</span>
          <br />
          <small className="no-wrap">{record?.createdBy?.partner?.name}</small>
        </>
      ),
    },
    {
      title: t("labels.NameCheckData.invitedAt"),
      dataIndex: "createdAt",
      render: (createdAt?: Date) =>
        createdAt ? (
          <span className="no-wrap">{formatMomentDate(moment(createdAt))}</span>
        ) : null,
    },
    {
      title: t("labels.NameCheckData.acceptedAt"),
      dataIndex: "acceptedAt",
      render: (text: string, record: RegistrationInviteDto) =>
        record?.status === InviteStatus.Completed ? (
          <span className="no-wrap">
            {formatMomentDate(moment(record?.acceptedAt))}
          </span>
        ) : null,
    },
    {
      title: "",
      key: "actions",
      fixed: "right",
      render: (text: string, record: RegistrationInviteDto) => (
        <Space size="small" align="center">
          {record.isDeactivated ? (
            <Tooltip title={t("labels.Accounts.activate")} key="view">
              <Button
                shape="circle"
                icon={<CheckCircleFilled />}
                type="primary"
                onClick={() => activate(record)}
              />
            </Tooltip>
          ) : (
            <Tooltip title={t("labels.Accounts.deactivate")} key="view">
              <Button
                shape="circle"
                icon={<MinusCircleFilled />}
                danger
                onClick={() => deactivate(record)}
              />
            </Tooltip>
          )}
        </Space>
      ),
    },
  ]

  const dispatch = useDispatch()

  const loadTableData = useCallback(
    () =>
      dispatch(
        accountSliceActions.loadData({
          page: pagination.page,
          take: pagination.take,
        })
      ),
    [dispatch, pagination.page, pagination.take]
  )

  useEffect(() => {
    loadTableData()
    dispatch(accountSliceActions.loadPartners())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleTableChange = useCallback(
    (newPaginationConfig: TablePaginationConfig, filters: any) => {
      dispatch(
        accountSliceActions.loadData({
          page: (newPaginationConfig.current ?? 1) - 1,
          take: newPaginationConfig.pageSize,
          partnerName: filters?.["partner.name"]?.[0],
          email: filters?.emailAddress?.[0],
        })
      )
    },
    [dispatch]
  )

  const paginationConfig = useMemo<TablePaginationConfig>(
    () => ({
      pageSizeOptions: ["20", "50"],
      showSizeChanger: true,
      pageSize: pagination.take,
      current: (pagination.page ?? 0) + 1,
      total: pagination.total,
    }),
    [pagination.page, pagination.take, pagination.total]
  )

  return (
    <>
      <Typography.Title>
        <div>{t("pages.AccountList.title")}</div>
        <Divider />
        <Button size="large" type="primary" onClick={onClickCreate}>
          <MailOutlined /> {t("forms.AccountList.createInvite")}
        </Button>
      </Typography.Title>

      <Table
        dataSource={list}
        columns={columns}
        loading={loading}
        rowKey="id"
        scroll={{ x: true }}
        pagination={paginationConfig}
        onChange={handleTableChange}
        expandable={{
          expandedRowRender: record => (
            <>
              <p style={{ margin: 0 }}>
                <Text>{t("labels.NameCheckData.inviteCode")}: </Text>
                <Text code>{record.invitationCode}</Text>
                <CopyOutlined
                  onClick={() => onClickCopyToClipboard(record)}
                  disabled={!record?.invitationCode}
                />
              </p>
              {record.acceptedAt ? (
                <p style={{ margin: 0 }}>
                  <Text type="success">
                    {t("labels.NameCheckData.accepted")}
                  </Text>
                </p>
              ) : record.emailSuccessfullySent ? (
                <p style={{ margin: 0 }}>
                  <Text type="warning">
                    {t("labels.NameCheckData.emailSuccessful")}
                  </Text>
                </p>
              ) : (
                <p style={{ margin: 0 }}>
                  <Text type="danger">
                    {t("labels.NameCheckData.emailFailed")}
                  </Text>
                </p>
              )}
            </>
          ),
          rowExpandable: record => !!record.invitationCode,
        }}
      />
      <Modal
        title={t("pages.Accounts.create.modal.title")}
        visible={showCreateModal}
        okText={t("pages.Accounts.create.modal.ok")}
        onOk={() => form.submit()}
        cancelText={
          inviteState === FeatureState.Success
            ? t("pages.Accounts.modal.close")
            : t("pages.Accounts.modal.cancel")
        }
        onCancel={() => setShowCreateModal(false)}
        okButtonProps={{
          loading: inviteState === FeatureState.Loading,
          disabled: inviteState === FeatureState.Success,
        }}
      >
        <Space direction="vertical" size="middle">
          <p>{t("pages.Accounts.create.modal.content")}</p>

          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Row gutter={24}>
              <Col span={24} key="emailAddress">
                <Form.Item
                  label={t("labels.Accounts.email")}
                  name="emailAddress"
                  rules={formItemRequiredNoWhitespace}
                >
                  <Input type="email" />
                </Form.Item>
              </Col>
              <Col span={24} key="partnerId">
                <Form.Item
                  label={t("labels.Accounts.partner")}
                  name="partnerId"
                  rules={formItemRequired}
                >
                  <Select<number>>
                    {partners.map(item => (
                      <Select.Option
                        key={`ptnr_${item.id}`}
                        value={item.id ?? ""}
                      >
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {generatedInvite?.invitationCode && (
              <>
                <Divider />
                <Row gutter={24}>
                  <Space
                    direction="vertical"
                    size="middle"
                    style={{ width: "100%" }}
                  >
                    <Col span={24} key="invitationCode">
                      <Alert
                        message={t("pages.Accounts.create.modal.responseCode")}
                        type="success"
                      />
                    </Col>
                    <Col span={24} key="invitationCode">
                      <Form.Item
                        label={t("labels.Accounts.invitationCode")}
                        name="invitationCode"
                        initialValue={generatedInvite?.invitationCode}
                      >
                        <Input
                          disabled
                          value={generatedInvite?.invitationCode}
                          addonAfter={
                            <CopyOutlined
                              onClick={() =>
                                onClickCopyToClipboard(generatedInvite)
                              }
                              disabled={!generatedInvite?.invitationCode}
                            />
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Space>
                </Row>
              </>
            )}
          </Form>
        </Space>
      </Modal>
    </>
  )
}

export default AccountList
