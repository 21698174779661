/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ContactDto,
    ContactDtoFromJSON,
    ContactDtoFromJSONTyped,
    ContactDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface PartnerDto
 */
export interface PartnerDto {
    /**
     * 
     * @type {number}
     * @memberof PartnerDto
     */
    id?: number;
    /**
     * 
     * @type {Array<ContactDto>}
     * @memberof PartnerDto
     */
    readonly contacts?: Array<ContactDto> | null;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerDto
     */
    isDeactivated?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PartnerDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PartnerDto
     */
    comment?: string | null;
}

export function PartnerDtoFromJSON(json: any): PartnerDto {
    return PartnerDtoFromJSONTyped(json, false);
}

export function PartnerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PartnerDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'contacts': !exists(json, 'contacts') ? undefined : (json['contacts'] === null ? null : (json['contacts'] as Array<any>).map(ContactDtoFromJSON)),
        'isDeactivated': !exists(json, 'isDeactivated') ? undefined : json['isDeactivated'],
        'name': json['name'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
    };
}

export function PartnerDtoToJSON(value?: PartnerDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'isDeactivated': value.isDeactivated,
        'name': value.name,
        'comment': value.comment,
    };
}


