import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { api } from "api"
import { FeatureState } from "models/FeatureState"
import { AppThunk, RootState } from "app/store"
import { FeaturePagination } from "models/FeaturePagination"
import {
  CreatePartnerDto,
  GetPartnerListPagedRequest,
  PartnerDto,
  SetDeactivatedFlagForPartnerRequest,
  UpdatePartnerDto,
} from "api/generated/namecheck"
import { getInitialPaginationState } from "utils/common"

interface PartnersSliceState {
  featureState: FeatureState
  saveState: FeatureState
  list: PartnerDto[]
  pagination: FeaturePagination
}

const initialState: PartnersSliceState = {
  featureState: FeatureState.Initial,
  saveState: FeatureState.Initial,
  list: [],
  pagination: getInitialPaginationState(),
}

const partnersSlice = createSlice({
  name: "partners",
  initialState,
  reducers: {
    reset: () => ({ ...initialState }),
    fetchPartnersStart(state) {
      state.featureState = FeatureState.Loading
    },
    fetchPartnersSuccess(state, { payload }: PayloadAction<PartnerDto[]>) {
      state.list = payload
      state.featureState = FeatureState.Success
    },
    fetchPartnersFailure(state) {
      state.featureState = FeatureState.Error
    },
    setPagination(state, { payload }: PayloadAction<FeaturePagination>) {
      state.pagination = payload
    },
    savePartnersStart(state) {
      state.saveState = FeatureState.Loading
    },
    savePartnersSuccess(state) {
      state.saveState = FeatureState.Success
    },
    savePartnersFailure(state) {
      state.saveState = FeatureState.Error
    },
  },
})

export const partnersSliceReducer = partnersSlice.reducer

/**
 * SELECTORS
 */

const loadingSelector = (state: RootState): boolean =>
  state.partners.featureState === FeatureState.Loading

const savingSelector = (state: RootState): boolean =>
  state.partners.saveState === FeatureState.Loading

export const partnersSliceSelectors = {
  isLoading: loadingSelector,
  isSaving: savingSelector,
}

/**
 * ACTIONS
 */

const {
  fetchPartnersStart,
  fetchPartnersFailure,
  fetchPartnersSuccess,
  reset,
  setPagination,
  savePartnersStart,
  savePartnersSuccess,
  savePartnersFailure,
} = partnersSlice.actions

const fetchPartners = (
  params: GetPartnerListPagedRequest = {}
): AppThunk<Promise<any>> => async dispatch => {
  dispatch(fetchPartnersStart())

  return api.namecheck.partner
    .getPartnerListPaged(params)
    .then(data => {
      const { results, ...pagination } = data

      dispatch(fetchPartnersSuccess(results || []))
      dispatch(setPagination(pagination))
    })
    .catch((error: any) => {
      dispatch(fetchPartnersFailure())

      return Promise.reject(error)
    })
}

type savePartnerParams = CreatePartnerDto | UpdatePartnerDto

const isSavePartnerParamsCreatePartnerDto = (
  value: savePartnerParams
): value is CreatePartnerDto =>
  !("id" in value) || ("id" in value && typeof value.id === "undefined")

const isSavePartnerParamsUpdatePartnerDto = (
  value: savePartnerParams
): value is UpdatePartnerDto => "id" in value && typeof value.id !== "undefined"

const savePartner = (
  params: savePartnerParams
): AppThunk<Promise<any>> => async dispatch => {
  dispatch(savePartnersStart())

  if (isSavePartnerParamsUpdatePartnerDto(params)) {
    return api.namecheck.partner
      .updatePartner({ updatePartnerDto: params, id: params.id + "" })
      .then(() => {
        dispatch(savePartnersSuccess())
      })
      .catch((error: any) => {
        dispatch(savePartnersFailure())

        return Promise.reject(error)
      })
  }

  if (isSavePartnerParamsCreatePartnerDto(params)) {
    return api.namecheck.partner
      .createPartner({ createPartnerDto: params })
      .then(() => {
        dispatch(savePartnersSuccess())
      })
      .catch((error: any) => {
        dispatch(savePartnersFailure())

        return Promise.reject(error)
      })
  }
}

const deletePartner = (
  id: number
): AppThunk<Promise<any>> => async dispatch => {
  return api.namecheck.partner.deletePartner({ id })
}

const activatePartner = (id: number): AppThunk => async dispatch => {
  dispatch(savePartnersStart())
  const request: SetDeactivatedFlagForPartnerRequest = {
    partnerActivationDto: {
      partnerId: id,
      isDeactivated: false,
    },
  }
  return api.namecheck.partner
    .setDeactivatedFlagForPartner(request)
    .then((data: any) => {
      dispatch(savePartnersSuccess())
    })
    .catch((error: any) => {
      dispatch(savePartnersFailure())
      return Promise.reject(error)
    })
}

const deactivatePartner = (id: number): AppThunk => async dispatch => {
  dispatch(savePartnersStart())
  const request: SetDeactivatedFlagForPartnerRequest = {
    partnerActivationDto: {
      partnerId: id,
      isDeactivated: true,
    },
  }
  return api.namecheck.partner
    .setDeactivatedFlagForPartner(request)
    .then((data: any) => {
      dispatch(savePartnersSuccess())
    })
    .catch((error: any) => {
      dispatch(savePartnersFailure())
      return Promise.reject(error)
    })
}

export const partnersSliceActions = {
  fetchPartners,
  reset,
  savePartner,
  deletePartner,
  activatePartner,
  deactivatePartner,
}
