import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import translationEn from "./locales/en.json"
import translationHu from "./locales/hu.json"

export enum Language {
  En = "en",
  Hu = "hu",
}

const resources = {
  [Language.En]: {
    translation: translationEn,
  },
  [Language.Hu]: {
    translation: translationHu,
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: Language.Hu,
  fallbackLng: Language.Hu,
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
