import { CheckStatus, MatchResult } from "api/generated/namecheck"

export const getTagColor = (status?: CheckStatus): string => {
  switch (status) {
    case CheckStatus.Pending:
      return "geekblue"
    case CheckStatus.Allowed:
    case CheckStatus.Successful:
      return "green"
    case CheckStatus.Anonymized:
    case CheckStatus.Refused:
      return "red"
    default:
      return ""
  }
}
export const getMatchTagColor = (status?: MatchResult): string => {
  switch (status) {
    case MatchResult.PartialMatch:
      return "geekblue"
    case MatchResult.NoMatch:
      return "green"
    default:
      return "red"
  }
}
