import {
  Divider,
  Table,
  Typography,
  Tooltip,
  Button,
  Form,
  message,
  Space,
  Modal,
  Tag,
} from "antd"
import { ContactDto, PartnerDto } from "api/generated/namecheck"
import { Trans, useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useCallback, useEffect, useMemo, useState } from "react"
import { ColumnsType, TablePaginationConfig } from "antd/lib/table"
import { RootState } from "app/store"
import { partnersSliceActions, partnersSliceSelectors } from "./PartnersSlice"
import {
  CheckCircleFilled,
  CheckCircleOutlined,
  CloseCircleFilled,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons"
import PartnerEditModal from "./components/PartnerEditModal"
import useSSTablePagination from "hooks/useSSTablePagination"
import { getTagColor } from "./utils"

const { confirm } = Modal

const Partners: React.FC = () => {
  const loading = useSelector(partnersSliceSelectors.isLoading)
  const { t } = useTranslation()
  const { pagination, list } = useSelector((state: RootState) => state.partners)
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const [partnerIdToEdit, setPartnerIdToEdit] = useState<number>()

  const [form] = Form.useForm()

  const dispatch = useDispatch()

  const loadTableData = useCallback(
    () =>
      dispatch(
        partnersSliceActions.fetchPartners({
          page: pagination.page,
          take: pagination.take,
        })
      ),
    [dispatch, pagination.page, pagination.take]
  )

  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleModalAfterClose = useCallback(() => {
    setPartnerIdToEdit(undefined)
  }, [])

  const openModal = useCallback(() => {
    setIsEditModalVisible(true)
  }, [])

  const closeModal = useCallback(() => {
    setIsEditModalVisible(false)
  }, [])

  const partnerToEdit = useMemo(
    () => list.find(item => item.id === partnerIdToEdit),
    [list, partnerIdToEdit]
  )

  const handleEditIconClick = useCallback(
    (data: PartnerDto) => {
      setPartnerIdToEdit(data.id)
      openModal()
    },
    [openModal]
  )

  const handleDeleteModalOk = useCallback(
    async (id?: number) => {
      if (typeof id === "number") {
        try {
          await dispatch(partnersSliceActions.deletePartner(id))

          message.success(t("successMessages.partners.delete"))

          return loadTableData()
        } catch (error) {
          console.log(error)
        }
      }
    },
    [dispatch, loadTableData, t]
  )

  const onClickActivate = (data: PartnerDto): void => {
    confirm({
      type: "info",
      title: t("pages.Partners.activate.modal.title"),
      content: (
        <Trans
          i18nKey="pages.Partners.activate.modal.content"
          defaults={t("pages.Partners.activate.modal.content")}
          values={{ partnerInfo: data?.name }}
          components={{ tag: <Tag className="modal-info-tag" /> }}
        />
      ),
      icon: <CheckCircleFilled />,
      okText: t("pages.Partners.activate.modal.ok"),
      cancelText: t("pages.Partners.modal.cancel"),
      onOk: async (e: any) => {
        await dispatch(partnersSliceActions.activatePartner(data.id ?? -1))
        message.success(t("pages.Partners.activate.response"), 5)
        loadTableData()
      },
    })
  }

  const onClickDeactivate = (data: PartnerDto): void => {
    confirm({
      type: "error",
      title: t("pages.Partners.deactivate.modal.title"),
      content: (
        <Trans
          i18nKey="pages.Partners.deactivate.modal.content"
          defaults={t("pages.Partners.deactivate.modal.content")}
          values={{ partnerInfo: data?.name }}
          components={{ tag: <Tag className="modal-info-tag" /> }}
        />
      ),
      icon: <CloseCircleFilled />,
      okText: t("pages.Partners.deactivate.modal.ok"),
      okButtonProps: { danger: true },
      cancelText: t("pages.Partners.modal.cancel"),
      cancelButtonProps: { danger: true },
      onOk: async (e: any) => {
        await dispatch(partnersSliceActions.deactivatePartner(data.id ?? -1))
        message.success(t("pages.Partners.deactivate.response"), 5)
        loadTableData()
      },
    })
  }

  const handleDeleteIconClick = useCallback(
    (data: PartnerDto) => {
      confirm({
        title: t("pages.Partners.deleteModal.title"),
        icon: <ExclamationCircleOutlined />,
        content: (
          <Trans
            i18nKey="pages.Partners.deleteModal.content"
            defaults={t("pages.Partners.deleteModal.content")}
            values={{ partnerInfo: data?.name }}
            components={{ tag: <Tag className="modal-info-tag" /> }}
          />
        ),
        okText: t("pages.Partners.deleteModal.okText"),
        okType: "danger",
        cancelText: t("pages.Partners.deleteModal.cancelText"),
        onOk() {
          return handleDeleteModalOk(data?.id)
        },
      })
    },
    [handleDeleteModalOk, t]
  )

  const columns: ColumnsType<PartnerDto> = [
    {
      title: t("labels.Partners.name"),
      dataIndex: "name",
    },
    {
      title: t("labels.Partners.status"),
      dataIndex: ["isDeactivated"],
      render: (isDeactivated: boolean) => (
        <Tag color={getTagColor(isDeactivated)}>
          {!isDeactivated
            ? t("labels.Partners.isActivated")
            : t("labels.Partners.isDeactivated")}
        </Tag>
      ),
    },
    {
      title: t("labels.Partners.contacts"),
      dataIndex: "contacts",
      render: (contacts: ContactDto[]) =>
        contacts?.map(item => item.emailAddress).join(", "),
    },
    {
      title: t("labels.Partners.comment"),
      dataIndex: "comment",
    },
    {
      title: "",
      key: "actions",
      fixed: "right",
      width: 105,
      render: (text: string, record) => (
        <Space size="small" align="center">
          <Tooltip title={t("labels.Partners.editTooltip")}>
            <Button
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => handleEditIconClick(record)}
            />
          </Tooltip>
          {!record.isDeactivated ? (
            <Tooltip title={t("labels.Partners.deactivate")} key="deactivate">
              <Button
                shape="circle"
                icon={<CloseCircleOutlined />}
                ghost
                danger
                onClick={() => onClickDeactivate(record)}
              />
            </Tooltip>
          ) : (
            <Tooltip title={t("labels.Partners.activate")} key="activate">
              <Button
                shape="circle"
                icon={<CheckCircleOutlined />}
                type="primary"
                ghost
                onClick={() => onClickActivate(record)}
              />
            </Tooltip>
          )}
          <Tooltip title={t("labels.Partners.deleteTooltip")}>
            <Button
              shape="circle"
              danger
              type="primary"
              icon={<DeleteOutlined />}
              onClick={() => handleDeleteIconClick(record)}
            />
          </Tooltip>
        </Space>
      ),
    },
  ]

  const handleTableChange = useCallback(
    (newPaginationConfig: TablePaginationConfig, filters: any) => {
      dispatch(
        partnersSliceActions.fetchPartners({
          page: (newPaginationConfig.current ?? 1) - 1,
          take: newPaginationConfig.pageSize,
        })
      )
    },
    [dispatch]
  )

  const paginationConfig = useSSTablePagination(pagination)

  const handleModalOk = useCallback(async () => {
    try {
      const values = await form.validateFields()

      await dispatch(partnersSliceActions.savePartner(values))

      if (values.id) {
        message.success(t("successMessages.partners.edit"))
      } else {
        message.success(t("successMessages.partners.create"))
      }

      closeModal()

      loadTableData()
    } catch (error) {}
  }, [closeModal, dispatch, form, loadTableData, t])

  return (
    <>
      <Typography.Title>
        {t("pages.Partners.title")}
        <Divider />
        <Button size="large" type="primary" onClick={openModal}>
          <PlusOutlined /> {t("labels.Partners.addNew")}
        </Button>
      </Typography.Title>
      <Table
        dataSource={list}
        columns={columns}
        loading={loading}
        rowKey="id"
        scroll={{ x: true }}
        pagination={paginationConfig}
        onChange={handleTableChange}
      />
      <PartnerEditModal
        visible={isEditModalVisible}
        onCancel={closeModal}
        onOk={handleModalOk}
        afterClose={handleModalAfterClose}
        partner={partnerToEdit}
        okText={t("labels.Partners.modal.okText")}
        cancelText={t("labels.Partners.modal.cancelText")}
        form={form}
      />
    </>
  )
}

export default Partners
