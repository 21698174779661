/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExternalAuthDto
 */
export interface ExternalAuthDto {
    /**
     * 
     * @type {string}
     * @memberof ExternalAuthDto
     */
    idToken?: string | null;
}

export function ExternalAuthDtoFromJSON(json: any): ExternalAuthDto {
    return ExternalAuthDtoFromJSONTyped(json, false);
}

export function ExternalAuthDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalAuthDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'idToken': !exists(json, 'idToken') ? undefined : json['idToken'],
    };
}

export function ExternalAuthDtoToJSON(value?: ExternalAuthDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'idToken': value.idToken,
    };
}


