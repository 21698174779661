import { Divider, Button, Space, Tag, Tooltip, Typography, Table } from "antd"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useCallback, useEffect } from "react"
import {
  nameCheckListSliceActions,
  nameCheckListSliceSelectors,
} from "./NameCheckListSlice"
import { RootState } from "app/store"
import { NameCheckData } from "models/NameCheckData"
import { CheckSource, CheckStatus } from "api/generated/namecheck"

import { getTagColor } from "../utils"

import {
  EyeOutlined,
  InfoCircleOutlined,
  ProfileOutlined,
  SecurityScanOutlined,
} from "@ant-design/icons"
import { Link } from "react-router-dom"
import { ColumnsType, TablePaginationConfig } from "antd/lib/table"
import { formatMomentDate, MomentFormats } from "utils/dateUtils"

import moment from "moment"
import { getEnumTranslationKey } from "utils/common"
import useSSTablePagination from "hooks/useSSTablePagination"
import useTableFilters from "hooks/useTableFilters"

const NameCheckList: React.FC = () => {
  const { pagination, list } = useSelector(
    (state: RootState) => state.nameCheckList
  )

  const loading = useSelector(nameCheckListSliceSelectors.isLoading)
  const { filterTableColumnProps } = useTableFilters()

  const { t } = useTranslation()

  const columns: ColumnsType<NameCheckData> = [
    {
      title: t("labels.NameCheckData.status"),
      dataIndex: "status",
      render: (status: CheckStatus) => (
        <Tag color={getTagColor(status)} key={status}>
          {t(getEnumTranslationKey("CheckStatus", status))}
        </Tag>
      ),
    },
    {
      title: t("labels.NameCheckData.fullName"),
      dataIndex: "fullName",
      ...filterTableColumnProps,
    },
    {
      title: () => (
        <Space size="small">
          <span>{t("labels.NameCheckData.personalData.label")}</span>
          <Tooltip
            placement="top"
            title={t("labels.NameCheckData.personalData.info")}
          >
            <InfoCircleOutlined className="info-icon" />
          </Tooltip>
        </Space>
      ),
      dataIndex: "personalData",
      render: (text: string, record: NameCheckData) => (
        <>
          <span>{record.emailAddress || record.additionalInfo}</span>
          {record.dateOfBirth && (
            <>
              <br />
              <small className="no-wrap">
                {formatMomentDate(record.dateOfBirth, MomentFormats.Date)}
              </small>
            </>
          )}
        </>
      ),
    },
    {
      title: t("labels.NameCheckData.createdAt"),
      dataIndex: "createdAt",
      render: (createdAt: moment.Moment) => formatMomentDate(createdAt),
    },
    {
      title: t("labels.NameCheckData.createdBy"),
      dataIndex: "createdBy",
      render: (text: string, record: NameCheckData) => (
        <>
          <span>{record?.createdBy?.email || record?.createdBy?.fullName}</span>
          <br />
          <small className="no-wrap">{record?.partner?.name}</small>
        </>
      ),
      ...filterTableColumnProps,
    },
    {
      title: t("labels.NameCheckData.source"),
      dataIndex: "source",
      render: (value: CheckSource) =>
        t(getEnumTranslationKey("CheckSource", value)),
    },
    {
      title: "",
      key: "actions",
      fixed: "right",
      render: (text: string, record: NameCheckData) => (
        <Space size="small" align="center">
          <Tooltip
            title={t("labels.NameCheckData.viewNameCheckResult")}
            key="view"
          >
            <Link to={`/namecheck/${record.nameCheckGuid}`}>
              <Button
                shape="circle"
                icon={<EyeOutlined />}
                disabled={!record.nameCheckGuid}
              />
            </Link>
          </Tooltip>
          <Tooltip
            title={t("labels.NameCheckData.viewInterviewProfile")}
            key="view"
          >
            <Link to={`/interview-profiles/${record.interviewProfileId}`}>
              <Button
                shape="circle"
                type="primary"
                icon={<ProfileOutlined />}
                disabled={!record.interviewProfileId}
              />
            </Link>
          </Tooltip>
        </Space>
      ),
    },
  ]

  const dispatch = useDispatch()

  const loadTableData = useCallback(
    () =>
      dispatch(
        nameCheckListSliceActions.loadData({
          page: pagination.page,
          take: pagination.take,
          hideSuccessful: false,
        })
      ),
    [dispatch, pagination.page, pagination.take]
  )

  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleTableChange = useCallback(
    (newPaginationConfig: TablePaginationConfig, filters: any) => {
      dispatch(
        nameCheckListSliceActions.loadData({
          page: (newPaginationConfig.current ?? 1) - 1,
          take: newPaginationConfig.pageSize,
          hideSuccessful: false,
          candidateName: filters?.fullName?.[0],
          checkUserEmailAddress: filters?.createdBy?.[0],
        })
      )
    },
    [dispatch]
  )

  const paginationConfig = useSSTablePagination(pagination)

  return (
    <>
      <Typography.Title>
        <div>{t("pages.NameCheckList.title")}</div>
        <Divider />
        <Link to={`/namecheck/`}>
          <Button size="large" type="primary">
            <SecurityScanOutlined /> {t("forms.NameCheck.toNew")}
          </Button>
        </Link>
      </Typography.Title>

      <Table
        dataSource={list}
        columns={columns}
        loading={loading}
        rowKey="id"
        scroll={{ x: true }}
        pagination={paginationConfig}
        onChange={handleTableChange}
      />
    </>
  )
}

export default NameCheckList
