import { useDispatch, useSelector } from "react-redux"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Link, useParams } from "react-router-dom"
import {
  profileSingleSliceActions,
  profileSingleSliceSelectors,
} from "./InterviewProfileSingleSlice"
import { useTranslation } from "react-i18next"
import {
  Form,
  Input,
  Button,
  DatePicker,
  Select,
  Row,
  Col,
  Space,
  Modal,
  Upload,
  Skeleton,
  Divider,
  Typography,
  message,
} from "antd"
import { SalaryExpectationType } from "api/generated/namecheck"
import {
  CheckCircleOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  RollbackOutlined,
  SaveOutlined,
  SyncOutlined,
  UploadOutlined,
} from "@ant-design/icons"
import { formItemRequired } from "utils/formItemRules"
import { RootState } from "app/store"
import {
  getEnumRealValue,
  getEnumTranslationKey,
  getEnumValues,
} from "utils/common"
import useFileUpload from "hooks/useFileUpload"
import { MomentFormats } from "utils/dateUtils"
import { getProfileStatusTranslationKey } from "../utils"

const { confirm } = Modal

const InterviewProfileSingle: React.FC<any> = () => {
  const dispatch = useDispatch()
  const params = useParams<any>()
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const {
    uploadFormItemProps,
    uploadProps,
    mapFileToInitialList,
  } = useFileUpload()

  const { positions, sites } = useSelector(
    (state: RootState) => state.interviewProfileSingle
  )

  const isDataLoading = useSelector(profileSingleSliceSelectors.isDataLoading)
  const data = useSelector(profileSingleSliceSelectors.data)
  const editIsEnabled = useSelector(profileSingleSliceSelectors.editIsEnabled)
  const isCvUploaded = useSelector(profileSingleSliceSelectors.isCvUploaded)
  const isIsUploaded = useSelector(profileSingleSliceSelectors.isIsUploaded)

  const [selectedSite, setSelectedSite] = useState<string | null>()

  const availablePositions = useMemo(
    () =>
      positions.filter(item =>
        item.sites?.map(item => item.code).includes(selectedSite)
      ),
    [positions, selectedSite]
  )

  useEffect(() => {
    dispatch(profileSingleSliceActions.fetchPositions())
    dispatch(profileSingleSliceActions.fetchSites())

    dispatch(profileSingleSliceActions.loadData(params.id))

    return () => {
      dispatch(profileSingleSliceActions.resetData())
    }
  }, [dispatch, params])

  // Initial form value set
  useEffect(() => {
    form.setFieldsValue({
      ...data,
      cv: data?.cv ? mapFileToInitialList(data?.cv) : undefined,
      interviewSheet: data?.interviewSheet
        ? mapFileToInitialList(data?.interviewSheet)
        : undefined,
      source: getEnumRealValue(data?.source),
      salaryExpectationType: getEnumRealValue(data?.salaryExpectationType),
    })

    setSelectedSite(data?.siteCode)
  }, [t, form, data, mapFileToInitialList])

  const onFinish = (fieldsValue: any) => {
    confirm({
      title: t("pages.InterviewProfile.modal.title"),
      icon: <ExclamationCircleOutlined />,
      content: t("pages.InterviewProfile.modal.content"),
      okText: t("pages.InterviewProfile.modal.ok"),
      cancelText: t("pages.InterviewProfile.modal.cancel"),
      onOk() {
        return handleSaveModalOk()
      },
    })
  }

  const handleSaveModalOk = async () => {
    await dispatch(profileSingleSliceActions.saveData(form.getFieldsValue()))

    message.success(t("successMessages.interviewProfile.edit"))
  }
  const handleFinalizeModalOk = useCallback(
    async (id?: number) => {
      if (typeof id === "number") {
        try {
          await dispatch(
            profileSingleSliceActions.saveData(form.getFieldsValue())
          )

          await dispatch(profileSingleSliceActions.finalizeItem(id))

          message.success(t("successMessages.interviewProfileList.finalize"))

          return dispatch(profileSingleSliceActions.loadData(id))
        } catch (error) {
          console.log(error)
        }
      }
    },
    [dispatch, t, form]
  )

  const handleDeleteModalOk = useCallback(
    async (id?: number) => {
      if (typeof id === "number") {
        try {
          await dispatch(
            profileSingleSliceActions.saveData(form.getFieldsValue())
          )

          await dispatch(profileSingleSliceActions.deleteItem(id))

          message.success(t("successMessages.interviewProfileList.delete"))

          return dispatch(profileSingleSliceActions.loadData(id))
        } catch (error) {
          console.log(error)
        }
      }
    },
    [dispatch, t, form]
  )

  const handleSiteChange = useCallback(
    (value: string) => {
      setSelectedSite(value)

      const formValues = form.getFieldsValue()

      form.setFieldsValue({
        ...formValues,
        positionId: undefined,
      })
    },
    [form]
  )

  return (
    <>
      <Typography.Title>{t("pages.InterviewProfile.title")}</Typography.Title>
      <Divider />
      {isDataLoading ? (
        <Skeleton active />
      ) : (
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item name={"id"} hidden>
            <Input hidden />
          </Form.Item>
          <Row gutter={24}>
            <Col span={12} key="fullName">
              <Form.Item
                label={t("labels.InterviewProfile.fullName")}
                name={["nameCheckData", "fullName"]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={data?.finalizedAt ? 6 : 12} key="status">
              <Form.Item
                label={t("labels.InterviewProfile.status")}
                name="statusLabel"
              >
                <span className="ant-form-text ant-input ant-input-disabled">
                  {t(getProfileStatusTranslationKey(data))}
                </span>
              </Form.Item>
            </Col>
            {data?.finalizedAt ? (
              <Col span={6} key="finalizedAt">
                <Form.Item
                  label={t("labels.InterviewProfile.finalizedAt")}
                  name="finalizedAt"
                  rules={formItemRequired}
                >
                  <DatePicker
                    showTime
                    format="YYYY-MM-DD HH:mm:ss"
                    disabled
                    className="ant-input"
                  />
                </Form.Item>
              </Col>
            ) : null}
            <Col span={12} key="source">
              <Form.Item
                label={t("labels.InterviewProfile.source")}
                name="sourceLabel"
              >
                <span className="ant-form-text ant-input ant-input-disabled">
                  {t(getEnumTranslationKey("CheckSource", data?.source))}
                </span>
              </Form.Item>
            </Col>
            <Col span={12} key="createdBy">
              <Form.Item
                label={t("labels.InterviewProfile.createdBy")}
                name={["createdBy", "email"]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12} key="dateOfBirth">
              <Form.Item
                label={t("labels.InterviewProfile.dateOfBirth")}
                name={["nameCheckData", "dateOfBirth"]}
              >
                <DatePicker
                  disabled
                  className="ant-input"
                  format={MomentFormats.Date}
                />
              </Form.Item>
            </Col>
            <Col span={12} key="emailAddress">
              <Form.Item
                label={t("labels.InterviewProfile.emailAddress")}
                name={["nameCheckData", "emailAddress"]}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12} key="createdAt">
              <Form.Item
                label={t("labels.InterviewProfile.createdAt")}
                name="createdAt"
              >
                <DatePicker
                  showTime
                  format={MomentFormats.DateTime}
                  disabled
                  className="ant-input"
                />
              </Form.Item>
            </Col>
            <Col span={12} key="expiresAt">
              <Form.Item
                label={t("labels.InterviewProfile.expiresAt")}
                name="expiresAt"
              >
                <DatePicker
                  showTime
                  format={MomentFormats.DateTime}
                  disabled
                  className="ant-input"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12} key="siteCode">
              <Form.Item
                label={t("labels.InterviewProfile.location")}
                name="siteCode"
              >
                <Select<string>
                  disabled={!editIsEnabled}
                  onChange={handleSiteChange}
                  placeholder={t("labels.InterviewProfile.locationPlaceholder")}
                >
                  {sites.map(item => (
                    <Select.Option
                      key={`site_${item.code}`}
                      value={item.code ?? ""}
                    >
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {selectedSite && (
              <Col span={12} key="position">
                <Form.Item
                  label={t("labels.InterviewProfile.position")}
                  name={["positionId"]}
                >
                  <Select
                    disabled={!editIsEnabled}
                    placeholder={t(
                      "labels.InterviewProfile.positionPlaceholder"
                    )}
                  >
                    {availablePositions.map(position => (
                      <Select.Option
                        key={`pos_${position.id}`}
                        value={position?.id ?? -1}
                      >
                        {position.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row gutter={24}>
            <Col span={12} key="salaryExpectationValue">
              <Form.Item
                label={t("labels.InterviewProfile.salaryExpectationValue")}
                name="salaryExpectationValue"
              >
                <Input
                  disabled={!editIsEnabled}
                  placeholder={t(
                    "labels.InterviewProfile.salaryExpectationValuePlaceholder"
                  )}
                  addonAfter=" HUF"
                  type="number"
                />
              </Form.Item>
            </Col>
            <Col span={12} key="salaryExpectationType">
              <Form.Item
                label={t("labels.InterviewProfile.salaryExpectationType")}
                name="salaryExpectationType"
              >
                <Select disabled={!editIsEnabled}>
                  {getEnumValues(SalaryExpectationType).map(value => (
                    <Select.Option key={`set_${value}`} value={value}>
                      {t(getEnumTranslationKey("SalaryExpectationType", value))}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12} key="cv">
              <Form.Item
                {...uploadFormItemProps}
                label={t("labels.InterviewProfile.cv")}
                name="cv"
              >
                <Upload {...uploadProps}>
                  <Button
                    disabled={!editIsEnabled}
                    icon={isCvUploaded ? <SyncOutlined /> : <UploadOutlined />}
                  >
                    {isCvUploaded ? t("forms.change") : t("forms.upload")}
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={12} key="interviewSheet">
              <Form.Item
                {...uploadFormItemProps}
                label={t("labels.InterviewProfile.interviewSheet")}
                name="interviewSheet"
              >
                <Upload {...uploadProps}>
                  <Button
                    disabled={!editIsEnabled}
                    icon={isIsUploaded ? <SyncOutlined /> : <UploadOutlined />}
                  >
                    {isIsUploaded ? t("forms.change") : t("forms.upload")}
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>

          <Divider />

          <Row justify="space-between">
            <Col>
              <Space size="middle">
                <Button
                  size="large"
                  type="primary"
                  ghost
                  icon={<CheckCircleOutlined />}
                  disabled={!data?.isInProgress}
                  onClick={() => {
                    confirm({
                      title: t(
                        "pages.InterviewProfileList.finalizeModal.title"
                      ),
                      icon: <ExclamationCircleOutlined />,
                      content: t(
                        "pages.InterviewProfileList.finalizeModal.content"
                      ),
                      okText: t(
                        "pages.InterviewProfileList.finalizeModal.okText"
                      ),
                      cancelText: t(
                        "pages.InterviewProfileList.finalizeModal.cancelText"
                      ),
                      onOk() {
                        return handleFinalizeModalOk(data?.id)
                      },
                    })
                  }}
                >
                  {t("pages.InterviewProfileList.finalizeModal.okText")}
                </Button>
                <Button
                  type="primary"
                  size="large"
                  icon={<DeleteOutlined />}
                  danger
                  disabled={!data?.isInProgress}
                  onClick={() => {
                    confirm({
                      title: t("pages.InterviewProfileList.deleteModal.title"),
                      icon: <ExclamationCircleOutlined />,
                      content: t(
                        "pages.InterviewProfileList.deleteModal.content"
                      ),
                      okText: t(
                        "pages.InterviewProfileList.deleteModal.okText"
                      ),
                      okType: "danger",
                      cancelText: t(
                        "pages.InterviewProfileList.deleteModal.cancelText"
                      ),
                      onOk() {
                        return handleDeleteModalOk(data?.id)
                      },
                    })
                  }}
                >
                  {t("pages.InterviewProfileList.deleteModal.okText")}
                </Button>
              </Space>
            </Col>
            <Col>
              <Space size="middle">
                <Link to={`/interview-profiles`}>
                  <Button size="large">
                    <RollbackOutlined /> {t("forms.cancel")}
                  </Button>
                </Link>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  disabled={!editIsEnabled}
                >
                  <SaveOutlined />
                  {t("forms.send")}
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      )}
    </>
  )
}

export default InterviewProfileSingle
