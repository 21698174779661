import React, { useEffect } from "react"
import {
  Button,
  Form,
  FormInstance,
  Input,
  Modal,
  ModalProps,
  Space,
} from "antd"
import { PartnerDto } from "api/generated/namecheck"
import { useTranslation } from "react-i18next"
import TextArea from "antd/lib/input/TextArea"
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons"
import {
  formItemEmail,
  formItemRequiredNoWhitespace,
  formItemPhone,
} from "utils/formItemRules"
import styles from "./PartnerEditModal.module.scss"
import { useSelector } from "react-redux"
import { partnersSliceSelectors } from "../PartnersSlice"

interface PartnerEditModalProps extends ModalProps {
  partner?: PartnerDto
  form?: FormInstance<any>
}

const PartnerEditModal: React.FC<PartnerEditModalProps> = ({
  partner,
  form,
  ...rest
}) => {
  const isSaving = useSelector(partnersSliceSelectors.isSaving)
  const { t } = useTranslation()

  useEffect(() => {
    form?.setFieldsValue(partner)
  }, [form, partner])

  return (
    <Modal
      {...rest}
      destroyOnClose={true}
      width={800}
      title={
        partner?.id
          ? t("labels.Partners.modal.editPartner")
          : t("labels.Partners.modal.addNewPartner")
      }
      okButtonProps={{ loading: isSaving }}
    >
      <Form preserve={false} layout="vertical" form={form}>
        <Form.Item hidden name="id">
          <Input hidden />
        </Form.Item>
        <Form.Item
          label={t("labels.Partners.name")}
          name="name"
          rules={formItemRequiredNoWhitespace}
        >
          <Input maxLength={255} />
        </Form.Item>
        <Form.Item label={t("labels.Partners.comment")} name="comment">
          <TextArea maxLength={255} />
        </Form.Item>
        <Form.Item label={t("labels.Partners.contacts")}>
          <Form.List name="contacts">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Space key={key} align="baseline" className={styles.space}>
                    <Form.Item
                      hidden
                      {...restField}
                      name={[name, "id"]}
                      fieldKey={[fieldKey, "id"]}
                    >
                      <Input hidden />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "fullName"]}
                      fieldKey={[fieldKey, "fullName"]}
                      rules={formItemRequiredNoWhitespace}
                    >
                      <Input
                        maxLength={255}
                        placeholder={t(
                          "labels.Partners.contactFields.fullName"
                        )}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "emailAddress"]}
                      fieldKey={[fieldKey, "emailAddress"]}
                      rules={[
                        ...formItemRequiredNoWhitespace,
                        ...formItemEmail,
                      ]}
                    >
                      <Input
                        type="email"
                        maxLength={255}
                        placeholder={t(
                          "labels.Partners.contactFields.emailAddress"
                        )}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "phoneNumber"]}
                      fieldKey={[fieldKey, "phoneNumber"]}
                      rules={[...formItemPhone]}
                    >
                      <Input
                        type="tel"
                        maxLength={255}
                        placeholder={t(
                          "labels.Partners.contactFields.phoneNumber"
                        )}
                      />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    {t("labels.Partners.contactFields.addNew")}
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default PartnerEditModal
