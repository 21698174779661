/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InterviewProfileDto,
    InterviewProfileDtoFromJSON,
    InterviewProfileDtoToJSON,
    InterviewProfileDtoPaginatedResultDto,
    InterviewProfileDtoPaginatedResultDtoFromJSON,
    InterviewProfileDtoPaginatedResultDtoToJSON,
    InterviewProfileUpdateDto,
    InterviewProfileUpdateDtoFromJSON,
    InterviewProfileUpdateDtoToJSON,
} from '../models';

export interface DeleteRequest {
    id: number;
}

export interface FinalizeRequest {
    id: number;
}

export interface GetRequest {
    id: number;
}

export interface GetAllRequest {
    createdBy?: string;
    personName?: string;
    hideDeleted?: boolean;
    take?: number;
    page?: number;
}

export interface UpdateRequest {
    id: number;
    interviewProfileUpdateDto?: InterviewProfileUpdateDto;
}

/**
 * 
 */
export class InterviewProfileApi extends runtime.BaseAPI {

    /**
     * Interjúprofil törlése.   Jogkör: Bármely bejelentkezett felhasználó.
     */
    async _deleteRaw(requestParameters: DeleteRequest): Promise<runtime.ApiResponse<InterviewProfileDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling _delete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/InterviewProfile/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InterviewProfileDtoFromJSON(jsonValue));
    }

    /**
     * Interjúprofil törlése.   Jogkör: Bármely bejelentkezett felhasználó.
     */
    async _delete(requestParameters: DeleteRequest): Promise<InterviewProfileDto> {
        const response = await this._deleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Interjúprofil véglegesítése.   Jogkör: Bármely bejelentkezett felhasználó.
     */
    async finalizeRaw(requestParameters: FinalizeRequest): Promise<runtime.ApiResponse<InterviewProfileDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling finalize.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/InterviewProfile/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InterviewProfileDtoFromJSON(jsonValue));
    }

    /**
     * Interjúprofil véglegesítése.   Jogkör: Bármely bejelentkezett felhasználó.
     */
    async finalize(requestParameters: FinalizeRequest): Promise<InterviewProfileDto> {
        const response = await this.finalizeRaw(requestParameters);
        return await response.value();
    }

    /**
     * Interjúprofil lekérése.   Jogkör: Bármely bejelentkezett felhasználó.
     */
    async getRaw(requestParameters: GetRequest): Promise<runtime.ApiResponse<InterviewProfileDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling get.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/InterviewProfile/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InterviewProfileDtoFromJSON(jsonValue));
    }

    /**
     * Interjúprofil lekérése.   Jogkör: Bármely bejelentkezett felhasználó.
     */
    async get(requestParameters: GetRequest): Promise<InterviewProfileDto> {
        const response = await this.getRaw(requestParameters);
        return await response.value();
    }

    /**
     * Interjúprofilok lekérése.   Jogkör: Bármely bejelentkezett felhasználó.
     */
    async getAllRaw(requestParameters: GetAllRequest): Promise<runtime.ApiResponse<InterviewProfileDtoPaginatedResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.createdBy !== undefined) {
            queryParameters['CreatedBy'] = requestParameters.createdBy;
        }

        if (requestParameters.personName !== undefined) {
            queryParameters['PersonName'] = requestParameters.personName;
        }

        if (requestParameters.hideDeleted !== undefined) {
            queryParameters['HideDeleted'] = requestParameters.hideDeleted;
        }

        if (requestParameters.take !== undefined) {
            queryParameters['Take'] = requestParameters.take;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/InterviewProfile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InterviewProfileDtoPaginatedResultDtoFromJSON(jsonValue));
    }

    /**
     * Interjúprofilok lekérése.   Jogkör: Bármely bejelentkezett felhasználó.
     */
    async getAll(requestParameters: GetAllRequest): Promise<InterviewProfileDtoPaginatedResultDto> {
        const response = await this.getAllRaw(requestParameters);
        return await response.value();
    }

    /**
     * Interjúprofil módosítása.   Jogkör: Bármely bejelentkezett felhasználó.
     */
    async updateRaw(requestParameters: UpdateRequest): Promise<runtime.ApiResponse<InterviewProfileDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling update.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/InterviewProfile/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InterviewProfileUpdateDtoToJSON(requestParameters.interviewProfileUpdateDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InterviewProfileDtoFromJSON(jsonValue));
    }

    /**
     * Interjúprofil módosítása.   Jogkör: Bármely bejelentkezett felhasználó.
     */
    async update(requestParameters: UpdateRequest): Promise<InterviewProfileDto> {
        const response = await this.updateRaw(requestParameters);
        return await response.value();
    }

}
