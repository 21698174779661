/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SiteDto,
    SiteDtoFromJSON,
    SiteDtoFromJSONTyped,
    SiteDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreatePositionDto
 */
export interface CreatePositionDto {
    /**
     * 
     * @type {string}
     * @memberof CreatePositionDto
     */
    positionCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreatePositionDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreatePositionDto
     */
    comment?: string | null;
    /**
     * 
     * @type {Array<SiteDto>}
     * @memberof CreatePositionDto
     */
    sites?: Array<SiteDto> | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreatePositionDto
     */
    isActive?: boolean;
}

export function CreatePositionDtoFromJSON(json: any): CreatePositionDto {
    return CreatePositionDtoFromJSONTyped(json, false);
}

export function CreatePositionDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePositionDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'positionCode': !exists(json, 'positionCode') ? undefined : json['positionCode'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
        'sites': !exists(json, 'sites') ? undefined : (json['sites'] === null ? null : (json['sites'] as Array<any>).map(SiteDtoFromJSON)),
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
    };
}

export function CreatePositionDtoToJSON(value?: CreatePositionDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'positionCode': value.positionCode,
        'name': value.name,
        'comment': value.comment,
        'sites': value.sites === undefined ? undefined : (value.sites === null ? null : (value.sites as Array<any>).map(SiteDtoToJSON)),
        'isActive': value.isActive,
    };
}


