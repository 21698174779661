import {
  AccountApi,
  Configuration,
  ConfigurationParameters,
  FileApi,
  InterviewProfileApi,
  LivenessCheckApi,
  Middleware,
  PositionApi,
  NameCheckApi,
  PartnerApi,
} from "api/generated/namecheck"
import { BlacklistedCompanyApi } from "./generated/namecheck"
import { errorHandlingMiddleware } from "./middleware/errorHandlingMiddleware"

interface ApiBaseConfigProps {
  basePath?: string
}

const apiMiddleware: Middleware[] = [...errorHandlingMiddleware]

export const apiBaseConfig = (
  props?: ApiBaseConfigProps
): ConfigurationParameters => ({
  basePath: props?.basePath,
  middleware: apiMiddleware,
})

const namecheckConfig: Configuration = new Configuration(
  apiBaseConfig({
    basePath: process.env.REACT_APP_NAMECHECK_API_URL,
  })
)

export const api = {
  namecheck: {
    livenessCheck: new LivenessCheckApi(namecheckConfig),
    account: new AccountApi(namecheckConfig),
    interviewProfile: new InterviewProfileApi(namecheckConfig),
    position: new PositionApi(namecheckConfig),
    nameCheck: new NameCheckApi(namecheckConfig),
    file: new FileApi(namecheckConfig),
    partner: new PartnerApi(namecheckConfig),
    blacklistedCompany: new BlacklistedCompanyApi(namecheckConfig),
  },
}
