import { Card, Layout } from "antd"
import Navigation from "./Navigation"
import useAuth from "features/auth/useAuth"
import { Container } from "./Container"
import { FullPageSpinner } from "./FullPageSpinner"

const { Content, Header } = Layout

interface PrivateLayoutProps {
  children: React.ReactNode
}

export const PrivateLayout: React.FC<PrivateLayoutProps> = ({ children }) => {
  const { loading } = useAuth() // e.g. when refreshing user login

  return (
    <Layout>
      <Header>
        <Container fluid>
          <Navigation />
        </Container>
      </Header>
      <Content>
        <Container fluid>
          <Card>{children}</Card>
        </Container>
      </Content>
      {loading && <FullPageSpinner transparent />}
    </Layout>
  )
}
