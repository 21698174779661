import { useMemo } from "react"
import { Avatar, Button, Menu, Popover, Space } from "antd"
import useAuth from "features/auth/useAuth"
import { useLocation } from "react-router"
import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useGoogleLogout } from "react-google-login"
import {
  ApartmentOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  FundProjectionScreenOutlined,
  HomeOutlined,
  LogoutOutlined,
  ProfileOutlined,
  SecurityScanOutlined,
  TeamOutlined,
} from "@ant-design/icons"
import styles from "./Navigation.module.scss"
import { authSliceActions } from "features/auth/authSlice"
import { useTranslation } from "react-i18next"
import { UserRole } from "api/generated/namecheck"

const { SubMenu } = Menu

export interface MenuItem {
  path: string
  label: string
  roles?: UserRole[]
  subRoutes?: MenuItem[]
  icon?: JSX.Element
}

const clientId = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID as string

const Navigation: React.FC = () => {
  const { hasUserRole, user, userName } = useAuth()
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const menuItems: MenuItem[] = useMemo(
    () => [
      {
        path: "",
        label: t("menu.header.home"),
        icon: <HomeOutlined />,
      },
      {
        path: "namecheck-results",
        label: t("menu.header.namecheck"),
        icon: <SecurityScanOutlined />,
        roles: [UserRole.Admin],
      },
      {
        path: "interview-profiles",
        label: t("menu.header.interview-profiles"),
        icon: <ProfileOutlined />,
      },
      {
        path: "positions",
        label: t("menu.header.positions"),
        icon: <FundProjectionScreenOutlined />,
        roles: [UserRole.Admin],
      },
      {
        path: "partners",
        label: t("menu.header.partners"),
        icon: <ApartmentOutlined />,
        roles: [UserRole.Admin],
      },
      {
        path: "blacklist",
        label: t("menu.header.blacklist"),
        icon: <ExclamationCircleOutlined />,
      },
      {
        path: "accounts",
        label: t("menu.header.accounts"),
        icon: <TeamOutlined />,
        roles: [UserRole.Admin],
      },
    ],
    [t]
  )

  const handleGoogleLogoutSuccess = () => {
    dispatch(authSliceActions.logOut())
  }

  const { signOut: googleSignOut } = useGoogleLogout({
    clientId,
    onLogoutSuccess: handleGoogleLogoutSuccess,
  })

  const menuItemsVisibleForUser = useMemo(
    () => menuItems.filter(({ roles }) => hasUserRole(roles)),
    [hasUserRole, menuItems]
  )

  const location = useLocation()

  const getVisibleSubMenuItemsForUser = (subRoutes: MenuItem[]) =>
    subRoutes.filter(({ roles }) => hasUserRole(roles))

  const handleLogout = () => {
    googleSignOut()
  }

  return (
    <div className={styles.wrapper}>
      <Menu defaultSelectedKeys={[location.pathname]} mode="horizontal">
        {menuItemsVisibleForUser.map(({ path, label, subRoutes, icon }) => {
          return subRoutes ? (
            <SubMenu
              key={`/${path}`}
              title={<Link to={`/${path}`}>{label}</Link>}
              icon={icon}
            >
              {getVisibleSubMenuItemsForUser(subRoutes).map(subRoute => (
                <Menu.Item key={`/${path}/${subRoute.path}`}>
                  {subRoute.label}
                  <Link to={`/${path}/${subRoute.path}`} />
                </Menu.Item>
              ))}
            </SubMenu>
          ) : (
            <Menu.Item key={`/${path}`} icon={icon}>
              {label}
              <Link to={`/${path}`} />
            </Menu.Item>
          )
        })}
      </Menu>
      <Popover
        content={
          <Button type="link" onClick={handleLogout}>
            <LogoutOutlined />
            {t("menu.header.logout")}
          </Button>
        }
        placement="bottom"
      >
        <Space>
          <Avatar src={user?.profileImageUrl} />
          {userName}
          <DownOutlined style={{ fontSize: "10px" }} />
        </Space>
      </Popover>
    </div>
  )
}

export default Navigation
