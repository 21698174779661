import styles from "./Container.module.scss"
import classNames from "classnames"

interface ContainerProps {
  children: React.ReactNode
  className?: string
  fluid?: boolean
}

export const Container: React.FC<ContainerProps> = ({
  children,
  className,
  fluid,
}) => {
  return (
    <div
      className={classNames(
        styles.container,
        { [styles.fluid]: fluid },
        className
      )}
    >
      {children}
    </div>
  )
}
