/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserProfileDto,
    UserProfileDtoFromJSON,
    UserProfileDtoFromJSONTyped,
    UserProfileDtoToJSON,
    UserRole,
    UserRoleFromJSON,
    UserRoleFromJSONTyped,
    UserRoleToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserTokenDto
 */
export interface UserTokenDto {
    /**
     * 
     * @type {string}
     * @memberof UserTokenDto
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserTokenDto
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserTokenDto
     */
    token?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof UserTokenDto
     */
    expires?: Date;
    /**
     * 
     * @type {UserProfileDto}
     * @memberof UserTokenDto
     */
    profile?: UserProfileDto;
    /**
     * 
     * @type {Array<UserRole>}
     * @memberof UserTokenDto
     */
    roles?: Array<UserRole> | null;
}

export function UserTokenDtoFromJSON(json: any): UserTokenDto {
    return UserTokenDtoFromJSONTyped(json, false);
}

export function UserTokenDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserTokenDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'token': !exists(json, 'token') ? undefined : json['token'],
        'expires': !exists(json, 'expires') ? undefined : (new Date(json['expires'])),
        'profile': !exists(json, 'profile') ? undefined : UserProfileDtoFromJSON(json['profile']),
        'roles': !exists(json, 'roles') ? undefined : (json['roles'] === null ? null : (json['roles'] as Array<any>).map(UserRoleFromJSON)),
    };
}

export function UserTokenDtoToJSON(value?: UserTokenDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'email': value.email,
        'token': value.token,
        'expires': value.expires === undefined ? undefined : (value.expires.toISOString()),
        'profile': UserProfileDtoToJSON(value.profile),
        'roles': value.roles === undefined ? undefined : (value.roles === null ? null : (value.roles as Array<any>).map(UserRoleToJSON)),
    };
}


