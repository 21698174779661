import { notification } from "antd"
import {
  Middleware,
  RequestContext,
  ResponseContext,
} from "api/generated/namecheck"
import i18n from "app/i18n"
import { getTokenFromStorage } from "features/auth/utils"

const exampleError = {
  errors: { ValidationError: ["MissingRegistrationInvite"] },
  type: "https://httpstatuses.com/400",
  title: "Bad Request",
  status: 400,
  detail: "(203) Missing registration invite",
  instance: null,
  extensions: {},
}

type RequestErrorBody = typeof exampleError

export const errorHandlingMiddleware: Middleware[] = [
  {
    pre: async (ctx: RequestContext): Promise<void> => {
      ctx.init.headers = {
        ...ctx.init.headers,
        Authorization: `Bearer ${getTokenFromStorage()}`,
      }

      return Promise.resolve()
    },
  },
  {
    post: async (ctx: ResponseContext): Promise<void> => {
      if (ctx.response.status === 500) {
        notification.error({
          message: i18n.t("errors.serverError.title"),
          duration: null,
        })
      } else if (ctx.response.status === 401) {
        // FIXME: VALAMIÉRT NEM MŰKÖDIK ÍGY, NINCS MÉG INICIALIZÁLVA A STORE ILYENKOR
        /* store.dispatch(logOut()) */

        notification.error({
          message: i18n.t("errors.authenticationError.title"),
          description: i18n.t("errors.authenticationError.description"),
          duration: null,
        })
      } else if (ctx.response.status === 400) {
        const error: RequestErrorBody = await ctx.response.json()

        const validationErrors = error.errors.ValidationError.map(error =>
          i18n.t(`backend-errors.${error}`)
        )

        notification.error({
          message: i18n.t("backend-errors.types.validationError"),
          description: validationErrors.join("\n"),
          duration: null,
        })
      }

      return Promise.resolve()
    },
  },
]
