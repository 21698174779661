import { TablePaginationConfig } from "antd"
import { FeaturePagination } from "models/FeaturePagination"
import { useMemo } from "react"

const useSSTablePagination = (
  pagination: FeaturePagination
): TablePaginationConfig => {
  const paginationConfig = useMemo<TablePaginationConfig>(
    () => ({
      pageSizeOptions: ["20", "50"],
      showSizeChanger: true,
      pageSize: pagination.take,
      current: (pagination.page ?? 0) + 1,
      total: pagination.total,
    }),
    [pagination.page, pagination.take, pagination.total]
  )

  return paginationConfig
}

export default useSSTablePagination
