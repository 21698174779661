import {
  Button,
  Divider,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
  Modal,
  message,
} from "antd"
import { CheckSource } from "api/generated/namecheck"
import { useTranslation } from "react-i18next"
import {
  getProfileStatusTranslationKey,
  getProfileStatusKey,
  getTagColor,
} from "../utils"
import { useDispatch, useSelector } from "react-redux"
import { useCallback, useEffect } from "react"
import {
  CheckCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  FileTextOutlined,
  ProfileOutlined,
} from "@ant-design/icons"
import {
  interviewProfileListSliceActions,
  interviewProfileListSliceSelectors,
} from "./InterviewProfileListSlice"
import { InterviewProfile } from "models/InterviewProfile"
import { Link } from "react-router-dom"
import { ColumnsType, TablePaginationConfig } from "antd/lib/table"
import { formatMomentDate } from "utils/dateUtils"
import { RootState } from "app/store"
import moment from "moment"
import { getEnumTranslationKey } from "utils/common"
import useFileUpload from "hooks/useFileUpload"
import useTableFilters from "hooks/useTableFilters"
import useSSTablePagination from "hooks/useSSTablePagination"
import NumberFormat from "react-number-format"

const { confirm } = Modal

const InterviewProfileList: React.FC = () => {
  const loading = useSelector(interviewProfileListSliceSelectors.isLoading)
  const data = useSelector(interviewProfileListSliceSelectors.data)

  const { filterTableColumnProps } = useTableFilters()
  const { t } = useTranslation()

  const { pagination } = useSelector(
    (state: RootState) => state.interviewProfileList
  )

  const { downloadFile } = useFileUpload()

  const columns: ColumnsType<InterviewProfile> = [
    {
      title: t("labels.InterviewProfile.status"),
      dataIndex: "status",
      key: "status",
      render: (text: string, record: InterviewProfile) => (
        <Tag
          color={getTagColor(getProfileStatusKey(record))}
          key={record.status}
        >
          {t(getProfileStatusTranslationKey(record))}
        </Tag>
      ),
    },
    {
      title: t("labels.InterviewProfile.fullName"),
      dataIndex: ["nameCheckData", "fullName"],
      ...filterTableColumnProps,
    },
    {
      title: t("labels.InterviewProfile.expiresAt"),
      dataIndex: "expiresAt",
      key: "expiresAt",
      render: (text: string, record: InterviewProfile) =>
        formatMomentDate(record.expiresAt),
    },
    {
      title: t("labels.InterviewProfile.location"),
      dataIndex: ["site", "name"],
    },
    {
      title: t("labels.InterviewProfile.position"),
      dataIndex: ["position", "name"],
    },
    {
      title: t("labels.InterviewProfile.salaryExpectationValue"),
      dataIndex: "salaryExpectationValue",
      render: (text: string, record: InterviewProfile) => (
        <>
          {record.salaryExpectationValue && (
            <>
              <span>
                <NumberFormat
                  value={record.salaryExpectationValue}
                  displayType="text"
                  thousandSeparator="."
                  decimalSeparator=","
                  suffix=" HUF"
                />
              </span>
              <br />
            </>
          )}
          <small className="no-wrap">
            {t(
              getEnumTranslationKey(
                "SalaryExpectationType",
                record.salaryExpectationType
              )
            )}
          </small>
        </>
      ),
    },
    {
      title: t("labels.InterviewProfile.documents"),
      dataIndex: "documents",
      render: (text: string, record: InterviewProfile) => (
        <Space size="small" align="center">
          <Tooltip title={t("labels.InterviewProfileList.cv")} key="cv">
            <Button
              size="small"
              shape="circle"
              icon={<FileTextOutlined />}
              disabled={!record.cv}
              onClick={() => {
                if (typeof record.cv?.id === "number") {
                  downloadFile(record.cv?.id)
                }
              }}
            />
          </Tooltip>
          <Tooltip
            title={t("labels.InterviewProfileList.interviewSheet")}
            key="interviewSheet"
          >
            <Button
              size="small"
              shape="circle"
              icon={<ProfileOutlined />}
              disabled={!record.interviewSheet}
              onClick={() => {
                if (typeof record.interviewSheet?.id === "number") {
                  downloadFile(record.interviewSheet?.id)
                }
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
    {
      title: t("labels.InterviewProfile.createdBy"),
      dataIndex: ["createdBy", "email"],
      ...filterTableColumnProps,
    },
    {
      title: t("labels.InterviewProfile.source"),
      dataIndex: "source",
      render: (value: CheckSource) =>
        t(getEnumTranslationKey("CheckSource", value)),
    },
    {
      title: t("labels.InterviewProfile.finalizedAt"),
      dataIndex: "finalizedAt",
      render: (finalizedAt: moment.Moment) => formatMomentDate(finalizedAt),
    },
    {
      title: "",
      key: "actions",
      fixed: "right",
      render: (text: string, record: InterviewProfile) => (
        <Space size="small" align="center">
          <Tooltip
            title={
              record?.isInProgress
                ? t("labels.InterviewProfileList.edit")
                : t("labels.InterviewProfileList.view")
            }
            key="edit"
          >
            <Link to={`/interview-profiles/${record.id}`}>
              <Button
                shape="circle"
                icon={record?.isInProgress ? <EditOutlined /> : <EyeOutlined />}
              />
            </Link>
          </Tooltip>
          <Tooltip
            title={t("labels.InterviewProfileList.finalize")}
            key="finalize"
          >
            <Button
              shape="circle"
              type="primary"
              ghost
              icon={<CheckCircleOutlined />}
              disabled={!record?.isInProgress}
              onClick={() => {
                confirm({
                  title: t("pages.InterviewProfileList.finalizeModal.title"),
                  icon: <ExclamationCircleOutlined />,
                  content: t(
                    "pages.InterviewProfileList.finalizeModal.content"
                  ),
                  okText: t("pages.InterviewProfileList.finalizeModal.okText"),
                  cancelText: t(
                    "pages.InterviewProfileList.finalizeModal.cancelText"
                  ),
                  onOk() {
                    return handleFinalizeModalOk(record.id)
                  },
                })
              }}
            />
          </Tooltip>
          <Tooltip title={t("labels.InterviewProfileList.delete")} key="delete">
            <Button
              type="primary"
              shape="circle"
              icon={<DeleteOutlined />}
              danger
              disabled={!record?.isInProgress}
              onClick={() => {
                confirm({
                  title: t("pages.InterviewProfileList.deleteModal.title"),
                  icon: <ExclamationCircleOutlined />,
                  content: t("pages.InterviewProfileList.deleteModal.content"),
                  okText: t("pages.InterviewProfileList.deleteModal.okText"),
                  okType: "danger",
                  cancelText: t(
                    "pages.InterviewProfileList.deleteModal.cancelText"
                  ),
                  onOk() {
                    return handleDeleteModalOk(record.id)
                  },
                })
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ]

  const dispatch = useDispatch()

  const loadTableData = useCallback(
    () =>
      dispatch(
        interviewProfileListSliceActions.loadData({
          page: pagination.page,
          take: pagination.take,
        })
      ),
    [dispatch, pagination.page, pagination.take]
  )

  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleTableChange = useCallback(
    (newPaginationConfig: TablePaginationConfig, filters: any) => {
      dispatch(
        interviewProfileListSliceActions.loadData({
          page: (newPaginationConfig.current ?? 1) - 1,
          take: newPaginationConfig.pageSize,
          personName: filters?.["nameCheckData.fullName"]?.[0],
          createdBy: filters?.["createdBy.email"]?.[0],
        })
      )
    },
    [dispatch]
  )

  const paginationConfig = useSSTablePagination(pagination)

  const handleFinalizeModalOk = useCallback(
    async (id?: number) => {
      if (typeof id === "number") {
        try {
          await dispatch(interviewProfileListSliceActions.finalizeItem(id))

          message.success(t("successMessages.interviewProfileList.finalize"))

          return loadTableData()
        } catch (error) {
          console.log(error)
        }
      }
    },
    [dispatch, loadTableData, t]
  )

  const handleDeleteModalOk = useCallback(
    async (id?: number) => {
      if (typeof id === "number") {
        try {
          await dispatch(interviewProfileListSliceActions.deleteItem(id))

          message.success(t("successMessages.interviewProfileList.delete"))

          return loadTableData()
        } catch (error) {
          console.log(error)
        }
      }
    },
    [dispatch, loadTableData, t]
  )

  return (
    <>
      <Typography.Title>
        {t("pages.InterviewProfileList.title")}
      </Typography.Title>
      <Divider />
      <Table
        dataSource={data}
        columns={columns}
        loading={loading}
        rowKey="id"
        scroll={{ x: true }}
        pagination={paginationConfig}
        onChange={handleTableChange}
      />
    </>
  )
}

export default InterviewProfileList
