/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InviteStatus,
    InviteStatusFromJSON,
    InviteStatusFromJSONTyped,
    InviteStatusToJSON,
    PartnerDto,
    PartnerDtoFromJSON,
    PartnerDtoFromJSONTyped,
    PartnerDtoToJSON,
    UserProfileDto,
    UserProfileDtoFromJSON,
    UserProfileDtoFromJSONTyped,
    UserProfileDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface RegistrationInviteDto
 */
export interface RegistrationInviteDto {
    /**
     * 
     * @type {number}
     * @memberof RegistrationInviteDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof RegistrationInviteDto
     */
    invitationCode?: string;
    /**
     * 
     * @type {string}
     * @memberof RegistrationInviteDto
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RegistrationInviteDto
     */
    emailSuccessfullySent?: boolean | null;
    /**
     * 
     * @type {Date}
     * @memberof RegistrationInviteDto
     */
    createdAt?: Date | null;
    /**
     * 
     * @type {UserProfileDto}
     * @memberof RegistrationInviteDto
     */
    createdBy?: UserProfileDto;
    /**
     * 
     * @type {Date}
     * @memberof RegistrationInviteDto
     */
    acceptedAt?: Date | null;
    /**
     * 
     * @type {InviteStatus}
     * @memberof RegistrationInviteDto
     */
    status?: InviteStatus;
    /**
     * 
     * @type {number}
     * @memberof RegistrationInviteDto
     */
    partnerId?: number;
    /**
     * 
     * @type {PartnerDto}
     * @memberof RegistrationInviteDto
     */
    partner?: PartnerDto;
    /**
     * 
     * @type {boolean}
     * @memberof RegistrationInviteDto
     */
    isDeactivated?: boolean;
    /**
     * 
     * @type {UserProfileDto}
     * @memberof RegistrationInviteDto
     */
    registeredAccount?: UserProfileDto;
}

export function RegistrationInviteDtoFromJSON(json: any): RegistrationInviteDto {
    return RegistrationInviteDtoFromJSONTyped(json, false);
}

export function RegistrationInviteDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegistrationInviteDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'invitationCode': !exists(json, 'invitationCode') ? undefined : json['invitationCode'],
        'emailAddress': !exists(json, 'emailAddress') ? undefined : json['emailAddress'],
        'emailSuccessfullySent': !exists(json, 'emailSuccessfullySent') ? undefined : json['emailSuccessfullySent'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (json['createdAt'] === null ? null : new Date(json['createdAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : UserProfileDtoFromJSON(json['createdBy']),
        'acceptedAt': !exists(json, 'acceptedAt') ? undefined : (json['acceptedAt'] === null ? null : new Date(json['acceptedAt'])),
        'status': !exists(json, 'status') ? undefined : InviteStatusFromJSON(json['status']),
        'partnerId': !exists(json, 'partner_Id') ? undefined : json['partner_Id'],
        'partner': !exists(json, 'partner') ? undefined : PartnerDtoFromJSON(json['partner']),
        'isDeactivated': !exists(json, 'isDeactivated') ? undefined : json['isDeactivated'],
        'registeredAccount': !exists(json, 'registeredAccount') ? undefined : UserProfileDtoFromJSON(json['registeredAccount']),
    };
}

export function RegistrationInviteDtoToJSON(value?: RegistrationInviteDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'invitationCode': value.invitationCode,
        'emailAddress': value.emailAddress,
        'emailSuccessfullySent': value.emailSuccessfullySent,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt === null ? null : value.createdAt.toISOString()),
        'createdBy': UserProfileDtoToJSON(value.createdBy),
        'acceptedAt': value.acceptedAt === undefined ? undefined : (value.acceptedAt === null ? null : value.acceptedAt.toISOString()),
        'status': InviteStatusToJSON(value.status),
        'partner_Id': value.partnerId,
        'partner': PartnerDtoToJSON(value.partner),
        'isDeactivated': value.isDeactivated,
        'registeredAccount': UserProfileDtoToJSON(value.registeredAccount),
    };
}


