import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { api } from "api"
import { FeatureState } from "models/FeatureState"
import { AppThunk, RootState } from "app/store"
import { FeaturePagination } from "models/FeaturePagination"
import {
  AcceptInviteRequest,
  CreateInviteRequest,
  GetAllInvitesRequest,
  GetPartnerListPagedRequest,
  PartnerDto,
  PartnerDtoPaginatedResultDto,
  RegistrationInviteDto,
  RegistrationInviteDtoPaginatedResultDto,
  SetDeactivatedFlagForAccountRequest,
} from "api/generated/namecheck"
import { UserActivationDto } from "api/generated/namecheck/models/UserActivationDto"
import { getLocationBasedLink, registrationPathBase } from "../utils"

interface AccountListState {
  featureState: FeatureState
  partnerState: FeatureState
  inviteState: FeatureState
  deactivationState: FeatureState
  partners: PartnerDto[]
  list: RegistrationInviteDto[]
  generatedInvite?: RegistrationInviteDto
  pagination: FeaturePagination
}

const initialState: AccountListState = {
  featureState: FeatureState.Initial,
  partnerState: FeatureState.Initial,
  inviteState: FeatureState.Initial,
  deactivationState: FeatureState.Initial,
  partners: [],
  list: [],
  generatedInvite: undefined,
  pagination: {
    take: 50,
    page: 0,
    total: 0,
  },
}

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    reset: () => ({ ...initialState }),
    fetchAccountDataStarted(state) {
      state.featureState = FeatureState.Loading
    },
    fetchAccountDataSucceeded(
      state,
      { payload }: PayloadAction<RegistrationInviteDto[]>
    ) {
      state.list = payload
      state.featureState = FeatureState.Success
    },
    fetchAccountDataFailed(state) {
      state.featureState = FeatureState.Error
    },
    fetchPartnersStarted(state) {
      state.partnerState = FeatureState.Loading
    },
    fetchPartnersSucceeded(state, { payload }: PayloadAction<PartnerDto[]>) {
      state.partners = payload
      state.partnerState = FeatureState.Success
    },
    fetchPartnersFailed(state) {
      state.partnerState = FeatureState.Error
    },
    fetchInviteStarted(state) {
      state.inviteState = FeatureState.Loading
    },
    fetchInviteSucceeded(
      state,
      { payload }: PayloadAction<RegistrationInviteDto>
    ) {
      state.inviteState = FeatureState.Success
      state.generatedInvite = payload
    },
    fetchInviteFailed(state) {
      state.inviteState = FeatureState.Error
    },
    fetchAccountDeactivationStarted(state) {
      state.deactivationState = FeatureState.Loading
    },
    fetchAccountDeactivationSucceeded(
      state,
      { payload }: PayloadAction<RegistrationInviteDto>
    ) {
      state.deactivationState = FeatureState.Success
    },
    fetchAccountDeactivationFailed(state) {
      state.deactivationState = FeatureState.Error
    },
    setPagination(state, { payload }: PayloadAction<FeaturePagination>) {
      state.pagination = payload
      state.featureState = FeatureState.Success
    },
    resetRegistrationInvite(state) {
      state.inviteState = FeatureState.Initial
      state.generatedInvite = initialState.generatedInvite
    },
  },
})

export const accountSliceReducer = accountSlice.reducer

/**
 * SELECTORS
 */

const loadingSelector = (state: RootState): boolean =>
  state.account.featureState === FeatureState.Loading

export const accountSliceSelectors = {
  isLoading: loadingSelector,
}

/**
 * ACTIONS
 */

const {
  fetchAccountDataStarted,
  fetchAccountDataFailed,
  fetchAccountDataSucceeded,
  fetchPartnersStarted,
  fetchPartnersFailed,
  fetchPartnersSucceeded,
  fetchInviteStarted,
  fetchInviteFailed,
  fetchInviteSucceeded,
  fetchAccountDeactivationStarted,
  fetchAccountDeactivationFailed,
  fetchAccountDeactivationSucceeded,
  reset,
  resetRegistrationInvite,
  setPagination,
} = accountSlice.actions

const loadData = (
  params: GetAllInvitesRequest = {}
): AppThunk => async dispatch => {
  dispatch(fetchAccountDataStarted())

  return api.namecheck.account
    .getAllInvites(params)
    .then((data: RegistrationInviteDtoPaginatedResultDto) => {
      const { results, ...pagination } = data
      dispatch(fetchAccountDataSucceeded(results ?? []))
      dispatch(setPagination(pagination))
    })
    .catch((error: any) => {
      dispatch(fetchAccountDataFailed())
      return Promise.reject(error)
    })
}

const activate = (
  id?: string
): AppThunk<Promise<RegistrationInviteDto>> => async dispatch => {
  dispatch(fetchAccountDeactivationStarted())
  const request: SetDeactivatedFlagForAccountRequest = {
    userActivationDto: {
      userInviteCode: id,
      isDeactivated: false,
    } as UserActivationDto,
  }
  return api.namecheck.account
    .setDeactivatedFlagForAccount(request)
    .then((data: RegistrationInviteDto) => {
      dispatch(fetchAccountDeactivationSucceeded(data))
      return Promise.resolve(data)
    })
    .catch((error: any) => {
      dispatch(fetchAccountDeactivationFailed())
      return Promise.reject(error)
    })
}

const deactivate = (id?: string): AppThunk => async dispatch => {
  dispatch(fetchAccountDeactivationStarted())
  const request: SetDeactivatedFlagForAccountRequest = {
    userActivationDto: {
      userInviteCode: id,
      isDeactivated: true,
    } as UserActivationDto,
  }
  return api.namecheck.account
    .setDeactivatedFlagForAccount(request)
    .then((data: RegistrationInviteDto) => {
      dispatch(fetchAccountDeactivationSucceeded(data))
      return Promise.resolve(data)
    })
    .catch((error: any) => {
      dispatch(fetchAccountDeactivationFailed())
      return Promise.reject(error)
    })
}

const loadPartners = (
  params: GetPartnerListPagedRequest = {}
): AppThunk => async dispatch => {
  dispatch(fetchPartnersStarted())

  return api.namecheck.partner
    .getPartnerListPaged(params)
    .then((data: PartnerDtoPaginatedResultDto) => {
      dispatch(fetchPartnersSucceeded(data.results ?? []))
    })
    .catch((error: any) => {
      dispatch(fetchPartnersFailed())
      return Promise.reject(error)
    })
}

const createInvite = (
  data: RegistrationInviteDto
): AppThunk<Promise<RegistrationInviteDto>> => async dispatch => {
  const request: CreateInviteRequest = {
    emailAddress: data.emailAddress ?? "",
    partnerId: data.partnerId ?? -1,
    urlFormat: getLocationBasedLink(registrationPathBase),
  }
  dispatch(fetchInviteStarted())

  return api.namecheck.account
    .createInvite(request)
    .then((data: RegistrationInviteDto) => {
      dispatch(fetchInviteSucceeded(data))
      return Promise.resolve(data)
    })
    .catch((error: any) => {
      dispatch(fetchInviteFailed())
      return Promise.reject(error)
    })
}

const acceptInvite = (
  data: RegistrationInviteDto
): AppThunk<Promise<RegistrationInviteDto>> => async dispatch => {
  const request: AcceptInviteRequest = {
    emailAddress: data.emailAddress ?? "",
    invitationCode: data.invitationCode,
  }
  dispatch(fetchInviteStarted())

  return api.namecheck.account
    .acceptInvite(request)
    .then((data: RegistrationInviteDto) => {
      dispatch(fetchInviteSucceeded(data))
      dispatch(resetRegistrationInvite())
      return Promise.resolve(data)
    })
    .catch((error: any) => {
      dispatch(fetchInviteFailed())
      return Promise.reject(error)
    })
}

export const accountSliceActions = {
  loadData,
  loadPartners,
  activate,
  deactivate,
  createInvite,
  acceptInvite,
  reset,
  resetRegistrationInvite,
}
