/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    FileDto,
    FileDtoFromJSON,
    FileDtoToJSON,
    FileType,
    FileTypeFromJSON,
    FileTypeToJSON,
} from '../models';

export interface DownloadFileRequest {
    type: FileType;
    name: string;
}

export interface DownloadFileByIdRequest {
    id: number;
}

export interface UploadFileRequest {
    profileId: number;
    type: FileType;
    file?: Blob;
}

/**
 * 
 */
export class FileApi extends runtime.BaseAPI {

    /**
     * Fájl letöltése.  Jogkör: Bármely bejelentkezett felhasználó.
     */
    async downloadFileRaw(requestParameters: DownloadFileRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling downloadFile.');
        }

        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling downloadFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/File/{type}/{name}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))).replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Fájl letöltése.  Jogkör: Bármely bejelentkezett felhasználó.
     */
    async downloadFile(requestParameters: DownloadFileRequest): Promise<object> {
        const response = await this.downloadFileRaw(requestParameters);
        return await response.value();
    }

    /**
     * Fájl letöltése.  Jogkör: Bármely bejelentkezett felhasználó.
     */
    async downloadFileByIdRaw(requestParameters: DownloadFileByIdRequest): Promise<runtime.ApiResponse<object>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling downloadFileById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/File/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Fájl letöltése.  Jogkör: Bármely bejelentkezett felhasználó.
     */
    async downloadFileById(requestParameters: DownloadFileByIdRequest): Promise<object> {
        const response = await this.downloadFileByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Fájl feltöltése.  Jogkör: Bármely bejelentkezett felhasználó.
     */
    async uploadFileRaw(requestParameters: UploadFileRequest): Promise<runtime.ApiResponse<FileDto>> {
        if (requestParameters.profileId === null || requestParameters.profileId === undefined) {
            throw new runtime.RequiredError('profileId','Required parameter requestParameters.profileId was null or undefined when calling uploadFile.');
        }

        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling uploadFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/api/v1/File/{profileId}/{type}`.replace(`{${"profileId"}}`, encodeURIComponent(String(requestParameters.profileId))).replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FileDtoFromJSON(jsonValue));
    }

    /**
     * Fájl feltöltése.  Jogkör: Bármely bejelentkezett felhasználó.
     */
    async uploadFile(requestParameters: UploadFileRequest): Promise<FileDto> {
        const response = await this.uploadFileRaw(requestParameters);
        return await response.value();
    }

}
