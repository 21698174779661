import { useEffect } from "react"
import { useTranslation } from "react-i18next"

const usePageMetaTitle = (titleKey?: string): void => {
  const { t } = useTranslation()
  const baseTitle = t("general.title")
  useEffect(() => {
    document.title = titleKey ? [t(titleKey), baseTitle].join(" | ") : baseTitle
  }, [t, baseTitle, titleKey])
}
export default usePageMetaTitle
