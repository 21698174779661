import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { api } from "api"
import { FeatureState } from "models/FeatureState"
import { AppThunk, RootState } from "app/store"
import {
  InterviewProfile,
  mapToInterviewProfile,
} from "models/InterviewProfile"
import { FeaturePagination } from "models/FeaturePagination"
import { GetAllRequest } from "api/generated/namecheck"
import { getInitialPaginationState } from "utils/common"

interface InterviewProfileListState {
  featureState: FeatureState
  actionState: FeatureState
  list: InterviewProfile[]
  pagination: FeaturePagination
}

const initialState: InterviewProfileListState = {
  featureState: FeatureState.Initial,
  actionState: FeatureState.Initial,
  list: [],
  pagination: getInitialPaginationState(),
}

const interviewProfileListSlice = createSlice({
  name: "interviewProfileList",
  initialState,
  reducers: {
    reset: () => ({ ...initialState }),
    fetchProfileListStart(state) {
      state.featureState = FeatureState.Loading
    },
    fetchProfileListSuccess(
      state,
      { payload }: PayloadAction<InterviewProfile[]>
    ) {
      state.list = payload
      state.featureState = FeatureState.Success
    },
    fetchProfileListFailure(state) {
      state.featureState = FeatureState.Error
    },
    setPagination(state, { payload }: PayloadAction<FeaturePagination>) {
      state.pagination = payload
      state.featureState = FeatureState.Success
    },
    setActionState(state, { payload }: PayloadAction<FeatureState>) {
      state.actionState = payload
    },
  },
})

export const interviewProfileListSliceReducer =
  interviewProfileListSlice.reducer

/**
 * SELECTORS
 */

const loadingSelector = (state: RootState): boolean =>
  state.interviewProfileList.featureState === FeatureState.Loading

const listSelector = (state: RootState): Array<InterviewProfile> | undefined =>
  state.interviewProfileList.list

export const interviewProfileListSliceSelectors = {
  isLoading: loadingSelector,
  data: listSelector,
}

/**
 * ACTIONS
 */

const {
  fetchProfileListStart,
  fetchProfileListFailure,
  fetchProfileListSuccess,
  reset,
  setPagination,
  setActionState,
} = interviewProfileListSlice.actions

const loadData = (
  params: GetAllRequest = {}
): AppThunk<Promise<any>> => async dispatch => {
  dispatch(fetchProfileListStart())

  return api.namecheck.interviewProfile
    .getAll(params)
    .then(data => {
      const { results, ...pagination } = data

      const mappedData = results?.map(item => mapToInterviewProfile(item)) || []

      dispatch(fetchProfileListSuccess(mappedData))
      dispatch(setPagination(pagination))
    })
    .catch((error: any) => {
      dispatch(fetchProfileListFailure())

      return Promise.reject(error)
    })
}

const finalizeItem = (id: number): AppThunk<Promise<any>> => async dispatch => {
  dispatch(setActionState(FeatureState.Loading))

  return api.namecheck.interviewProfile
    .finalize({ id })
    .then(data => {
      dispatch(setActionState(FeatureState.Success))
    })
    .catch((error: any) => {
      dispatch(setActionState(FeatureState.Error))

      return Promise.reject(error)
    })
}

const deleteItem = (id: number): AppThunk<Promise<any>> => async dispatch => {
  dispatch(setActionState(FeatureState.Loading))

  return api.namecheck.interviewProfile
    ._delete({ id })
    .then(data => {
      dispatch(setActionState(FeatureState.Success))
    })
    .catch((error: any) => {
      dispatch(setActionState(FeatureState.Error))

      return Promise.reject(error)
    })
}

export const interviewProfileListSliceActions = {
  loadData,
  reset,
  finalizeItem,
  deleteItem,
}
