import { Route, Redirect } from "react-router-dom"
import { useSelector } from "react-redux"
import { authSliceSelectors } from "features/auth/authSlice"
import { FullPageLayout } from "components/layout/FullPageLayout"
import usePageMetaTitle from "hooks/usePageMetaTitle"

interface PublicRouteProps {
  component: React.ElementType
  layout?: React.ElementType
  restricted?: boolean
  name?: string
  path?: string
  title?: string
}

export const PublicRoute: React.FC<PublicRouteProps> = ({
  component: Component,
  layout: Layout = FullPageLayout,
  restricted,
  name,
  title,
  ...rest
}) => {
  const user = useSelector(authSliceSelectors.user)

  usePageMetaTitle(title)

  return (
    <Route
      {...rest}
      render={(props: any) => {
        const userCameFrom =
          props.location.state && props.location.state.from
            ? props.location.state.from.pathname +
              props.location.state.from.search +
              props.location.state.from.hash
            : "/"

        const redirectTo = userCameFrom === "/logout" ? "/" : userCameFrom

        // restricted meaning it can only be accessed, if the user is not logged in
        return user && restricted ? (
          <Redirect to={redirectTo} />
        ) : (
          <Layout name={name}>
            <Component {...props} />
          </Layout>
        )
      }}
    />
  )
}
