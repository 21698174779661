/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BlacklistedCompanyDto
 */
export interface BlacklistedCompanyDto {
    /**
     * 
     * @type {number}
     * @memberof BlacklistedCompanyDto
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof BlacklistedCompanyDto
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof BlacklistedCompanyDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BlacklistedCompanyDto
     */
    comment?: string | null;
}

export function BlacklistedCompanyDtoFromJSON(json: any): BlacklistedCompanyDto {
    return BlacklistedCompanyDtoFromJSONTyped(json, false);
}

export function BlacklistedCompanyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BlacklistedCompanyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
    };
}

export function BlacklistedCompanyDtoToJSON(value?: BlacklistedCompanyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'name': value.name,
        'comment': value.comment,
    };
}


