/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RegistrationInviteDto,
    RegistrationInviteDtoFromJSON,
    RegistrationInviteDtoFromJSONTyped,
    RegistrationInviteDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface RegistrationInviteDtoPaginatedResultDto
 */
export interface RegistrationInviteDtoPaginatedResultDto {
    /**
     * 
     * @type {number}
     * @memberof RegistrationInviteDtoPaginatedResultDto
     */
    take?: number;
    /**
     * 
     * @type {number}
     * @memberof RegistrationInviteDtoPaginatedResultDto
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof RegistrationInviteDtoPaginatedResultDto
     */
    total?: number;
    /**
     * 
     * @type {Array<RegistrationInviteDto>}
     * @memberof RegistrationInviteDtoPaginatedResultDto
     */
    results?: Array<RegistrationInviteDto> | null;
}

export function RegistrationInviteDtoPaginatedResultDtoFromJSON(json: any): RegistrationInviteDtoPaginatedResultDto {
    return RegistrationInviteDtoPaginatedResultDtoFromJSONTyped(json, false);
}

export function RegistrationInviteDtoPaginatedResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegistrationInviteDtoPaginatedResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'take': !exists(json, 'take') ? undefined : json['take'],
        'page': !exists(json, 'page') ? undefined : json['page'],
        'total': !exists(json, 'total') ? undefined : json['total'],
        'results': !exists(json, 'results') ? undefined : (json['results'] === null ? null : (json['results'] as Array<any>).map(RegistrationInviteDtoFromJSON)),
    };
}

export function RegistrationInviteDtoPaginatedResultDtoToJSON(value?: RegistrationInviteDtoPaginatedResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'take': value.take,
        'page': value.page,
        'total': value.total,
        'results': value.results === undefined ? undefined : (value.results === null ? null : (value.results as Array<any>).map(RegistrationInviteDtoToJSON)),
    };
}


