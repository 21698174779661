import React from "react"
import { Route, Redirect, RouteComponentProps } from "react-router-dom"
import useAuth from "features/auth/useAuth"
import { PrivateLayout } from "components/layout/PrivateLayout"
import { UserRole } from "api/generated/namecheck"
import usePageMetaTitle from "hooks/usePageMetaTitle"

interface PrivateRouteProps {
  component: React.ElementType
  layout?: React.ElementType
  roles?: UserRole[]
  name?: string
  path?: string
  title?: string
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  layout: Layout = PrivateLayout,
  roles,
  name,
  title,
  ...rest
}) => {
  const { userIsLoggedIn, hasUserRole } = useAuth()

  const renderLoggedInContent = (props: RouteComponentProps) =>
    hasUserRole(roles) ? (
      <Layout name={name}>
        <Component {...props} />
      </Layout>
    ) : (
      <Redirect to={{ pathname: "/" }} />
    )

  const renderNotLoggedInContent = (props: RouteComponentProps) => (
    <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
  )

  usePageMetaTitle(title)

  return (
    <Route
      {...rest}
      render={props =>
        userIsLoggedIn
          ? renderLoggedInContent(props)
          : renderNotLoggedInContent(props)
      }
    />
  )
}
