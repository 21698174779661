/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CheckStatus {
    InvalidEnum = 'InvalidEnum',
    Pending = 'Pending',
    Allowed = 'Allowed',
    Successful = 'Successful',
    Anonymized = 'Anonymized',
    Refused = 'Refused'
}

export function CheckStatusFromJSON(json: any): CheckStatus {
    return CheckStatusFromJSONTyped(json, false);
}

export function CheckStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckStatus {
    return json as CheckStatus;
}

export function CheckStatusToJSON(value?: CheckStatus | null): any {
    return value as any;
}

