import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { api } from "api"
import { FeatureState } from "models/FeatureState"
import { AppThunk, RootState } from "app/store"
import { FeaturePagination } from "models/FeaturePagination"
import {
  BlacklistedCompanyDto,
  CreateBlacklistRequest,
  DeleteBlacklistRequest,
  UpdateBlacklistRequest,
  UpdateBlacklistedCompanyDto,
  BlacklistedCompanyDtoPaginatedResultDto,
  GetAllBlacklistRequest,
  CreateBlacklistedCompanyDto,
} from "api/generated/namecheck"
import { getInitialPaginationState } from "utils/common"

interface BlacklistState {
  featureState: FeatureState
  actionState: FeatureState
  list: BlacklistedCompanyDto[]
  pagination: FeaturePagination
}

const initialState: BlacklistState = {
  featureState: FeatureState.Initial,
  actionState: FeatureState.Initial,
  list: [],
  pagination: getInitialPaginationState(),
}

const blacklistSlice = createSlice({
  name: "blacklist",
  initialState,
  reducers: {
    reset: () => ({ ...initialState }),
    fetchBlacklistDataStarted(state) {
      state.featureState = FeatureState.Loading
    },
    fetchBlacklistDataSucceeded(
      state,
      { payload }: PayloadAction<BlacklistedCompanyDto[]>
    ) {
      state.list = payload
      state.featureState = FeatureState.Success
    },
    fetchBlacklistDataFailed(state) {
      state.featureState = FeatureState.Error
    },
    fetchActionStarted(state) {
      state.actionState = FeatureState.Loading
    },
    fetchActionSucceeded(state) {
      state.actionState = FeatureState.Success
    },
    fetchActionFailed(state) {
      state.actionState = FeatureState.Error
    },
    resetActionState(state) {
      state.actionState = FeatureState.Initial
    },
    setPagination(state, { payload }: PayloadAction<FeaturePagination>) {
      state.pagination = payload
      state.featureState = FeatureState.Success
    },
  },
})

export const blacklistSliceReducer = blacklistSlice.reducer

/**
 * SELECTORS
 */

const loadingSelector = (state: RootState): boolean =>
  state.blacklist.featureState === FeatureState.Loading

export const blacklistSliceSelectors = {
  isLoading: loadingSelector,
}

/**
 * ACTIONS
 */

const {
  fetchBlacklistDataStarted,
  fetchBlacklistDataFailed,
  fetchBlacklistDataSucceeded,
  fetchActionStarted,
  fetchActionFailed,
  fetchActionSucceeded,
  resetActionState,
  setPagination,
} = blacklistSlice.actions

const loadData = (
  params: GetAllBlacklistRequest = {}
): AppThunk => async dispatch => {
  dispatch(fetchBlacklistDataStarted())

  return api.namecheck.blacklistedCompany
    .getAllBlacklist(params)
    .then((data: BlacklistedCompanyDtoPaginatedResultDto) => {
      const { results, ...pagination } = data
      dispatch(fetchBlacklistDataSucceeded(results ?? []))
      dispatch(setPagination(pagination))
    })
    .catch((error: any) => {
      dispatch(fetchBlacklistDataFailed())
      return Promise.reject(error)
    })
}

const createCompany = (
  data: BlacklistedCompanyDto
): AppThunk => async dispatch => {
  dispatch(fetchActionStarted())
  const request: CreateBlacklistRequest = {
    createBlacklistedCompanyDto: data as CreateBlacklistedCompanyDto,
  }
  return api.namecheck.blacklistedCompany
    .createBlacklist(request)
    .then((data: any) => {
      dispatch(fetchActionSucceeded())
    })
    .catch((error: any) => {
      dispatch(fetchActionFailed())
      return Promise.reject(error)
    })
}

const updateCompany = (
  data: BlacklistedCompanyDto
): AppThunk => async dispatch => {
  dispatch(fetchActionStarted())
  const request: UpdateBlacklistRequest = {
    id: (data.id ?? -1).toString(),
    updateBlacklistedCompanyDto: data as UpdateBlacklistedCompanyDto,
  }
  return api.namecheck.blacklistedCompany
    .updateBlacklist(request)
    .then((data: any) => {
      dispatch(fetchActionSucceeded())
    })
    .catch((error: any) => {
      dispatch(fetchActionFailed())
      return Promise.reject(error)
    })
}

const deleteCompany = (id: number): AppThunk => async dispatch => {
  dispatch(fetchActionStarted())
  const request: DeleteBlacklistRequest = {
    id,
  }
  return api.namecheck.blacklistedCompany
    .deleteBlacklist(request)
    .then((data: any) => {
      dispatch(fetchActionSucceeded())
    })
    .catch((error: any) => {
      dispatch(fetchActionFailed())
      return Promise.reject(error)
    })
}

export const blacklistSliceActions = {
  loadData,
  createCompany,
  updateCompany,
  deleteCompany,
  resetActionState,
}
