import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { api } from "api"
import { FeatureState } from "models/FeatureState"
import { AppThunk, RootState } from "app/store"
import { FeaturePagination } from "models/FeaturePagination"
import {
  PositionDto,
  UpdatePositionDto,
  CreatePositionDto,
  CreatePositionRequest,
  GetAllPositionsPaginatedRequest,
  PositionDtoPaginatedResultDto,
  UpdatePositionRequest,
  ActivatePositionRequest,
  InactivatePositionRequest,
  DeletePositionRequest,
  SiteDto,
} from "api/generated/namecheck"
import { getInitialPaginationState } from "utils/common"
import { mapToPositionDto, PositionEdit } from "models/PositionEdit"

interface PositionsState {
  featureState: FeatureState
  actionState: FeatureState
  sitesState: FeatureState
  list: PositionDto[]
  pagination: FeaturePagination
  sites: SiteDto[]
}

const initialState: PositionsState = {
  featureState: FeatureState.Initial,
  actionState: FeatureState.Initial,
  sitesState: FeatureState.Initial,
  list: [],
  pagination: getInitialPaginationState(),
  sites: [],
}

const positionsSlice = createSlice({
  name: "positions",
  initialState,
  reducers: {
    reset: () => ({ ...initialState }),
    fetchPositionsDataStarted(state) {
      state.featureState = FeatureState.Loading
    },
    fetchPositionsDataSucceeded(
      state,
      { payload }: PayloadAction<PositionDto[]>
    ) {
      state.list = payload
      state.featureState = FeatureState.Success
    },
    fetchPositionsDataFailed(state) {
      state.featureState = FeatureState.Error
    },
    fetchActionStarted(state) {
      state.actionState = FeatureState.Loading
    },
    fetchActionSucceeded(state) {
      state.actionState = FeatureState.Success
    },
    fetchActionFailed(state) {
      state.actionState = FeatureState.Error
    },
    fetchSitesStart(state) {
      state.sitesState = FeatureState.Loading
    },
    fetchSitesSuccess(state, { payload }: PayloadAction<SiteDto[]>) {
      state.sites = payload
      state.sitesState = FeatureState.Success
    },
    fetchSitesFailure(state) {
      state.sitesState = FeatureState.Error
    },
    resetActionState(state) {
      state.actionState = FeatureState.Initial
    },
    setPagination(state, { payload }: PayloadAction<FeaturePagination>) {
      state.pagination = payload
      state.featureState = FeatureState.Success
    },
  },
})

export const positionsSliceReducer = positionsSlice.reducer

/**
 * SELECTORS
 */

const loadingSelector = (state: RootState): boolean =>
  state.positions.featureState === FeatureState.Loading

export const positionsSliceSelectors = {
  isLoading: loadingSelector,
}

/**
 * ACTIONS
 */

const {
  fetchPositionsDataStarted,
  fetchPositionsDataFailed,
  fetchPositionsDataSucceeded,
  fetchActionStarted,
  fetchActionFailed,
  fetchActionSucceeded,
  fetchSitesStart,
  fetchSitesSuccess,
  fetchSitesFailure,
  resetActionState,
  setPagination,
} = positionsSlice.actions

const loadData = (
  params: GetAllPositionsPaginatedRequest = {}
): AppThunk => async dispatch => {
  dispatch(fetchPositionsDataStarted())

  return api.namecheck.position
    .getAllPositionsPaginated(params)
    .then((data: PositionDtoPaginatedResultDto) => {
      const { results, ...pagination } = data
      dispatch(fetchPositionsDataSucceeded(results ?? []))
      dispatch(setPagination(pagination))
    })
    .catch((error: any) => {
      dispatch(fetchPositionsDataFailed())
      return Promise.reject(error)
    })
}

const fetchSites = (): AppThunk => async dispatch => {
  dispatch(fetchSitesStart())

  return api.namecheck.position
    .getAllSites()
    .then(data => {
      dispatch(fetchSitesSuccess(data))
    })
    .catch((error: any) => {
      dispatch(fetchSitesFailure())

      return Promise.reject(error)
    })
}

const createPosition = (data: PositionEdit): AppThunk => async dispatch => {
  dispatch(fetchActionStarted())
  const request: CreatePositionRequest = {
    createPositionDto: mapToPositionDto(data) as CreatePositionDto,
  }
  return api.namecheck.position
    .createPosition(request)
    .then((data: any) => {
      dispatch(fetchActionSucceeded())
    })
    .catch((error: any) => {
      dispatch(fetchActionFailed())
      return Promise.reject(error)
    })
}

const updatePosition = (data: PositionEdit): AppThunk => async dispatch => {
  dispatch(fetchActionStarted())
  const request: UpdatePositionRequest = {
    id: (data.id ?? -1).toString(),
    updatePositionDto: mapToPositionDto(data) as UpdatePositionDto,
  }
  return api.namecheck.position
    .updatePosition(request)
    .then((data: any) => {
      dispatch(fetchActionSucceeded())
    })
    .catch((error: any) => {
      dispatch(fetchActionFailed())
      return Promise.reject(error)
    })
}

const activatePosition = (id: number): AppThunk => async dispatch => {
  dispatch(fetchActionStarted())
  const request: ActivatePositionRequest = {
    id,
  }
  return api.namecheck.position
    .activatePosition(request)
    .then((data: any) => {
      dispatch(fetchActionSucceeded())
    })
    .catch((error: any) => {
      dispatch(fetchActionFailed())
      return Promise.reject(error)
    })
}

const deactivatePosition = (id: number): AppThunk => async dispatch => {
  dispatch(fetchActionStarted())
  const request: InactivatePositionRequest = {
    id,
  }
  return api.namecheck.position
    .inactivatePosition(request)
    .then((data: any) => {
      dispatch(fetchActionSucceeded())
    })
    .catch((error: any) => {
      dispatch(fetchActionFailed())
      return Promise.reject(error)
    })
}

const deletePosition = (id: number): AppThunk => async dispatch => {
  dispatch(fetchActionStarted())
  const request: DeletePositionRequest = {
    id,
  }
  return api.namecheck.position
    .deletePosition(request)
    .then((data: any) => {
      dispatch(fetchActionSucceeded())
    })
    .catch((error: any) => {
      dispatch(fetchActionFailed())
      return Promise.reject(error)
    })
}

export const positionsSliceActions = {
  loadData,
  createPosition,
  updatePosition,
  activatePosition,
  deactivatePosition,
  deletePosition,
  resetActionState,
  fetchSites,
}
