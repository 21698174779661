/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SalaryExpectationType {
    InvalidEnum = 'InvalidEnum',
    EmployeeNet = 'EmployeeNet',
    EmployeeGross = 'EmployeeGross',
    ContractorHourly = 'ContractorHourly',
    ContractorWeekly = 'ContractorWeekly',
    ContractorMonthly = 'ContractorMonthly',
    NotSpecified = 'NotSpecified'
}

export function SalaryExpectationTypeFromJSON(json: any): SalaryExpectationType {
    return SalaryExpectationTypeFromJSONTyped(json, false);
}

export function SalaryExpectationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SalaryExpectationType {
    return json as SalaryExpectationType;
}

export function SalaryExpectationTypeToJSON(value?: SalaryExpectationType | null): any {
    return value as any;
}

