/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MatchResult,
    MatchResultFromJSON,
    MatchResultFromJSONTyped,
    MatchResultToJSON,
} from './';

/**
 * 
 * @export
 * @interface NameCheckMatchDto
 */
export interface NameCheckMatchDto {
    /**
     * 
     * @type {number}
     * @memberof NameCheckMatchDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof NameCheckMatchDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NameCheckMatchDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NameCheckMatchDto
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof NameCheckMatchDto
     */
    dateOfBirth?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof NameCheckMatchDto
     */
    additionalInfo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NameCheckMatchDto
     */
    nameCheckGuid?: string;
    /**
     * 
     * @type {number}
     * @memberof NameCheckMatchDto
     */
    level?: number;
    /**
     * 
     * @type {MatchResult}
     * @memberof NameCheckMatchDto
     */
    result?: MatchResult;
}

export function NameCheckMatchDtoFromJSON(json: any): NameCheckMatchDto {
    return NameCheckMatchDtoFromJSONTyped(json, false);
}

export function NameCheckMatchDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): NameCheckMatchDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'emailAddress': !exists(json, 'emailAddress') ? undefined : json['emailAddress'],
        'dateOfBirth': !exists(json, 'dateOfBirth') ? undefined : (json['dateOfBirth'] === null ? null : new Date(json['dateOfBirth'])),
        'additionalInfo': !exists(json, 'additionalInfo') ? undefined : json['additionalInfo'],
        'nameCheckGuid': !exists(json, 'nameCheckGuid') ? undefined : json['nameCheckGuid'],
        'level': !exists(json, 'level') ? undefined : json['level'],
        'result': !exists(json, 'result') ? undefined : MatchResultFromJSON(json['result']),
    };
}

export function NameCheckMatchDtoToJSON(value?: NameCheckMatchDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'lastName': value.lastName,
        'firstName': value.firstName,
        'emailAddress': value.emailAddress,
        'dateOfBirth': value.dateOfBirth === undefined ? undefined : (value.dateOfBirth === null ? null : value.dateOfBirth.toISOString()),
        'additionalInfo': value.additionalInfo,
        'nameCheckGuid': value.nameCheckGuid,
        'level': value.level,
        'result': MatchResultToJSON(value.result),
    };
}


