/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FileType,
    FileTypeFromJSON,
    FileTypeFromJSONTyped,
    FileTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface FileDto
 */
export interface FileDto {
    /**
     * 
     * @type {number}
     * @memberof FileDto
     */
    id?: number;
    /**
     * 
     * @type {FileType}
     * @memberof FileDto
     */
    type?: FileType;
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    url?: string | null;
}

export function FileDtoFromJSON(json: any): FileDto {
    return FileDtoFromJSONTyped(json, false);
}

export function FileDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : FileTypeFromJSON(json['type']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'url': !exists(json, 'url') ? undefined : json['url'],
    };
}

export function FileDtoToJSON(value?: FileDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': FileTypeToJSON(value.type),
        'name': value.name,
        'url': value.url,
    };
}


