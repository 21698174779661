import {
  Action,
  configureStore,
  getDefaultMiddleware,
  ThunkAction,
} from "@reduxjs/toolkit"
import { accountSliceReducer } from "features/accounts/list/AccountSlice"
import { authSliceReducer } from "features/auth/authSlice"
import { blacklistSliceReducer } from "features/blacklist/list/BlacklistSlice"
import { interviewProfileListSliceReducer } from "features/interviewProfile/list/InterviewProfileListSlice"
import { interviewProfileSingleSliceReducer } from "features/interviewProfile/single/InterviewProfileSingleSlice"
import { nameCheckListSliceReducer } from "features/nameCheck/list/NameCheckListSlice"
import { nameCheckSingleSliceReducer } from "features/nameCheck/single/NameCheckSingleSlice"
import { partnersSliceReducer } from "features/partners/PartnersSlice"
import { positionsSliceReducer } from "features/positions/PositionsSlice"

const store = configureStore({
  reducer: {
    auth: authSliceReducer,
    account: accountSliceReducer,
    interviewProfileList: interviewProfileListSliceReducer,
    interviewProfileSingle: interviewProfileSingleSliceReducer,
    positions: positionsSliceReducer,
    nameCheckList: nameCheckListSliceReducer,
    nameCheckSingle: nameCheckSingleSliceReducer,
    blacklist: blacklistSliceReducer,
    partners: partnersSliceReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
})

export default store

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
