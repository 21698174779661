/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreatePositionDto,
    CreatePositionDtoFromJSON,
    CreatePositionDtoToJSON,
    PositionDropdownDto,
    PositionDropdownDtoFromJSON,
    PositionDropdownDtoToJSON,
    PositionDto,
    PositionDtoFromJSON,
    PositionDtoToJSON,
    PositionDtoPaginatedResultDto,
    PositionDtoPaginatedResultDtoFromJSON,
    PositionDtoPaginatedResultDtoToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SiteDto,
    SiteDtoFromJSON,
    SiteDtoToJSON,
    UpdatePositionDto,
    UpdatePositionDtoFromJSON,
    UpdatePositionDtoToJSON,
} from '../models';

export interface ActivatePositionRequest {
    id: number;
}

export interface CreatePositionRequest {
    createPositionDto?: CreatePositionDto;
}

export interface DeletePositionRequest {
    id: number;
}

export interface GetAllPositionsForComboRequest {
    filterText?: string;
    siteCode?: string;
}

export interface GetAllPositionsPaginatedRequest {
    take?: number;
    page?: number;
}

export interface GetPositionRequest {
    id: number;
}

export interface InactivatePositionRequest {
    id: number;
}

export interface UpdatePositionRequest {
    id: string;
    updatePositionDto?: UpdatePositionDto;
}

/**
 * 
 */
export class PositionApi extends runtime.BaseAPI {

    /**
     */
    async activatePositionRaw(requestParameters: ActivatePositionRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling activatePosition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/Position/{id}/activate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async activatePosition(requestParameters: ActivatePositionRequest): Promise<void> {
        await this.activatePositionRaw(requestParameters);
    }

    /**
     */
    async createPositionRaw(requestParameters: CreatePositionRequest): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/Position`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePositionDtoToJSON(requestParameters.createPositionDto),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async createPosition(requestParameters: CreatePositionRequest): Promise<number> {
        const response = await this.createPositionRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deletePositionRaw(requestParameters: DeletePositionRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deletePosition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/Position/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deletePosition(requestParameters: DeletePositionRequest): Promise<void> {
        await this.deletePositionRaw(requestParameters);
    }

    /**
     */
    async getAllPositionsRaw(): Promise<runtime.ApiResponse<Array<PositionDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/Position`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PositionDtoFromJSON));
    }

    /**
     */
    async getAllPositions(): Promise<Array<PositionDto>> {
        const response = await this.getAllPositionsRaw();
        return await response.value();
    }

    /**
     */
    async getAllPositionsForComboRaw(requestParameters: GetAllPositionsForComboRequest): Promise<runtime.ApiResponse<Array<PositionDropdownDto>>> {
        const queryParameters: any = {};

        if (requestParameters.filterText !== undefined) {
            queryParameters['filterText'] = requestParameters.filterText;
        }

        if (requestParameters.siteCode !== undefined) {
            queryParameters['siteCode'] = requestParameters.siteCode;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/Position/positions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PositionDropdownDtoFromJSON));
    }

    /**
     */
    async getAllPositionsForCombo(requestParameters: GetAllPositionsForComboRequest): Promise<Array<PositionDropdownDto>> {
        const response = await this.getAllPositionsForComboRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getAllPositionsPaginatedRaw(requestParameters: GetAllPositionsPaginatedRequest): Promise<runtime.ApiResponse<PositionDtoPaginatedResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.take !== undefined) {
            queryParameters['Take'] = requestParameters.take;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/Position/all`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PositionDtoPaginatedResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async getAllPositionsPaginated(requestParameters: GetAllPositionsPaginatedRequest): Promise<PositionDtoPaginatedResultDto> {
        const response = await this.getAllPositionsPaginatedRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getAllSitesRaw(): Promise<runtime.ApiResponse<Array<SiteDto>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/Position/sites`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SiteDtoFromJSON));
    }

    /**
     */
    async getAllSites(): Promise<Array<SiteDto>> {
        const response = await this.getAllSitesRaw();
        return await response.value();
    }

    /**
     */
    async getPositionRaw(requestParameters: GetPositionRequest): Promise<runtime.ApiResponse<PositionDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPosition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/Position/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PositionDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPosition(requestParameters: GetPositionRequest): Promise<PositionDto> {
        const response = await this.getPositionRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async inactivatePositionRaw(requestParameters: InactivatePositionRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling inactivatePosition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/Position/{id}/inactivate`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async inactivatePosition(requestParameters: InactivatePositionRequest): Promise<void> {
        await this.inactivatePositionRaw(requestParameters);
    }

    /**
     */
    async updatePositionRaw(requestParameters: UpdatePositionRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePosition.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/Position/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePositionDtoToJSON(requestParameters.updatePositionDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updatePosition(requestParameters: UpdatePositionRequest): Promise<void> {
        await this.updatePositionRaw(requestParameters);
    }

}
