/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MatchResult {
    InvalidEnum = 'InvalidEnum',
    NoMatch = 'NoMatch',
    PartialMatch = 'PartialMatch',
    FullMatch = 'FullMatch'
}

export function MatchResultFromJSON(json: any): MatchResult {
    return MatchResultFromJSONTyped(json, false);
}

export function MatchResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): MatchResult {
    return json as MatchResult;
}

export function MatchResultToJSON(value?: MatchResult | null): any {
    return value as any;
}

