/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CheckSource,
    CheckSourceFromJSON,
    CheckSourceFromJSONTyped,
    CheckSourceToJSON,
    FileDto,
    FileDtoFromJSON,
    FileDtoFromJSONTyped,
    FileDtoToJSON,
    InterviewProfileStatus,
    InterviewProfileStatusFromJSON,
    InterviewProfileStatusFromJSONTyped,
    InterviewProfileStatusToJSON,
    NameCheckDataDto,
    NameCheckDataDtoFromJSON,
    NameCheckDataDtoFromJSONTyped,
    NameCheckDataDtoToJSON,
    PositionDropdownDto,
    PositionDropdownDtoFromJSON,
    PositionDropdownDtoFromJSONTyped,
    PositionDropdownDtoToJSON,
    SalaryExpectationType,
    SalaryExpectationTypeFromJSON,
    SalaryExpectationTypeFromJSONTyped,
    SalaryExpectationTypeToJSON,
    SiteDto,
    SiteDtoFromJSON,
    SiteDtoFromJSONTyped,
    SiteDtoToJSON,
    UserProfileDto,
    UserProfileDtoFromJSON,
    UserProfileDtoFromJSONTyped,
    UserProfileDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface InterviewProfileDto
 */
export interface InterviewProfileDto {
    /**
     * 
     * @type {number}
     * @memberof InterviewProfileDto
     */
    id?: number;
    /**
     * 
     * @type {NameCheckDataDto}
     * @memberof InterviewProfileDto
     */
    nameCheckData?: NameCheckDataDto;
    /**
     * 
     * @type {InterviewProfileStatus}
     * @memberof InterviewProfileDto
     */
    status?: InterviewProfileStatus;
    /**
     * 
     * @type {PositionDropdownDto}
     * @memberof InterviewProfileDto
     */
    position?: PositionDropdownDto;
    /**
     * 
     * @type {number}
     * @memberof InterviewProfileDto
     */
    positionId?: number | null;
    /**
     * 
     * @type {SalaryExpectationType}
     * @memberof InterviewProfileDto
     */
    salaryExpectationType?: SalaryExpectationType;
    /**
     * 
     * @type {string}
     * @memberof InterviewProfileDto
     */
    salaryExpectationValue?: string | null;
    /**
     * 
     * @type {FileDto}
     * @memberof InterviewProfileDto
     */
    cv?: FileDto;
    /**
     * 
     * @type {FileDto}
     * @memberof InterviewProfileDto
     */
    interviewSheet?: FileDto;
    /**
     * 
     * @type {Date}
     * @memberof InterviewProfileDto
     */
    finalizedAt?: Date | null;
    /**
     * 
     * @type {CheckSource}
     * @memberof InterviewProfileDto
     */
    source?: CheckSource;
    /**
     * 
     * @type {Date}
     * @memberof InterviewProfileDto
     */
    expiresAt?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof InterviewProfileDto
     */
    readonly isExpired?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InterviewProfileDto
     */
    siteCode?: string | null;
    /**
     * 
     * @type {SiteDto}
     * @memberof InterviewProfileDto
     */
    site?: SiteDto;
    /**
     * 
     * @type {Date}
     * @memberof InterviewProfileDto
     */
    createdAt?: Date;
    /**
     * 
     * @type {UserProfileDto}
     * @memberof InterviewProfileDto
     */
    createdBy?: UserProfileDto;
    /**
     * 
     * @type {string}
     * @memberof InterviewProfileDto
     */
    createdById?: string | null;
}

export function InterviewProfileDtoFromJSON(json: any): InterviewProfileDto {
    return InterviewProfileDtoFromJSONTyped(json, false);
}

export function InterviewProfileDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewProfileDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'nameCheckData': !exists(json, 'nameCheckData') ? undefined : NameCheckDataDtoFromJSON(json['nameCheckData']),
        'status': !exists(json, 'status') ? undefined : InterviewProfileStatusFromJSON(json['status']),
        'position': !exists(json, 'position') ? undefined : PositionDropdownDtoFromJSON(json['position']),
        'positionId': !exists(json, 'positionId') ? undefined : json['positionId'],
        'salaryExpectationType': !exists(json, 'salaryExpectationType') ? undefined : SalaryExpectationTypeFromJSON(json['salaryExpectationType']),
        'salaryExpectationValue': !exists(json, 'salaryExpectationValue') ? undefined : json['salaryExpectationValue'],
        'cv': !exists(json, 'cv') ? undefined : FileDtoFromJSON(json['cv']),
        'interviewSheet': !exists(json, 'interviewSheet') ? undefined : FileDtoFromJSON(json['interviewSheet']),
        'finalizedAt': !exists(json, 'finalizedAt') ? undefined : (json['finalizedAt'] === null ? null : new Date(json['finalizedAt'])),
        'source': !exists(json, 'source') ? undefined : CheckSourceFromJSON(json['source']),
        'expiresAt': !exists(json, 'expiresAt') ? undefined : (new Date(json['expiresAt'])),
        'isExpired': !exists(json, 'isExpired') ? undefined : json['isExpired'],
        'siteCode': !exists(json, 'siteCode') ? undefined : json['siteCode'],
        'site': !exists(json, 'site') ? undefined : SiteDtoFromJSON(json['site']),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : UserProfileDtoFromJSON(json['createdBy']),
        'createdById': !exists(json, 'createdBy_Id') ? undefined : json['createdBy_Id'],
    };
}

export function InterviewProfileDtoToJSON(value?: InterviewProfileDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'nameCheckData': NameCheckDataDtoToJSON(value.nameCheckData),
        'status': InterviewProfileStatusToJSON(value.status),
        'position': PositionDropdownDtoToJSON(value.position),
        'positionId': value.positionId,
        'salaryExpectationType': SalaryExpectationTypeToJSON(value.salaryExpectationType),
        'salaryExpectationValue': value.salaryExpectationValue,
        'cv': FileDtoToJSON(value.cv),
        'interviewSheet': FileDtoToJSON(value.interviewSheet),
        'finalizedAt': value.finalizedAt === undefined ? undefined : (value.finalizedAt === null ? null : value.finalizedAt.toISOString()),
        'source': CheckSourceToJSON(value.source),
        'expiresAt': value.expiresAt === undefined ? undefined : (value.expiresAt.toISOString()),
        'siteCode': value.siteCode,
        'site': SiteDtoToJSON(value.site),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'createdBy': UserProfileDtoToJSON(value.createdBy),
        'createdBy_Id': value.createdById,
    };
}


