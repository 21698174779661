import { useDispatch, useSelector } from "react-redux"
import {
  Row,
  Col,
  Form,
  Input,
  message,
  Space,
  Button,
  Typography,
  Divider,
} from "antd"
import { useTranslation } from "react-i18next"
import { Container } from "components/layout/Container"
import {
  formItemRequired,
  formItemRequiredNoWhitespace,
} from "utils/formItemRules"
import { useHistory, useParams } from "react-router"
import { RegistrationInviteDto } from "api/generated/namecheck"
import { CheckSquareFilled } from "@ant-design/icons"
import { accountSliceActions } from "./list/AccountSlice"
import { RootState } from "app/store"
import { FeatureState } from "models/FeatureState"

const AcceptInvite: React.FC = () => {
  const params = useParams<any>() as RegistrationInviteDto
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const history = useHistory()
  const { inviteState } = useSelector((state: RootState) => state.account)

  const onFinish = (values: RegistrationInviteDto): any => {
    // eslint-disable-next-line @typescript-eslint/no-extra-semi
    ;(async () => {
      try {
        await dispatch(accountSliceActions.acceptInvite(values))
        message.success(t("pages.Accounts.acceptedInvite.response.OK"), 5)
        history.push(`/`)
      } catch (_) {
        message.error(t("pages.Accounts.acceptedInvite.response.Error"), 5)
      }
    })()
  }

  return (
    <Container>
      <Row justify="center">
        <Col xs={24} lg={8}>
          <Typography.Title>
            {t("pages.Accounts.acceptedInvite.title")}
          </Typography.Title>
          <Divider />
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <Row gutter={24}>
              <Col span={24} key="emailAddress">
                <Form.Item
                  label={t("labels.Accounts.email")}
                  name="emailAddress"
                  rules={formItemRequiredNoWhitespace}
                  initialValue={decodeURIComponent(params?.emailAddress || "")}
                >
                  <Input
                    type="email"
                    value={decodeURIComponent(params?.emailAddress || "")}
                  />
                </Form.Item>
              </Col>
              <Col span={24} key="invitationCode">
                <Form.Item
                  label={t("labels.Accounts.invitationCode")}
                  name="invitationCode"
                  initialValue={params?.invitationCode}
                  rules={formItemRequired}
                >
                  <Input value={params?.invitationCode || ""} />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="end">
              <Col>
                <Space size="middle">
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    loading={inviteState === FeatureState.Loading}
                  >
                    <CheckSquareFilled />
                    {t("pages.Accounts.acceptedInvite.accept")}
                  </Button>
                </Space>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default AcceptInvite
