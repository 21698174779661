/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AccountOrder,
    AccountOrderFromJSON,
    AccountOrderToJSON,
    ExternalAuthDto,
    ExternalAuthDtoFromJSON,
    ExternalAuthDtoToJSON,
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    RegistrationInviteDto,
    RegistrationInviteDtoFromJSON,
    RegistrationInviteDtoToJSON,
    RegistrationInviteDtoPaginatedResultDto,
    RegistrationInviteDtoPaginatedResultDtoFromJSON,
    RegistrationInviteDtoPaginatedResultDtoToJSON,
    UserActivationDto,
    UserActivationDtoFromJSON,
    UserActivationDtoToJSON,
    UserProfileDto,
    UserProfileDtoFromJSON,
    UserProfileDtoToJSON,
    UserProfileDtoPaginatedResultDto,
    UserProfileDtoPaginatedResultDtoFromJSON,
    UserProfileDtoPaginatedResultDtoToJSON,
    UserRoleUpdateDto,
    UserRoleUpdateDtoFromJSON,
    UserRoleUpdateDtoToJSON,
    UserTokenDto,
    UserTokenDtoFromJSON,
    UserTokenDtoToJSON,
} from '../models';

export interface AcceptInviteRequest {
    invitationCode?: string;
    emailAddress?: string;
}

export interface CreateInviteRequest {
    partnerId?: number;
    emailAddress?: string;
    urlFormat?: string;
}

export interface GetAllInvitesRequest {
    email?: string;
    partnerName?: string;
    orderBy?: AccountOrder;
    orderDirection?: ListSortDirection;
    take?: number;
    page?: number;
}

export interface GetAllProfilesRequest {
    email?: string;
    partnerName?: string;
    orderBy?: AccountOrder;
    orderDirection?: ListSortDirection;
    take?: number;
    page?: number;
}

export interface GoogleAuthenticateRequest {
    externalAuthDto?: ExternalAuthDto;
}

export interface SetDeactivatedFlagForAccountRequest {
    userActivationDto?: UserActivationDto;
}

export interface SetRoleRequest {
    userRoleUpdateDto?: UserRoleUpdateDto;
}

/**
 * 
 */
export class AccountApi extends runtime.BaseAPI {

    /**
     * Korábban generált regisztrációs meghívó elfogadása.
     */
    async acceptInviteRaw(requestParameters: AcceptInviteRequest): Promise<runtime.ApiResponse<RegistrationInviteDto>> {
        const queryParameters: any = {};

        if (requestParameters.invitationCode !== undefined) {
            queryParameters['invitationCode'] = requestParameters.invitationCode;
        }

        if (requestParameters.emailAddress !== undefined) {
            queryParameters['emailAddress'] = requestParameters.emailAddress;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/Account/AcceptInvite`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RegistrationInviteDtoFromJSON(jsonValue));
    }

    /**
     * Korábban generált regisztrációs meghívó elfogadása.
     */
    async acceptInvite(requestParameters: AcceptInviteRequest): Promise<RegistrationInviteDto> {
        const response = await this.acceptInviteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Regisztrációs meghívó generálása.   Jogkör: Admin.
     */
    async createInviteRaw(requestParameters: CreateInviteRequest): Promise<runtime.ApiResponse<RegistrationInviteDto>> {
        const queryParameters: any = {};

        if (requestParameters.partnerId !== undefined) {
            queryParameters['partnerId'] = requestParameters.partnerId;
        }

        if (requestParameters.emailAddress !== undefined) {
            queryParameters['emailAddress'] = requestParameters.emailAddress;
        }

        if (requestParameters.urlFormat !== undefined) {
            queryParameters['urlFormat'] = requestParameters.urlFormat;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/Account/CreateInvite`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RegistrationInviteDtoFromJSON(jsonValue));
    }

    /**
     * Regisztrációs meghívó generálása.   Jogkör: Admin.
     */
    async createInvite(requestParameters: CreateInviteRequest): Promise<RegistrationInviteDto> {
        const response = await this.createInviteRaw(requestParameters);
        return await response.value();
    }

    /**
     * Kiküldött meghívók lekérése.   Jogkör: Admin.
     */
    async getAllInvitesRaw(requestParameters: GetAllInvitesRequest): Promise<runtime.ApiResponse<RegistrationInviteDtoPaginatedResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.email !== undefined) {
            queryParameters['Email'] = requestParameters.email;
        }

        if (requestParameters.partnerName !== undefined) {
            queryParameters['PartnerName'] = requestParameters.partnerName;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['OrderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['OrderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.take !== undefined) {
            queryParameters['Take'] = requestParameters.take;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/Account/Invites`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RegistrationInviteDtoPaginatedResultDtoFromJSON(jsonValue));
    }

    /**
     * Kiküldött meghívók lekérése.   Jogkör: Admin.
     */
    async getAllInvites(requestParameters: GetAllInvitesRequest): Promise<RegistrationInviteDtoPaginatedResultDto> {
        const response = await this.getAllInvitesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Felhasználói profilok lekérése.   Jogkör: Admin.
     */
    async getAllProfilesRaw(requestParameters: GetAllProfilesRequest): Promise<runtime.ApiResponse<UserProfileDtoPaginatedResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.email !== undefined) {
            queryParameters['Email'] = requestParameters.email;
        }

        if (requestParameters.partnerName !== undefined) {
            queryParameters['PartnerName'] = requestParameters.partnerName;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['OrderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['OrderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.take !== undefined) {
            queryParameters['Take'] = requestParameters.take;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/Account/Profiles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserProfileDtoPaginatedResultDtoFromJSON(jsonValue));
    }

    /**
     * Felhasználói profilok lekérése.   Jogkör: Admin.
     */
    async getAllProfiles(requestParameters: GetAllProfilesRequest): Promise<UserProfileDtoPaginatedResultDto> {
        const response = await this.getAllProfilesRaw(requestParameters);
        return await response.value();
    }

    /**
     * Bejelentkezés callback-je a Google authentikáció során, előállítja a JWT tokent a FE számára.
     */
    async googleAuthenticateRaw(requestParameters: GoogleAuthenticateRequest): Promise<runtime.ApiResponse<UserTokenDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/Account/GoogleAuthenticate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExternalAuthDtoToJSON(requestParameters.externalAuthDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserTokenDtoFromJSON(jsonValue));
    }

    /**
     * Bejelentkezés callback-je a Google authentikáció során, előállítja a JWT tokent a FE számára.
     */
    async googleAuthenticate(requestParameters: GoogleAuthenticateRequest): Promise<UserTokenDto> {
        const response = await this.googleAuthenticateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Saját profiladatok lekérése.   Jogkör: Bármely bejelentkezett felhasználó.
     */
    async meRaw(): Promise<runtime.ApiResponse<UserProfileDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/Account/Me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserProfileDtoFromJSON(jsonValue));
    }

    /**
     * Saját profiladatok lekérése.   Jogkör: Bármely bejelentkezett felhasználó.
     */
    async me(): Promise<UserProfileDto> {
        const response = await this.meRaw();
        return await response.value();
    }

    /**
     * Egy adott felhasználó meghívójának/profiljának korlátozása.   Jogkör: Admin.
     */
    async setDeactivatedFlagForAccountRaw(requestParameters: SetDeactivatedFlagForAccountRequest): Promise<runtime.ApiResponse<RegistrationInviteDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/Account/SetActivationState`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserActivationDtoToJSON(requestParameters.userActivationDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => RegistrationInviteDtoFromJSON(jsonValue));
    }

    /**
     * Egy adott felhasználó meghívójának/profiljának korlátozása.   Jogkör: Admin.
     */
    async setDeactivatedFlagForAccount(requestParameters: SetDeactivatedFlagForAccountRequest): Promise<RegistrationInviteDto> {
        const response = await this.setDeactivatedFlagForAccountRaw(requestParameters);
        return await response.value();
    }

    /**
     * Egy adott felhasználó jogkörének megváltoztatása.   Jogkör: Admin.
     */
    async setRoleRaw(requestParameters: SetRoleRequest): Promise<runtime.ApiResponse<UserProfileDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/Account/AddRole`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserRoleUpdateDtoToJSON(requestParameters.userRoleUpdateDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserProfileDtoFromJSON(jsonValue));
    }

    /**
     * Egy adott felhasználó jogkörének megváltoztatása.   Jogkör: Admin.
     */
    async setRole(requestParameters: SetRoleRequest): Promise<UserProfileDto> {
        const response = await this.setRoleRaw(requestParameters);
        return await response.value();
    }

}
