/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum NameCheckResultOrder {
    InvalidEnum = 'InvalidEnum',
    CheckTime = 'CheckTime',
    EmailAddress = 'EmailAddress',
    PartnerName = 'PartnerName',
    CandidateName = 'CandidateName'
}

export function NameCheckResultOrderFromJSON(json: any): NameCheckResultOrder {
    return NameCheckResultOrderFromJSONTyped(json, false);
}

export function NameCheckResultOrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): NameCheckResultOrder {
    return json as NameCheckResultOrder;
}

export function NameCheckResultOrderToJSON(value?: NameCheckResultOrder | null): any {
    return value as any;
}

