import { authSliceActions } from "features/auth/authSlice"
import { interviewProfileListSliceActions } from "features/interviewProfile/list/InterviewProfileListSlice"
import { profileSingleSliceActions } from "features/interviewProfile/single/InterviewProfileSingleSlice"
import { partnersSliceActions } from "features/partners/PartnersSlice"
import { batch } from "react-redux"
import { AppThunk } from "./store"

export const resetStore = (): AppThunk => async dispatch => {
  batch(() => {
    dispatch(authSliceActions.reset())
    dispatch(interviewProfileListSliceActions.reset())
    dispatch(profileSingleSliceActions.resetData())
    dispatch(partnersSliceActions.reset())
  })
}
