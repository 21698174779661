/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PartnerDto,
    PartnerDtoFromJSON,
    PartnerDtoFromJSONTyped,
    PartnerDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface PartnerDtoPaginatedResultDto
 */
export interface PartnerDtoPaginatedResultDto {
    /**
     * 
     * @type {number}
     * @memberof PartnerDtoPaginatedResultDto
     */
    take?: number;
    /**
     * 
     * @type {number}
     * @memberof PartnerDtoPaginatedResultDto
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof PartnerDtoPaginatedResultDto
     */
    total?: number;
    /**
     * 
     * @type {Array<PartnerDto>}
     * @memberof PartnerDtoPaginatedResultDto
     */
    results?: Array<PartnerDto> | null;
}

export function PartnerDtoPaginatedResultDtoFromJSON(json: any): PartnerDtoPaginatedResultDto {
    return PartnerDtoPaginatedResultDtoFromJSONTyped(json, false);
}

export function PartnerDtoPaginatedResultDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PartnerDtoPaginatedResultDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'take': !exists(json, 'take') ? undefined : json['take'],
        'page': !exists(json, 'page') ? undefined : json['page'],
        'total': !exists(json, 'total') ? undefined : json['total'],
        'results': !exists(json, 'results') ? undefined : (json['results'] === null ? null : (json['results'] as Array<any>).map(PartnerDtoFromJSON)),
    };
}

export function PartnerDtoPaginatedResultDtoToJSON(value?: PartnerDtoPaginatedResultDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'take': value.take,
        'page': value.page,
        'total': value.total,
        'results': value.results === undefined ? undefined : (value.results === null ? null : (value.results as Array<any>).map(PartnerDtoToJSON)),
    };
}


