import { RegistrationInviteDto, UserRole } from "api/generated/namecheck"

export const getTagColor = (status?: UserRole): string => {
  switch (status) {
    case UserRole.Admin:
      return "red"
    default:
      return "geekblue"
  }
}

export enum RegistrationStatus {
  Completed = "Completed",
  Accepted = "Accepted",
  EmailSent = "EmailSent",
  EmailSendingFailed = "EmailSendingFailed",
}

export const getRegistrationStatus = (
  record?: RegistrationInviteDto
): RegistrationStatus =>
  record?.registeredAccount
    ? RegistrationStatus.Completed
    : record?.acceptedAt
    ? RegistrationStatus.Accepted
    : record?.emailSuccessfullySent
    ? RegistrationStatus.EmailSent
    : RegistrationStatus.EmailSendingFailed

export const getLocationBasedLink = (
  page = registrationPathBase,
  params = ["emailAddress", "invitationCode"],
  prefix = "{",
  postfix = "}"
): any => {
  return (
    window.location.origin +
    "/" +
    getRegistrationPath(page, params, prefix, postfix)
  )
}

export const getRegistrationLink = (
  emailAddress: string,
  invitationCode: string
): any => {
  return getLocationBasedLink(
    registrationPathBase,
    [emailAddress, invitationCode],
    "",
    ""
  )
}

export const getRegistrationPath = (
  page = registrationPathBase,
  params = ["emailAddress", "invitationCode"],
  prefix = ":",
  postfix = "?"
): any => {
  const baseUrl = page

  const path = params?.map(p => {
    return p ? "/" + (prefix ?? "") + p + (postfix ?? "") : ""
  })

  return baseUrl + path?.join("")
}

export const registrationPathBase = "accept-invite"
