import { GoogleLoginResponse } from "react-google-login"

const storageKeys = {
  token: "namecheckToken",
}

export const setTokenToStorage = (token: string): void =>
  sessionStorage.setItem(storageKeys.token, token)

export const getTokenFromStorage = (): string | null =>
  sessionStorage.getItem(storageKeys.token)

export const clearStorage = (): void =>
  Object.values(storageKeys).forEach(key => {
    sessionStorage.removeItem(key)
  })

export const refreshGoogleTokenSetupHandler = (
  res: GoogleLoginResponse,
  onRefreshSuccess: (tokenId: string) => any,
  onRefreshFail: () => any
): void => {
  // Timing to renew access token
  let refreshTiming = (res.tokenObj.expires_in || 3600 - 5 * 60) * 1000

  const refreshToken = async () => {
    try {
      const newAuthRes = await res.reloadAuthResponse()

      refreshTiming = (newAuthRes.expires_in || 3600 - 5 * 60) * 1000

      onRefreshSuccess?.(newAuthRes.id_token)

      // Setup the other timer after the first one
      setTimeout(refreshToken, refreshTiming)
    } catch (error) {
      onRefreshFail?.()
    }
  }

  // Setup first refresh timer
  setTimeout(refreshToken, refreshTiming)
}
