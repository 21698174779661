/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserRole,
    UserRoleFromJSON,
    UserRoleFromJSONTyped,
    UserRoleToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserRoleUpdateDto
 */
export interface UserRoleUpdateDto {
    /**
     * 
     * @type {string}
     * @memberof UserRoleUpdateDto
     */
    userId?: string | null;
    /**
     * 
     * @type {UserRole}
     * @memberof UserRoleUpdateDto
     */
    role?: UserRole;
}

export function UserRoleUpdateDtoFromJSON(json: any): UserRoleUpdateDto {
    return UserRoleUpdateDtoFromJSONTyped(json, false);
}

export function UserRoleUpdateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserRoleUpdateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'role': !exists(json, 'role') ? undefined : UserRoleFromJSON(json['role']),
    };
}

export function UserRoleUpdateDtoToJSON(value?: UserRoleUpdateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'role': UserRoleToJSON(value.role),
    };
}


