import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import store from "./app/store"
import { Provider } from "react-redux"
import { ConfigProvider } from "antd"
import huHU from "antd/es/locale/hu_HU"
import { BrowserRouter as Router } from "react-router-dom"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

import "assets/styles/style.scss"
import "./app/i18n"

ReactDOM.render(
  <GoogleReCaptchaProvider
    reCaptchaKey={process.env.REACT_APP_GOOGLE_RECAPTCHA3_KEY as string}
  >
    <React.StrictMode>
      <Provider store={store}>
        <Router>
          <ConfigProvider locale={huHU}>
            <App />
          </ConfigProvider>
        </Router>
      </Provider>
    </React.StrictMode>
  </GoogleReCaptchaProvider>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
