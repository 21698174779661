import i18n from "app/i18n"
import { Rule } from "rc-field-form/lib/interface"

const requiredMessage = i18n.t("formRules.required")
const emailMessage = i18n.t("formRules.email")
const phoneMessage = i18n.t("formRules.phone")

export const formItemRequiredNoWhitespace: Rule[] = [
  { required: true, message: requiredMessage },
  { whitespace: true, message: requiredMessage },
]

export const formItemRequired: Rule[] = [
  { required: true, message: requiredMessage },
]

// https://emailregex.com/
export const formItemEmail: Rule[] = [
  {
    // eslint-disable-next-line no-useless-escape
    pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    message: emailMessage,
  },
]

// https://www.regextester.com/98279
export const formItemPhone: Rule[] = [
  {
    // eslint-disable-next-line no-useless-escape
    pattern: /((?:\+?3|0)6)(?:-|\()?(\d{1,2})(?:-|\))?(\d{3})-?(\d{3,4})/,
    message: phoneMessage,
  },
]
