/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NameCheckRequestDto
 */
export interface NameCheckRequestDto {
    /**
     * 
     * @type {string}
     * @memberof NameCheckRequestDto
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof NameCheckRequestDto
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof NameCheckRequestDto
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof NameCheckRequestDto
     */
    dateOfBirth?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof NameCheckRequestDto
     */
    recaptchaResponse?: string | null;
}

export function NameCheckRequestDtoFromJSON(json: any): NameCheckRequestDto {
    return NameCheckRequestDtoFromJSONTyped(json, false);
}

export function NameCheckRequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): NameCheckRequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'emailAddress': !exists(json, 'emailAddress') ? undefined : json['emailAddress'],
        'dateOfBirth': !exists(json, 'dateOfBirth') ? undefined : (json['dateOfBirth'] === null ? null : new Date(json['dateOfBirth'])),
        'recaptchaResponse': !exists(json, 'recaptchaResponse') ? undefined : json['recaptchaResponse'],
    };
}

export function NameCheckRequestDtoToJSON(value?: NameCheckRequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'emailAddress': value.emailAddress,
        'dateOfBirth': value.dateOfBirth === undefined ? undefined : (value.dateOfBirth === null ? null : value.dateOfBirth.toISOString()),
        'recaptchaResponse': value.recaptchaResponse,
    };
}


