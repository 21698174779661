/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum InviteStatus {
    InvalidEnum = 'InvalidEnum',
    Pending = 'Pending',
    Completed = 'Completed',
    Expired = 'Expired'
}

export function InviteStatusFromJSON(json: any): InviteStatus {
    return InviteStatusFromJSONTyped(json, false);
}

export function InviteStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): InviteStatus {
    return json as InviteStatus;
}

export function InviteStatusToJSON(value?: InviteStatus | null): any {
    return value as any;
}

