/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateBlacklistedCompanyDto
 */
export interface UpdateBlacklistedCompanyDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateBlacklistedCompanyDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateBlacklistedCompanyDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateBlacklistedCompanyDto
     */
    comment?: string | null;
}

export function UpdateBlacklistedCompanyDtoFromJSON(json: any): UpdateBlacklistedCompanyDto {
    return UpdateBlacklistedCompanyDtoFromJSONTyped(json, false);
}

export function UpdateBlacklistedCompanyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateBlacklistedCompanyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
    };
}

export function UpdateBlacklistedCompanyDtoToJSON(value?: UpdateBlacklistedCompanyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'comment': value.comment,
    };
}


