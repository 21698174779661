import { Result, Button } from "antd"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { ErrorLayout } from "./ErrorLayout"

export const Error500: React.FC = () => {
  const { t } = useTranslation()
  return (
    <ErrorLayout>
      <Result
        status="500"
        title={t("pages.500.title")}
        subTitle={t("pages.500.subTitle")}
        extra={
          <Button size="large" type="primary">
            <Link to="/">{t("pages.500.link")}</Link>
          </Button>
        }
      />
    </ErrorLayout>
  )
}
