import { Layout } from "antd"
import styles from "./FullPageLayout.module.scss"
import classNames from "classnames"

const { Content } = Layout

interface FullPageLayoutProps {
  children: React.ReactNode
  contentCentered: boolean
}

export const FullPageLayout: React.FC<FullPageLayoutProps> = ({
  children,
  contentCentered = false,
}) => {
  return (
    <Layout
      className={classNames({
        [styles.contentCentered]: contentCentered,
      })}
    >
      <Content className={styles.content}>{children}</Content>
    </Layout>
  )
}
