import { useMemo } from "react"
import { UploadFile, UploadProps } from "antd/lib/upload/interface"
import { api } from "api"
import * as FileSaver from "file-saver"
import { FileDto } from "api/generated/namecheck"

const normFile = (event: any) => {
  // only allow one file
  return [event.file]
}

const downloadFile = async (id: number) => {
  let fileName = "unnamed_file.pdf"

  return api.namecheck.file
    .downloadFileByIdRaw({ id })
    .then(response => {
      const contentDispositionHeader = response.raw.headers.get(
        "content-disposition"
      )

      // Display the key/value pairs
      /* for (const pair of response.raw.headers.entries() as any) {
        console.log(pair[0] + ": " + pair[1])
      } */

      if (contentDispositionHeader) {
        const parts = contentDispositionHeader.match(/filename\*=UTF-8''(.*)/)
        fileName = (parts && parts[1]) || "unnamed_file"
      }

      return response.raw.arrayBuffer()
    })
    .then(arrayBuffer => {
      FileSaver.saveAs(new Blob([arrayBuffer]), fileName)
    })
}

const uploadFormItemProps = {
  valuePropName: "fileList",
  getValueFromEvent: normFile,
}

const mapFileToInitialList = (file?: FileDto): UploadFile<any>[] =>
  file
    ? [file].map(
        ({ name, id, url }) =>
          ({
            name,
            uid: id?.toString(),
            status: "done",
            response: "ok",
            url,
          } as UploadFile<any>)
      )
    : []

interface UseFileUpload {
  uploadFormItemProps: {
    valuePropName: string
    getValueFromEvent: (event: any) => any[]
  }
  uploadProps: UploadProps<any>
  mapFileToInitialList: (file?: any) => UploadFile<any>[]
  downloadFile: (id: number) => Promise<void>
}

const useFileUpload = (
  accept = ".doc,.docx,application/msword,.pdf"
): UseFileUpload => {
  const uploadProps = useMemo<UploadProps>(
    () => ({
      beforeUpload: () => false,
      showUploadList: { showRemoveIcon: false, showDownloadIcon: true },
      onDownload: file => downloadFile(+file.uid),
      accept,
    }),
    [accept]
  )

  return {
    uploadFormItemProps,
    uploadProps,
    mapFileToInitialList,
    downloadFile,
  }
}

export default useFileUpload
