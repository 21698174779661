import { UserProfileDto, UserRole } from "api/generated/namecheck"
import { useCallback, useMemo } from "react"
import { GoogleLoginResponse } from "react-google-login"
import { useTranslation } from "react-i18next"
import { useSelector, useDispatch } from "react-redux"
import { authSliceActions, authSliceSelectors } from "./authSlice"
import { refreshGoogleTokenSetupHandler } from "./utils"

interface UseAuth {
  userIsLoggedIn: boolean
  user?: UserProfileDto
  loading: boolean
  hasUserRole: (contentRoles?: any[]) => boolean
  refreshGoogleTokenSetup: (res: any) => void
  userName: string
}

const useAuth = (): UseAuth => {
  const userIsLoggedIn = useSelector(authSliceSelectors.userIsLoggedIn)
  const user = useSelector(authSliceSelectors.user)
  const loading = useSelector(authSliceSelectors.authIsLoading)
  const dispatch = useDispatch()
  const { i18n } = useTranslation()

  const hasUserRole = useCallback(
    (contentRoles?: UserRole[]) => {
      const userRoles = user?.roles || []

      return (
        !contentRoles ||
        (!!contentRoles.length &&
          contentRoles.some(routeRole => userRoles.includes(routeRole)))
      )
    },
    [user?.roles]
  )

  const refreshGoogleTokenSetup = useCallback(
    (res: GoogleLoginResponse) =>
      refreshGoogleTokenSetupHandler(
        res,
        (tokenId: string) => dispatch(authSliceActions.logIn(tokenId)),
        () => dispatch(authSliceActions.logOut())
      ),
    [dispatch]
  )

  const userName = useMemo(
    () =>
      i18n.language === "hu"
        ? `${user?.lastName} ${user?.firstName}`
        : `${user?.firstName} ${user?.lastName}`,
    [i18n.language, user]
  )

  return {
    userIsLoggedIn,
    user,
    loading,
    hasUserRole,
    refreshGoogleTokenSetup,
    userName,
  }
}

export default useAuth
