import { useMemo, useRef } from "react"
import { FilterDropdownProps } from "antd/lib/table/interface"
import { Button, Input, Space } from "antd"
import { useTranslation } from "react-i18next"
import { SearchOutlined } from "@ant-design/icons"

interface UseTableFilters {
  filterTableColumnProps: {
    filterDropdown: (props: FilterDropdownProps) => JSX.Element
    filterIcon: (filtered: boolean) => JSX.Element
    onFilterDropdownVisibleChange: (visible: boolean) => void
  }
}

const useTableFilters = (): UseTableFilters => {
  const searchInput = useRef<Input>(null)

  const { t } = useTranslation()

  const filterTableColumnProps = useMemo(
    () => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: FilterDropdownProps) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder={t("table.filters.search")}
            value={selectedKeys[0]}
            onChange={e =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 110 }}
            >
              {t("table.filters.search")}
            </Button>
            <Button
              onClick={() => clearFilters?.()}
              size="small"
              style={{ width: 110 }}
            >
              {t("table.filters.reset")}
            </Button>
          </Space>
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (visible) {
          setTimeout(() => searchInput?.current?.select(), 100)
        }
      },
    }),
    [t]
  )

  return {
    filterTableColumnProps,
  }
}

export default useTableFilters
