/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ContactDto,
    ContactDtoFromJSON,
    ContactDtoToJSON,
    CreateContactDto,
    CreateContactDtoFromJSON,
    CreateContactDtoToJSON,
    CreatePartnerDto,
    CreatePartnerDtoFromJSON,
    CreatePartnerDtoToJSON,
    PartnerActivationDto,
    PartnerActivationDtoFromJSON,
    PartnerActivationDtoToJSON,
    PartnerDto,
    PartnerDtoFromJSON,
    PartnerDtoToJSON,
    PartnerDtoPaginatedResultDto,
    PartnerDtoPaginatedResultDtoFromJSON,
    PartnerDtoPaginatedResultDtoToJSON,
    PartnerStatusDto,
    PartnerStatusDtoFromJSON,
    PartnerStatusDtoToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    UpdateContactDto,
    UpdateContactDtoFromJSON,
    UpdateContactDtoToJSON,
    UpdatePartnerDto,
    UpdatePartnerDtoFromJSON,
    UpdatePartnerDtoToJSON,
} from '../models';

export interface CreateContactRequest {
    partnerId: number;
    id: string;
    createContactDto?: CreateContactDto;
}

export interface CreatePartnerRequest {
    createPartnerDto?: CreatePartnerDto;
}

export interface DeleteContactRequest {
    id: number;
    partnerId: number;
}

export interface DeletePartnerRequest {
    id: number;
}

export interface GetContactRequest {
    id: number;
    partnerId: number;
}

export interface GetContactListRequest {
    partnerId: number;
}

export interface GetPartnerRequest {
    id: number;
}

export interface GetPartnerListPagedRequest {
    take?: number;
    page?: number;
}

export interface SetDeactivatedFlagForPartnerRequest {
    partnerActivationDto?: PartnerActivationDto;
}

export interface UpdateContactRequest {
    partnerId: number;
    id: string;
    updateContactDto?: UpdateContactDto;
}

export interface UpdatePartnerRequest {
    id: string;
    updatePartnerDto?: UpdatePartnerDto;
}

/**
 * 
 */
export class PartnerApi extends runtime.BaseAPI {

    /**
     */
    async createContactRaw(requestParameters: CreateContactRequest): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.partnerId === null || requestParameters.partnerId === undefined) {
            throw new runtime.RequiredError('partnerId','Required parameter requestParameters.partnerId was null or undefined when calling createContact.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling createContact.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/Partner/{partnerId}/Contact/{id}`.replace(`{${"partnerId"}}`, encodeURIComponent(String(requestParameters.partnerId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateContactDtoToJSON(requestParameters.createContactDto),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async createContact(requestParameters: CreateContactRequest): Promise<number> {
        const response = await this.createContactRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async createPartnerRaw(requestParameters: CreatePartnerRequest): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/Partner`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePartnerDtoToJSON(requestParameters.createPartnerDto),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async createPartner(requestParameters: CreatePartnerRequest): Promise<number> {
        const response = await this.createPartnerRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteContactRaw(requestParameters: DeleteContactRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteContact.');
        }

        if (requestParameters.partnerId === null || requestParameters.partnerId === undefined) {
            throw new runtime.RequiredError('partnerId','Required parameter requestParameters.partnerId was null or undefined when calling deleteContact.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/Partner/{partnerId}/Contact/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"partnerId"}}`, encodeURIComponent(String(requestParameters.partnerId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteContact(requestParameters: DeleteContactRequest): Promise<void> {
        await this.deleteContactRaw(requestParameters);
    }

    /**
     */
    async deletePartnerRaw(requestParameters: DeletePartnerRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deletePartner.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/Partner/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deletePartner(requestParameters: DeletePartnerRequest): Promise<void> {
        await this.deletePartnerRaw(requestParameters);
    }

    /**
     */
    async getContactRaw(requestParameters: GetContactRequest): Promise<runtime.ApiResponse<ContactDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getContact.');
        }

        if (requestParameters.partnerId === null || requestParameters.partnerId === undefined) {
            throw new runtime.RequiredError('partnerId','Required parameter requestParameters.partnerId was null or undefined when calling getContact.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/Partner/{partnerId}/Contact/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"partnerId"}}`, encodeURIComponent(String(requestParameters.partnerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ContactDtoFromJSON(jsonValue));
    }

    /**
     */
    async getContact(requestParameters: GetContactRequest): Promise<ContactDto> {
        const response = await this.getContactRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getContactListRaw(requestParameters: GetContactListRequest): Promise<runtime.ApiResponse<Array<ContactDto>>> {
        if (requestParameters.partnerId === null || requestParameters.partnerId === undefined) {
            throw new runtime.RequiredError('partnerId','Required parameter requestParameters.partnerId was null or undefined when calling getContactList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/Partner/{partnerId}`.replace(`{${"partnerId"}}`, encodeURIComponent(String(requestParameters.partnerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ContactDtoFromJSON));
    }

    /**
     */
    async getContactList(requestParameters: GetContactListRequest): Promise<Array<ContactDto>> {
        const response = await this.getContactListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getPartnerRaw(requestParameters: GetPartnerRequest): Promise<runtime.ApiResponse<PartnerDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPartner.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/Partner/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PartnerDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPartner(requestParameters: GetPartnerRequest): Promise<PartnerDto> {
        const response = await this.getPartnerRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getPartnerListPagedRaw(requestParameters: GetPartnerListPagedRequest): Promise<runtime.ApiResponse<PartnerDtoPaginatedResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.take !== undefined) {
            queryParameters['Take'] = requestParameters.take;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/Partner`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PartnerDtoPaginatedResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async getPartnerListPaged(requestParameters: GetPartnerListPagedRequest): Promise<PartnerDtoPaginatedResultDto> {
        const response = await this.getPartnerListPagedRaw(requestParameters);
        return await response.value();
    }

    /**
     * Egy adott felhasználó meghívójának/profiljának korlátozása.   Jogkör: Admin.
     */
    async setDeactivatedFlagForPartnerRaw(requestParameters: SetDeactivatedFlagForPartnerRequest): Promise<runtime.ApiResponse<PartnerStatusDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/Partner/SetActivationState`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PartnerActivationDtoToJSON(requestParameters.partnerActivationDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PartnerStatusDtoFromJSON(jsonValue));
    }

    /**
     * Egy adott felhasználó meghívójának/profiljának korlátozása.   Jogkör: Admin.
     */
    async setDeactivatedFlagForPartner(requestParameters: SetDeactivatedFlagForPartnerRequest): Promise<PartnerStatusDto> {
        const response = await this.setDeactivatedFlagForPartnerRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateContactRaw(requestParameters: UpdateContactRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.partnerId === null || requestParameters.partnerId === undefined) {
            throw new runtime.RequiredError('partnerId','Required parameter requestParameters.partnerId was null or undefined when calling updateContact.');
        }

        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateContact.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/Partner/{partnerId}/Contact/{id}`.replace(`{${"partnerId"}}`, encodeURIComponent(String(requestParameters.partnerId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateContactDtoToJSON(requestParameters.updateContactDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateContact(requestParameters: UpdateContactRequest): Promise<void> {
        await this.updateContactRaw(requestParameters);
    }

    /**
     */
    async updatePartnerRaw(requestParameters: UpdatePartnerRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePartner.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/Partner/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePartnerDtoToJSON(requestParameters.updatePartnerDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updatePartner(requestParameters: UpdatePartnerRequest): Promise<void> {
        await this.updatePartnerRaw(requestParameters);
    }

}
