/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateBlacklistedCompanyDto
 */
export interface CreateBlacklistedCompanyDto {
    /**
     * 
     * @type {string}
     * @memberof CreateBlacklistedCompanyDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateBlacklistedCompanyDto
     */
    comment?: string | null;
}

export function CreateBlacklistedCompanyDtoFromJSON(json: any): CreateBlacklistedCompanyDto {
    return CreateBlacklistedCompanyDtoFromJSONTyped(json, false);
}

export function CreateBlacklistedCompanyDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateBlacklistedCompanyDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
    };
}

export function CreateBlacklistedCompanyDtoToJSON(value?: CreateBlacklistedCompanyDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'comment': value.comment,
    };
}


