import { Error404 } from "components/layout/Error404.page"
import React from "react"
import { Switch } from "react-router-dom"
import routes from "router/routes"
import { PublicRoute } from "./PublicRoute"

export const RouterView: React.FC = () => {
  return (
    <Switch>
      {routes}

      <PublicRoute component={Error404} />
    </Switch>
  )
}
