/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateContactDto
 */
export interface CreateContactDto {
    /**
     * 
     * @type {string}
     * @memberof CreateContactDto
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateContactDto
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof CreateContactDto
     */
    phoneNumber: string;
}

export function CreateContactDtoFromJSON(json: any): CreateContactDto {
    return CreateContactDtoFromJSONTyped(json, false);
}

export function CreateContactDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateContactDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'fullName': json['fullName'],
        'emailAddress': json['emailAddress'],
        'phoneNumber': json['phoneNumber'],
    };
}

export function CreateContactDtoToJSON(value?: CreateContactDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'fullName': value.fullName,
        'emailAddress': value.emailAddress,
        'phoneNumber': value.phoneNumber,
    };
}


