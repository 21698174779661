/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CheckSource {
    InvalidEnum = 'InvalidEnum',
    Default = 'Default',
    HeroImportApplyForm = 'HeroImportApplyForm',
    HeroImportTalentPool = 'HeroImportTalentPool',
    NC1Migrated = 'NC1Migrated'
}

export function CheckSourceFromJSON(json: any): CheckSource {
    return CheckSourceFromJSONTyped(json, false);
}

export function CheckSourceFromJSONTyped(json: any, ignoreDiscriminator: boolean): CheckSource {
    return json as CheckSource;
}

export function CheckSourceToJSON(value?: CheckSource | null): any {
    return value as any;
}

