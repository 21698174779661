import {
  Divider,
  Button,
  Space,
  Tooltip,
  Typography,
  Table,
  message,
  Form,
  Tag,
  Modal,
  Input,
} from "antd"
import { Trans, useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useCallback, useEffect, useState } from "react"
import { RootState } from "app/store"
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons"
import { ColumnsType, TablePaginationConfig } from "antd/lib/table"
import {
  blacklistSliceActions,
  blacklistSliceSelectors,
} from "./BlacklistSlice"
import { BlacklistedCompanyDto, UserRole } from "api/generated/namecheck"
import { formatMomentDate } from "utils/dateUtils"
import moment from "moment"
import confirm from "antd/lib/modal/confirm"
import { FeatureState } from "models/FeatureState"
import { formItemRequired } from "utils/formItemRules"
import useSSTablePagination from "hooks/useSSTablePagination"
import TextArea from "antd/lib/input/TextArea"
import useAuth from "features/auth/useAuth"

const Blacklist: React.FC = () => {
  const { pagination, list, actionState } = useSelector(
    (state: RootState) => state.blacklist
  )

  const [showCreateModal, setShowCreateModal] = useState<any>(false)
  const [editedCompany, setEditedCompany] = useState<
    BlacklistedCompanyDto | undefined
  >(undefined)

  const loading = useSelector(blacklistSliceSelectors.isLoading)

  const { t } = useTranslation()

  const { hasUserRole } = useAuth()

  const [form] = Form.useForm()

  const onClickCreate = (event: any): void => {
    form.resetFields()
    setEditedCompany(undefined)
    setShowCreateModal(true)
  }

  const onClickEdit = (data: BlacklistedCompanyDto): void => {
    form.setFieldsValue(data)
    setEditedCompany(data)
    setShowCreateModal(true)
  }

  const onFinish = async (values: BlacklistedCompanyDto): Promise<any> => {
    editedCompany
      ? await dispatch(blacklistSliceActions.updateCompany(values))
      : await dispatch(blacklistSliceActions.createCompany(values))

    loadTableData()
    editedCompany
      ? message.success(t("pages.Blacklist.edit.modal.responseSuccess"))
      : message.success(t("pages.Blacklist.create.modal.responseSuccess"))

    dispatch(blacklistSliceActions.resetActionState())
    setShowCreateModal(false)
  }

  const paginationConfig = useSSTablePagination(pagination)

  const onClickDelete = (data: BlacklistedCompanyDto): void => {
    confirm({
      type: "error",
      title: t("pages.Blacklist.delete.modal.title"),
      content: (
        <Trans
          i18nKey="pages.Blacklist.delete.modal.content"
          defaults={t("pages.Blacklist.delete.modal.content")}
          values={{ companyInfo: data?.name }}
          components={{ tag: <Tag className="modal-info-tag" /> }}
        />
      ),
      icon: <DeleteOutlined />,
      okText: t("pages.Blacklist.delete.modal.ok"),
      okButtonProps: { danger: true },
      cancelText: t("pages.Blacklist.modal.cancel"),
      cancelButtonProps: { danger: true },
      onOk: async (e: any) => {
        await dispatch(blacklistSliceActions.deleteCompany(data.id ?? -1))
        message.warning(t("pages.Blacklist.delete.response"), 5)
        loadTableData()
      },
    })
  }

  const columns: ColumnsType<BlacklistedCompanyDto> = [
    {
      title: t("labels.Blacklist.name"),
      dataIndex: ["name"],
    },
    ...(hasUserRole([UserRole.Admin])
      ? [
          {
            title: t("labels.Blacklist.createdAt"),
            dataIndex: ["createdAt"],
            render: (createdAt?: Date) =>
              createdAt ? (
                <span className="no-wrap">
                  {formatMomentDate(moment(createdAt))}
                </span>
              ) : null,
          },
        ]
      : []),
    {
      title: t("labels.Blacklist.comment"),
      dataIndex: ["comment"],
    },
    {
      title: "",
      key: "actions",
      fixed: "right",
      align: "right",
      render: (text: string, record: BlacklistedCompanyDto) => (
        <Space size="small" align="center">
          <Tooltip title={t("labels.Blacklist.edit")} key="view">
            <Button
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => onClickEdit(record)}
              disabled={!hasUserRole([UserRole.Admin])}
            />
          </Tooltip>
          <Tooltip title={t("labels.Blacklist.delete")} key="view">
            <Button
              type="primary"
              shape="circle"
              icon={<DeleteOutlined />}
              danger
              onClick={() => onClickDelete(record)}
              disabled={!hasUserRole([UserRole.Admin])}
            />
          </Tooltip>
        </Space>
      ),
    },
  ]

  const dispatch = useDispatch()

  const loadTableData = useCallback(
    () =>
      dispatch(
        blacklistSliceActions.loadData({
          page: pagination.page,
          take: pagination.take,
        })
      ),
    [dispatch, pagination.page, pagination.take]
  )

  useEffect(() => {
    loadTableData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleTableChange = useCallback(
    (newPaginationConfig: TablePaginationConfig) => {
      dispatch(
        blacklistSliceActions.loadData({
          page: (newPaginationConfig.current ?? 1) - 1,
          take: newPaginationConfig.pageSize,
        })
      )
    },
    [dispatch]
  )

  return (
    <>
      <Typography.Title>
        <div>{t("pages.Blacklist.title")}</div>
        <Divider />
        {hasUserRole([UserRole.Admin]) && (
          <Button size="large" type="primary" onClick={onClickCreate}>
            <PlusOutlined /> {t("forms.Blacklist.createCompany")}
          </Button>
        )}
      </Typography.Title>

      <Table
        dataSource={list}
        columns={columns}
        loading={loading}
        rowKey="id"
        scroll={{ x: true }}
        pagination={paginationConfig}
        onChange={handleTableChange}
      />
      <Modal
        title={
          editedCompany
            ? t("pages.Blacklist.edit.modal.title")
            : t("pages.Blacklist.create.modal.title")
        }
        visible={showCreateModal}
        okText={
          editedCompany
            ? t("pages.Blacklist.edit.modal.ok")
            : t("pages.Blacklist.create.modal.ok")
        }
        onOk={() => form.submit()}
        cancelText={t("pages.Blacklist.modal.cancel")}
        onCancel={() => setShowCreateModal(false)}
        okButtonProps={{
          loading: actionState === FeatureState.Loading,
        }}
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            hidden
            name="id"
            rules={editedCompany ? formItemRequired : undefined}
          >
            <Input type="number" hidden />
          </Form.Item>
          <Form.Item
            label={t("labels.Blacklist.name")}
            name="name"
            rules={formItemRequired}
          >
            <Input type="text" maxLength={255} />
          </Form.Item>
          <Form.Item label={t("labels.Blacklist.comment")} name="comment">
            <TextArea maxLength={255} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default Blacklist
