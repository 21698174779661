/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HealthReportEntry,
    HealthReportEntryFromJSON,
    HealthReportEntryFromJSONTyped,
    HealthReportEntryToJSON,
    HealthStatus,
    HealthStatusFromJSON,
    HealthStatusFromJSONTyped,
    HealthStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface HealthReport
 */
export interface HealthReport {
    /**
     * 
     * @type {{ [key: string]: HealthReportEntry; }}
     * @memberof HealthReport
     */
    entries?: { [key: string]: HealthReportEntry; } | null;
    /**
     * 
     * @type {HealthStatus}
     * @memberof HealthReport
     */
    status?: HealthStatus;
    /**
     * 
     * @type {string}
     * @memberof HealthReport
     */
    totalDuration?: string;
}

export function HealthReportFromJSON(json: any): HealthReport {
    return HealthReportFromJSONTyped(json, false);
}

export function HealthReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): HealthReport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'entries': !exists(json, 'entries') ? undefined : (json['entries'] === null ? null : mapValues(json['entries'], HealthReportEntryFromJSON)),
        'status': !exists(json, 'status') ? undefined : HealthStatusFromJSON(json['status']),
        'totalDuration': !exists(json, 'totalDuration') ? undefined : json['totalDuration'],
    };
}

export function HealthReportToJSON(value?: HealthReport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'entries': value.entries === undefined ? undefined : (value.entries === null ? null : mapValues(value.entries, HealthReportEntryToJSON)),
        'status': HealthStatusToJSON(value.status),
        'totalDuration': value.totalDuration,
    };
}


