/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SalaryExpectationType,
    SalaryExpectationTypeFromJSON,
    SalaryExpectationTypeFromJSONTyped,
    SalaryExpectationTypeToJSON,
} from './';

/**
 * 
 * @export
 * @interface InterviewProfileUpdateDto
 */
export interface InterviewProfileUpdateDto {
    /**
     * 
     * @type {number}
     * @memberof InterviewProfileUpdateDto
     */
    positionId?: number | null;
    /**
     * 
     * @type {SalaryExpectationType}
     * @memberof InterviewProfileUpdateDto
     */
    salaryExpectationType?: SalaryExpectationType;
    /**
     * 
     * @type {string}
     * @memberof InterviewProfileUpdateDto
     */
    salaryExpectationValue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InterviewProfileUpdateDto
     */
    siteCode?: string | null;
}

export function InterviewProfileUpdateDtoFromJSON(json: any): InterviewProfileUpdateDto {
    return InterviewProfileUpdateDtoFromJSONTyped(json, false);
}

export function InterviewProfileUpdateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewProfileUpdateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'positionId': !exists(json, 'positionId') ? undefined : json['positionId'],
        'salaryExpectationType': !exists(json, 'salaryExpectationType') ? undefined : SalaryExpectationTypeFromJSON(json['salaryExpectationType']),
        'salaryExpectationValue': !exists(json, 'salaryExpectationValue') ? undefined : json['salaryExpectationValue'],
        'siteCode': !exists(json, 'siteCode') ? undefined : json['siteCode'],
    };
}

export function InterviewProfileUpdateDtoToJSON(value?: InterviewProfileUpdateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'positionId': value.positionId,
        'salaryExpectationType': SalaryExpectationTypeToJSON(value.salaryExpectationType),
        'salaryExpectationValue': value.salaryExpectationValue,
        'siteCode': value.siteCode,
    };
}


