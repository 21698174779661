/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ListSortDirection,
    ListSortDirectionFromJSON,
    ListSortDirectionToJSON,
    NameCheckDataDto,
    NameCheckDataDtoFromJSON,
    NameCheckDataDtoToJSON,
    NameCheckDataDtoPaginatedResultDto,
    NameCheckDataDtoPaginatedResultDtoFromJSON,
    NameCheckDataDtoPaginatedResultDtoToJSON,
    NameCheckRequestDto,
    NameCheckRequestDtoFromJSON,
    NameCheckRequestDtoToJSON,
    NameCheckResultDto,
    NameCheckResultDtoFromJSON,
    NameCheckResultDtoToJSON,
    NameCheckResultOrder,
    NameCheckResultOrderFromJSON,
    NameCheckResultOrderToJSON,
} from '../models';

export interface AllowNameCheckRequest {
    nameCheckGuid: string;
}

export interface GetNameCheckRequest {
    nameCheckGuid?: string;
}

export interface GetNameCheckListRequest {
    checkUserEmailAddress?: string;
    partnerName?: string;
    candidateName?: string;
    checkTime?: Date;
    hideSuccessful?: boolean;
    orderBy?: NameCheckResultOrder;
    orderDirection?: ListSortDirection;
    take?: number;
    page?: number;
}

export interface HeroNameCheckRequest {
    nameCheckRequestDto?: NameCheckRequestDto;
}

export interface NameCheckRequest {
    nameCheckRequestDto?: NameCheckRequestDto;
}

export interface RefuseNameCheckRequest {
    nameCheckGuid: string;
}

/**
 * 
 */
export class NameCheckApi extends runtime.BaseAPI {

    /**
     * NameCheck eredmény engedélyezése
     */
    async allowNameCheckRaw(requestParameters: AllowNameCheckRequest): Promise<runtime.ApiResponse<NameCheckDataDto>> {
        if (requestParameters.nameCheckGuid === null || requestParameters.nameCheckGuid === undefined) {
            throw new runtime.RequiredError('nameCheckGuid','Required parameter requestParameters.nameCheckGuid was null or undefined when calling allowNameCheck.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/NameCheck/{nameCheckGuid}/allow`.replace(`{${"nameCheckGuid"}}`, encodeURIComponent(String(requestParameters.nameCheckGuid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NameCheckDataDtoFromJSON(jsonValue));
    }

    /**
     * NameCheck eredmény engedélyezése
     */
    async allowNameCheck(requestParameters: AllowNameCheckRequest): Promise<NameCheckDataDto> {
        const response = await this.allowNameCheckRaw(requestParameters);
        return await response.value();
    }

    /**
     * NameCheck eredmények lekérése.   Jogkör: Bármely bejelentkezett felhasználó.
     */
    async getNameCheckRaw(requestParameters: GetNameCheckRequest): Promise<runtime.ApiResponse<NameCheckDataDto>> {
        const queryParameters: any = {};

        if (requestParameters.nameCheckGuid !== undefined) {
            queryParameters['nameCheckGuid'] = requestParameters.nameCheckGuid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/NameCheck`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NameCheckDataDtoFromJSON(jsonValue));
    }

    /**
     * NameCheck eredmények lekérése.   Jogkör: Bármely bejelentkezett felhasználó.
     */
    async getNameCheck(requestParameters: GetNameCheckRequest): Promise<NameCheckDataDto> {
        const response = await this.getNameCheckRaw(requestParameters);
        return await response.value();
    }

    /**
     * NameCheck eredmények lekérése szűréssel.  Jogkör: Bármely bejelentkezett felhasználó.
     */
    async getNameCheckListRaw(requestParameters: GetNameCheckListRequest): Promise<runtime.ApiResponse<NameCheckDataDtoPaginatedResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.checkUserEmailAddress !== undefined) {
            queryParameters['CheckUserEmailAddress'] = requestParameters.checkUserEmailAddress;
        }

        if (requestParameters.partnerName !== undefined) {
            queryParameters['PartnerName'] = requestParameters.partnerName;
        }

        if (requestParameters.candidateName !== undefined) {
            queryParameters['CandidateName'] = requestParameters.candidateName;
        }

        if (requestParameters.checkTime !== undefined) {
            queryParameters['CheckTime'] = (requestParameters.checkTime as any).toISOString();
        }

        if (requestParameters.hideSuccessful !== undefined) {
            queryParameters['HideSuccessful'] = requestParameters.hideSuccessful;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['OrderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.orderDirection !== undefined) {
            queryParameters['OrderDirection'] = requestParameters.orderDirection;
        }

        if (requestParameters.take !== undefined) {
            queryParameters['Take'] = requestParameters.take;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/NameCheck/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NameCheckDataDtoPaginatedResultDtoFromJSON(jsonValue));
    }

    /**
     * NameCheck eredmények lekérése szűréssel.  Jogkör: Bármely bejelentkezett felhasználó.
     */
    async getNameCheckList(requestParameters: GetNameCheckListRequest): Promise<NameCheckDataDtoPaginatedResultDto> {
        const response = await this.getNameCheckListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Hero -tól érkező kérés ellenőriz hogy a jelölt szerepel-e már a rendszerben.  Ez a végpont basic auth.-ot használ.
     */
    async heroNameCheckRaw(requestParameters: HeroNameCheckRequest): Promise<runtime.ApiResponse<NameCheckResultDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/NameCheck/HeroNameCheck`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NameCheckRequestDtoToJSON(requestParameters.nameCheckRequestDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NameCheckResultDtoFromJSON(jsonValue));
    }

    /**
     * Hero -tól érkező kérés ellenőriz hogy a jelölt szerepel-e már a rendszerben.  Ez a végpont basic auth.-ot használ.
     */
    async heroNameCheck(requestParameters: HeroNameCheckRequest): Promise<NameCheckResultDto> {
        const response = await this.heroNameCheckRaw(requestParameters);
        return await response.value();
    }

    /**
     * Ellenőriz hogy a jelölt szerepel-e már a rendszerben  ReCaptcha ellenőrzéssel
     */
    async nameCheckRaw(requestParameters: NameCheckRequest): Promise<runtime.ApiResponse<NameCheckResultDto>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/NameCheck`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NameCheckRequestDtoToJSON(requestParameters.nameCheckRequestDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NameCheckResultDtoFromJSON(jsonValue));
    }

    /**
     * Ellenőriz hogy a jelölt szerepel-e már a rendszerben  ReCaptcha ellenőrzéssel
     */
    async nameCheck(requestParameters: NameCheckRequest): Promise<NameCheckResultDto> {
        const response = await this.nameCheckRaw(requestParameters);
        return await response.value();
    }

    /**
     * NameCheck eredmény elutasítása
     */
    async refuseNameCheckRaw(requestParameters: RefuseNameCheckRequest): Promise<runtime.ApiResponse<NameCheckDataDto>> {
        if (requestParameters.nameCheckGuid === null || requestParameters.nameCheckGuid === undefined) {
            throw new runtime.RequiredError('nameCheckGuid','Required parameter requestParameters.nameCheckGuid was null or undefined when calling refuseNameCheck.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/NameCheck/{nameCheckGuid}/refuse`.replace(`{${"nameCheckGuid"}}`, encodeURIComponent(String(requestParameters.nameCheckGuid))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => NameCheckDataDtoFromJSON(jsonValue));
    }

    /**
     * NameCheck eredmény elutasítása
     */
    async refuseNameCheck(requestParameters: RefuseNameCheckRequest): Promise<NameCheckDataDto> {
        const response = await this.refuseNameCheckRaw(requestParameters);
        return await response.value();
    }

}
