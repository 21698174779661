import { Redirect, useHistory } from "react-router-dom"

interface ErrorLayoutProps {
  children: React.ReactNode
}

export const ErrorLayout: React.FC<ErrorLayoutProps> = ({ children }): any => {
  const history = useHistory()

  // POP means user accessed error page directly
  return history.action === "POP" ? <Redirect to="/" /> : children
}
