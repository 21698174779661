import { NameCheckDataDto } from "api/generated/namecheck"
import moment from "moment"
import { convertBackendDateToMoment } from "utils/dateUtils"
import {
  mapToNameCheckMatchDto,
  mapToNameCheckMatch,
  NameCheckMatch,
} from "./NameCheckMatch"

export interface NameCheckData
  extends Omit<NameCheckDataDto, "dateOfBirth" | "createdAt" | "matches"> {
  dateOfBirth?: moment.Moment
  createdAt?: moment.Moment
  matches?: NameCheckMatch[]
}

export const mapToNameCheckData = (dto: NameCheckDataDto): NameCheckData => {
  const data: NameCheckData = {
    ...dto,
    dateOfBirth: convertBackendDateToMoment(dto.dateOfBirth),
    createdAt: convertBackendDateToMoment(dto.createdAt),
    matches: dto.matches?.map(x => mapToNameCheckMatch(x)) ?? [],
  }
  return data
}

export const mapToNameCheckDataDto = (
  data?: NameCheckData
): NameCheckDataDto => {
  const dto: NameCheckDataDto = {
    ...data,
    dateOfBirth: data?.dateOfBirth ? data?.dateOfBirth.toDate() : undefined,
    createdAt: data?.createdAt ? data?.createdAt.toDate() : undefined,
    matches: data?.matches?.map(d => mapToNameCheckMatchDto(d)) ?? [],
  }
  return dto
}
