/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BlacklistedCompanyDto,
    BlacklistedCompanyDtoFromJSON,
    BlacklistedCompanyDtoToJSON,
    BlacklistedCompanyDtoPaginatedResultDto,
    BlacklistedCompanyDtoPaginatedResultDtoFromJSON,
    BlacklistedCompanyDtoPaginatedResultDtoToJSON,
    CreateBlacklistedCompanyDto,
    CreateBlacklistedCompanyDtoFromJSON,
    CreateBlacklistedCompanyDtoToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    UpdateBlacklistedCompanyDto,
    UpdateBlacklistedCompanyDtoFromJSON,
    UpdateBlacklistedCompanyDtoToJSON,
} from '../models';

export interface CreateBlacklistRequest {
    createBlacklistedCompanyDto?: CreateBlacklistedCompanyDto;
}

export interface DeleteBlacklistRequest {
    id: number;
}

export interface GetAllBlacklistRequest {
    take?: number;
    page?: number;
}

export interface GetBlacklistRequest {
    id: number;
}

export interface UpdateBlacklistRequest {
    id: string;
    updateBlacklistedCompanyDto?: UpdateBlacklistedCompanyDto;
}

/**
 * 
 */
export class BlacklistedCompanyApi extends runtime.BaseAPI {

    /**
     */
    async createBlacklistRaw(requestParameters: CreateBlacklistRequest): Promise<runtime.ApiResponse<number>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/BlacklistedCompany`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateBlacklistedCompanyDtoToJSON(requestParameters.createBlacklistedCompanyDto),
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async createBlacklist(requestParameters: CreateBlacklistRequest): Promise<number> {
        const response = await this.createBlacklistRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async deleteBlacklistRaw(requestParameters: DeleteBlacklistRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteBlacklist.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/BlacklistedCompany/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteBlacklist(requestParameters: DeleteBlacklistRequest): Promise<void> {
        await this.deleteBlacklistRaw(requestParameters);
    }

    /**
     */
    async getAllBlacklistRaw(requestParameters: GetAllBlacklistRequest): Promise<runtime.ApiResponse<BlacklistedCompanyDtoPaginatedResultDto>> {
        const queryParameters: any = {};

        if (requestParameters.take !== undefined) {
            queryParameters['Take'] = requestParameters.take;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['Page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/BlacklistedCompany`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BlacklistedCompanyDtoPaginatedResultDtoFromJSON(jsonValue));
    }

    /**
     */
    async getAllBlacklist(requestParameters: GetAllBlacklistRequest): Promise<BlacklistedCompanyDtoPaginatedResultDto> {
        const response = await this.getAllBlacklistRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getBlacklistRaw(requestParameters: GetBlacklistRequest): Promise<runtime.ApiResponse<BlacklistedCompanyDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getBlacklist.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/BlacklistedCompany/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BlacklistedCompanyDtoFromJSON(jsonValue));
    }

    /**
     */
    async getBlacklist(requestParameters: GetBlacklistRequest): Promise<BlacklistedCompanyDto> {
        const response = await this.getBlacklistRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async updateBlacklistRaw(requestParameters: UpdateBlacklistRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateBlacklist.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json-patch+json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/BlacklistedCompany/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateBlacklistedCompanyDtoToJSON(requestParameters.updateBlacklistedCompanyDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateBlacklist(requestParameters: UpdateBlacklistRequest): Promise<void> {
        await this.updateBlacklistRaw(requestParameters);
    }

}
