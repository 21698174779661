/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PartnerDto,
    PartnerDtoFromJSON,
    PartnerDtoFromJSONTyped,
    PartnerDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface AspNetUserDto
 */
export interface AspNetUserDto {
    /**
     * 
     * @type {string}
     * @memberof AspNetUserDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AspNetUserDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AspNetUserDto
     */
    readonly fullName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AspNetUserDto
     */
    email?: string | null;
    /**
     * 
     * @type {PartnerDto}
     * @memberof AspNetUserDto
     */
    partner?: PartnerDto;
}

export function AspNetUserDtoFromJSON(json: any): AspNetUserDto {
    return AspNetUserDtoFromJSONTyped(json, false);
}

export function AspNetUserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AspNetUserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'partner': !exists(json, 'partner') ? undefined : PartnerDtoFromJSON(json['partner']),
    };
}

export function AspNetUserDtoToJSON(value?: AspNetUserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'partner': PartnerDtoToJSON(value.partner),
    };
}


