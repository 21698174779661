import { FeaturePagination } from "models/FeaturePagination"

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getEnumTranslationKey = (enumType: string, key: any): string =>
  key === "InvalidEnum" ? "" : `enums.${enumType}.${key}`

export const getEnumValues = (object: { [s: string]: string }): string[] =>
  Object.values(object).filter(value => value !== "InvalidEnum")

// Get rid of InvalidEnum value. BE hotfix...
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getEnumRealValue = (value: any): string | null =>
  value === "InvalidEnum" ? null : value

export const getInitialPaginationState = (
  pageSize = 50
): FeaturePagination => ({
  take: pageSize,
  page: 0,
  total: 0,
})
