/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateContactDto
 */
export interface UpdateContactDto {
    /**
     * 
     * @type {number}
     * @memberof UpdateContactDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateContactDto
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateContactDto
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateContactDto
     */
    phoneNumber: string;
}

export function UpdateContactDtoFromJSON(json: any): UpdateContactDto {
    return UpdateContactDtoFromJSONTyped(json, false);
}

export function UpdateContactDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateContactDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'fullName': json['fullName'],
        'emailAddress': json['emailAddress'],
        'phoneNumber': json['phoneNumber'],
    };
}

export function UpdateContactDtoToJSON(value?: UpdateContactDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'fullName': value.fullName,
        'emailAddress': value.emailAddress,
        'phoneNumber': value.phoneNumber,
    };
}


