import { useDispatch, useSelector } from "react-redux"
import { useEffect } from "react"
import { Link, useHistory, useParams } from "react-router-dom"
import {
  nameCheckSingleSliceActions,
  nameCheckSingleSliceSelectors,
} from "./NameCheckSingleSlice"
import { useTranslation } from "react-i18next"
import {
  Form,
  Input,
  Button,
  DatePicker,
  Select,
  Row,
  Col,
  Space,
  Skeleton,
  Divider,
  Typography,
  Table,
  message,
  Tag,
  Tooltip,
} from "antd"
import {
  CheckCircleFilled,
  CheckCircleOutlined,
  CloseCircleFilled,
  EyeOutlined,
  InfoCircleOutlined,
  ProfileOutlined,
  RollbackOutlined,
} from "@ant-design/icons"
import {
  formItemEmail,
  formItemRequired,
  formItemRequiredNoWhitespace,
} from "utils/formItemRules"
import { getEnumTranslationKey, getEnumValues } from "utils/common"
import { NameCheckData } from "models/NameCheckData"
import {
  CheckSource,
  CheckStatus,
  MatchResult,
  NameCheckResultDto,
  UserRole,
} from "api/generated/namecheck"
import { ColumnsType } from "antd/lib/table"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import notification, { ArgsProps } from "antd/lib/notification"
import confirm from "antd/lib/modal/confirm"
import { getMatchTagColor } from "../utils"
import { formatMomentDate, MomentFormats } from "utils/dateUtils"
import { NameCheckMatch } from "models/NameCheckMatch"
import useAuth from "features/auth/useAuth"

const NameCheckSingle: React.FC<any> = () => {
  const dispatch = useDispatch()
  const params = useParams<any>()
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const history = useHistory()
  const { executeRecaptcha } = useGoogleReCaptcha()

  const { hasUserRole } = useAuth()

  const data = useSelector(nameCheckSingleSliceSelectors.data)
  const interviewProfileCreated = useSelector(
    nameCheckSingleSliceSelectors.interviewProfileCreated
  )
  const actionNeeded = useSelector(nameCheckSingleSliceSelectors.actionNeeded)
  const createMode = useSelector(nameCheckSingleSliceSelectors.createMode)
  const isDataLoading = useSelector(nameCheckSingleSliceSelectors.isDataLoading)

  useEffect(() => {
    if (params?.id) {
      dispatch(nameCheckSingleSliceActions.loadData(params.id))
    }
    return () => {
      dispatch(nameCheckSingleSliceActions.resetData())
    }
  }, [dispatch, params])

  useEffect(() => {
    form.setFieldsValue(data)
  }, [t, form, data])

  const onFinish = (fieldsValue: any) => {
    confirm({
      title: t("pages.NameCheck.modal.title"),
      content: t("pages.NameCheck.modal.content"),
      okText: t("pages.NameCheck.modal.ok"),
      cancelText: t("pages.NameCheck.modal.cancel"),
      onOk: async (_: any) => {
        if (!executeRecaptcha) {
          const notificationData: ArgsProps = {
            message: t("backend-errors.types.validationError"),
            description: t("backend-errors.CaptchaResponseFailed"),
            duration: null,
          }
          notification.error(notificationData)
          return
        }

        const googleReCaptchaResponse = await executeRecaptcha("nameCheck")

        const saveResponse: NameCheckResultDto = (await dispatch(
          nameCheckSingleSliceActions.saveData(
            form.getFieldsValue() as NameCheckData,
            googleReCaptchaResponse
          )
        )) as NameCheckResultDto

        if (saveResponse.nameCheckGuid) {
          history.push(`/namecheck/` + saveResponse.nameCheckGuid)

          if (saveResponse.result === MatchResult.NoMatch) {
            notification.success({
              message: t(
                getEnumTranslationKey("MatchResult", saveResponse.result)
              ),
              duration: 5,
            })
          } else {
            notification.error({
              message: t(
                getEnumTranslationKey("MatchResult", saveResponse.result)
              ),
              duration: 5,
            })
          }

          dispatch(nameCheckSingleSliceActions.resetSaveState())
        }
      },
    })
  }

  const onClickAllow = (event: any): void => {
    confirm({
      type: "info",
      title: t("pages.NameCheck.allow.modal.title"),
      content: t("pages.NameCheck.allow.modal.content"),
      icon: <CheckCircleFilled />,
      okText: t("pages.NameCheck.allow.modal.ok"),
      cancelText: t("pages.NameCheck.modal.cancel"),
      onOk: async (_: any) => {
        const guid = form.getFieldValue("nameCheckGuid")
        await dispatch(nameCheckSingleSliceActions.allowNameCheck(guid))
        message.success(t("pages.NameCheck.allow.response"))
      },
    })
  }

  const onClickRefuse = (event: any): void => {
    confirm({
      type: "error",
      title: t("pages.NameCheck.refuse.modal.title"),
      content: t("pages.NameCheck.refuse.modal.content"),
      icon: <CloseCircleFilled />,
      okText: t("pages.NameCheck.refuse.modal.ok"),
      okButtonProps: { danger: true },
      cancelText: t("pages.NameCheck.modal.cancel"),
      cancelButtonProps: { danger: true },
      onOk: async (_: any) => {
        const guid = form.getFieldValue("nameCheckGuid")
        await dispatch(nameCheckSingleSliceActions.refuseNameCheck(guid))
        message.error(t("pages.NameCheck.refuse.response"))
      },
    })
  }

  const columns: ColumnsType<NameCheckMatch> = [
    {
      title: t("labels.NameCheckData.lastName"),
      dataIndex: "lastName",
    },
    {
      title: t("labels.NameCheckData.firstName"),
      dataIndex: "firstName",
    },
    {
      title: () => (
        <Space size="small">
          <span>{t("labels.NameCheckData.personalData.label")}</span>
          <Tooltip
            placement="top"
            title={t("labels.NameCheckData.personalData.info")}
          >
            <InfoCircleOutlined className="info-icon" />
          </Tooltip>
        </Space>
      ),
      dataIndex: "personalData",
      render: (text: string, record: NameCheckMatch) => (
        <>
          <span>{record.emailAddress || record.additionalInfo}</span>
          {record.dateOfBirth && (
            <>
              <br />
              <small className="no-wrap">
                {formatMomentDate(record.dateOfBirth, MomentFormats.Date)}
              </small>
            </>
          )}
        </>
      ),
    },
    {
      title: t("labels.NameCheckData.result"),
      dataIndex: "result",
      render: (text: string, record: NameCheckMatch) => (
        <Tag color={getMatchTagColor(record?.result)}>
          {t(getEnumTranslationKey("MatchResult", record?.result))}
        </Tag>
      ),
    },
    {
      title: t("labels.NameCheckData.level"),
      dataIndex: "level",
    },
    {
      title: "",
      key: "actions",
      fixed: "right",
      render: (text: string, record: NameCheckMatch) => (
        <Space size="small" align="center">
          <Tooltip
            title={t("labels.NameCheckData.viewNameCheckResult")}
            key="view"
          >
            <Link to={`/namecheck/${record.nameCheckGuid}`}>
              <Button
                shape="circle"
                icon={<EyeOutlined />}
                disabled={!record.nameCheckGuid}
              />
            </Link>
          </Tooltip>
        </Space>
      ),
    },
  ]

  return (
    <>
      <Typography.Title>
        {createMode
          ? t("pages.NameCheck.title-create")
          : t("pages.NameCheck.title-view")}
      </Typography.Title>
      <Divider />
      {isDataLoading ? (
        <Skeleton active />
      ) : (
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item name="nameCheckGuid" hidden>
            <Input disabled hidden />
          </Form.Item>

          {!createMode && (
            <Row gutter={24}>
              <Col span={6} key="status">
                <Form.Item
                  label={t("labels.NameCheck.status")}
                  name="status"
                  rules={formItemRequired}
                >
                  <Select disabled>
                    {getEnumValues(CheckStatus).map(value => (
                      <Select.Option key={`chkstt_${value}`} value={value}>
                        {t(getEnumTranslationKey("CheckStatus", value))}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6} key="source">
                <Form.Item
                  label={t("labels.NameCheck.source")}
                  name="source"
                  rules={formItemRequired}
                >
                  <Select disabled>
                    {getEnumValues(CheckSource).map(value => (
                      <Select.Option key={`chksrc_${value}`} value={value}>
                        {t(getEnumTranslationKey("CheckSource", value))}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6} key="createdAt">
                <Form.Item
                  label={t("labels.NameCheck.createdAt")}
                  name="createdAt"
                  rules={formItemRequired}
                >
                  <DatePicker
                    showTime
                    format="YYYY-MM-DD HH:mm:ss"
                    disabled
                    className="ant-input"
                  />
                </Form.Item>
              </Col>
              <Col span={6} key="createdBy">
                <Form.Item
                  label={t("labels.NameCheck.createdBy")}
                  name={["createdBy", "email"]}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row gutter={24}>
            <Col span={12} key="lastName">
              <Form.Item
                label={t("labels.NameCheck.lastName")}
                name="lastName"
                rules={formItemRequired}
              >
                <Input disabled={!createMode} maxLength={255} />
              </Form.Item>
            </Col>
            <Col span={12} key="firstName">
              <Form.Item
                label={t("labels.NameCheck.firstName")}
                name="firstName"
                rules={formItemRequired}
              >
                <Input disabled={!createMode} maxLength={255} />
              </Form.Item>
            </Col>
            <Col span={12} key="emailAddress">
              <Form.Item
                label={t("labels.NameCheck.emailAddress")}
                name="emailAddress"
                rules={[...formItemRequiredNoWhitespace, ...formItemEmail]}
              >
                <Input
                  disabled={!createMode}
                  maxLength={255}
                  type="email"
                  placeholder={
                    createMode
                      ? undefined
                      : t("labels.NameCheck.emailAddressPlaceholder")
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12} key="dateOfBirth">
              <Form.Item
                label={t("labels.NameCheck.dateOfBirth")}
                name="dateOfBirth"
              >
                <DatePicker
                  disabled={!createMode}
                  className="ant-input"
                  format={MomentFormats.Date}
                  placeholder={
                    createMode
                      ? undefined
                      : t("labels.NameCheck.dateOfBirthPlaceholder")
                  }
                />
              </Form.Item>
            </Col>
            {!createMode && data?.additionalInfo && (
              <Col span={12} key="additionalInfo">
                <Form.Item
                  rules={formItemRequired}
                  label={
                    <Space size="small">
                      <span>{t("labels.NameCheck.additionalInfo")}</span>
                      <Tooltip
                        placement="top"
                        title={t("labels.NameCheck.additionalInfoExtra")}
                      >
                        <InfoCircleOutlined className="info-icon" />
                      </Tooltip>
                    </Space>
                  }
                  name="additionalInfo"
                >
                  <Input disabled={!createMode} />
                </Form.Item>
              </Col>
            )}
            {!createMode && hasUserRole([UserRole.Admin]) && data?.matches && (
              <Col span={24} key="matches">
                <Form.Item
                  label={t("labels.NameCheck.matches")}
                  name="matches"
                  rules={formItemRequired}
                >
                  <Table
                    dataSource={data?.matches ?? []}
                    columns={columns}
                    rowKey="id"
                    pagination={false}
                  />
                </Form.Item>
              </Col>
            )}
          </Row>

          <Divider />

          <Row justify="end">
            <Col>
              <Space size="middle">
                {hasUserRole([UserRole.Admin]) && (
                  <Link to={`/namecheck-results/`}>
                    <Button size="large">
                      <RollbackOutlined /> {t("forms.NameCheck.cancel")}
                    </Button>
                  </Link>
                )}
                {createMode && (
                  <Button type="primary" htmlType="submit" size="large">
                    <CheckCircleOutlined />
                    {t("forms.NameCheck.send")}
                  </Button>
                )}
                {interviewProfileCreated && (
                  <Link to={`/interview-profiles/` + data?.interviewProfileId}>
                    <Button size="large" type="primary">
                      <ProfileOutlined /> {t("forms.NameCheck.toProfile")}
                    </Button>
                  </Link>
                )}
                {hasUserRole([UserRole.Admin]) && actionNeeded && (
                  <>
                    <Button
                      type="primary"
                      danger
                      size="large"
                      onClick={onClickRefuse}
                    >
                      <CloseCircleFilled />
                      {t("forms.NameCheck.refuse")}
                    </Button>
                    <Button type="primary" size="large" onClick={onClickAllow}>
                      <CheckCircleFilled />
                      {t("forms.NameCheck.allow")}
                    </Button>
                  </>
                )}
              </Space>
            </Col>
          </Row>
        </Form>
      )}
    </>
  )
}

export default NameCheckSingle
