/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AspNetUserDto,
    AspNetUserDtoFromJSON,
    AspNetUserDtoFromJSONTyped,
    AspNetUserDtoToJSON,
    CheckSource,
    CheckSourceFromJSON,
    CheckSourceFromJSONTyped,
    CheckSourceToJSON,
    CheckStatus,
    CheckStatusFromJSON,
    CheckStatusFromJSONTyped,
    CheckStatusToJSON,
    NameCheckMatchDto,
    NameCheckMatchDtoFromJSON,
    NameCheckMatchDtoFromJSONTyped,
    NameCheckMatchDtoToJSON,
    PartnerDto,
    PartnerDtoFromJSON,
    PartnerDtoFromJSONTyped,
    PartnerDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface NameCheckDataDto
 */
export interface NameCheckDataDto {
    /**
     * 
     * @type {number}
     * @memberof NameCheckDataDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof NameCheckDataDto
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NameCheckDataDto
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NameCheckDataDto
     */
    readonly fullName?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof NameCheckDataDto
     */
    dateOfBirth?: Date;
    /**
     * 
     * @type {string}
     * @memberof NameCheckDataDto
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof NameCheckDataDto
     */
    additionalInfo?: string | null;
    /**
     * 
     * @type {Array<NameCheckMatchDto>}
     * @memberof NameCheckDataDto
     */
    readonly matches?: Array<NameCheckMatchDto> | null;
    /**
     * 
     * @type {CheckStatus}
     * @memberof NameCheckDataDto
     */
    status?: CheckStatus;
    /**
     * 
     * @type {CheckSource}
     * @memberof NameCheckDataDto
     */
    source?: CheckSource;
    /**
     * 
     * @type {Date}
     * @memberof NameCheckDataDto
     */
    createdAt?: Date;
    /**
     * 
     * @type {AspNetUserDto}
     * @memberof NameCheckDataDto
     */
    createdBy?: AspNetUserDto;
    /**
     * 
     * @type {string}
     * @memberof NameCheckDataDto
     */
    nameCheckGuid?: string;
    /**
     * 
     * @type {PartnerDto}
     * @memberof NameCheckDataDto
     */
    partner?: PartnerDto;
    /**
     * 
     * @type {number}
     * @memberof NameCheckDataDto
     */
    interviewProfileId?: number | null;
}

export function NameCheckDataDtoFromJSON(json: any): NameCheckDataDto {
    return NameCheckDataDtoFromJSONTyped(json, false);
}

export function NameCheckDataDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): NameCheckDataDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
        'dateOfBirth': !exists(json, 'dateOfBirth') ? undefined : (new Date(json['dateOfBirth'])),
        'emailAddress': !exists(json, 'emailAddress') ? undefined : json['emailAddress'],
        'additionalInfo': !exists(json, 'additionalInfo') ? undefined : json['additionalInfo'],
        'matches': !exists(json, 'matches') ? undefined : (json['matches'] === null ? null : (json['matches'] as Array<any>).map(NameCheckMatchDtoFromJSON)),
        'status': !exists(json, 'status') ? undefined : CheckStatusFromJSON(json['status']),
        'source': !exists(json, 'source') ? undefined : CheckSourceFromJSON(json['source']),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : AspNetUserDtoFromJSON(json['createdBy']),
        'nameCheckGuid': !exists(json, 'nameCheckGuid') ? undefined : json['nameCheckGuid'],
        'partner': !exists(json, 'partner') ? undefined : PartnerDtoFromJSON(json['partner']),
        'interviewProfileId': !exists(json, 'interviewProfileId') ? undefined : json['interviewProfileId'],
    };
}

export function NameCheckDataDtoToJSON(value?: NameCheckDataDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'lastName': value.lastName,
        'firstName': value.firstName,
        'dateOfBirth': value.dateOfBirth === undefined ? undefined : (value.dateOfBirth.toISOString()),
        'emailAddress': value.emailAddress,
        'additionalInfo': value.additionalInfo,
        'status': CheckStatusToJSON(value.status),
        'source': CheckSourceToJSON(value.source),
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'createdBy': AspNetUserDtoToJSON(value.createdBy),
        'nameCheckGuid': value.nameCheckGuid,
        'partner': PartnerDtoToJSON(value.partner),
        'interviewProfileId': value.interviewProfileId,
    };
}


