/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum InterviewProfileStatus {
    InvalidEnum = 'InvalidEnum',
    InProgress = 'InProgress',
    Finalized = 'Finalized',
    Deleted = 'Deleted',
    Anonymized = 'Anonymized'
}

export function InterviewProfileStatusFromJSON(json: any): InterviewProfileStatus {
    return InterviewProfileStatusFromJSONTyped(json, false);
}

export function InterviewProfileStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): InterviewProfileStatus {
    return json as InterviewProfileStatus;
}

export function InterviewProfileStatusToJSON(value?: InterviewProfileStatus | null): any {
    return value as any;
}

