import { NameCheckMatchDto } from "api/generated/namecheck"
import moment from "moment"
import { convertBackendDateToMoment } from "utils/dateUtils"

export interface NameCheckMatch extends Omit<NameCheckMatchDto, "dateOfBirth"> {
  dateOfBirth?: moment.Moment
}

export const mapToNameCheckMatch = (dto: NameCheckMatchDto): NameCheckMatch => {
  const data: NameCheckMatch = {
    ...dto,
    dateOfBirth: convertBackendDateToMoment(dto.dateOfBirth),
  }
  return data
}

export const mapToNameCheckMatchDto = (
  data?: NameCheckMatch
): NameCheckMatchDto => {
  const dto: NameCheckMatchDto = {
    ...data,
    dateOfBirth: data?.dateOfBirth ? data?.dateOfBirth.toDate() : undefined,
  }
  return dto
}
