import { useDispatch, useSelector } from "react-redux"
import { authSliceActions, authSliceSelectors } from "./authSlice"
import { Button, Card, Row, Col, Typography, notification, Divider } from "antd"
import {
  useGoogleLogin,
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from "react-google-login"
import { useMemo } from "react"
import useAuth from "./useAuth"
import { useTranslation } from "react-i18next"
import { GoogleOutlined } from "@ant-design/icons"
import { Container } from "components/layout/Container"

const { Title } = Typography

const clientId = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID as string

const Login: React.FC = () => {
  const dispatch = useDispatch()
  const isServerValidatingLogin = useSelector(authSliceSelectors.authIsLoading)
  const { t } = useTranslation()

  const { refreshGoogleTokenSetup } = useAuth()

  const handleGoogleLoginSuccess = async (
    res: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => {
    const tokenId = (res as GoogleLoginResponse).tokenId

    refreshGoogleTokenSetup(res)

    try {
      await dispatch(authSliceActions.logIn(tokenId))
    } catch (error: any) {
      notification.error({
        message: t("errors.loginError.title"),
        description: t("errors.loginError.description"),
        duration: null,
      })
    }
  }

  const handleGoogleLoginFailure = (res: any) => {
    console.log("Login failed:", res)

    if (res.error !== "idpiframe_initialization_failed") {
      notification.error({
        message: t("errors.authenticationError.title"),
        description: t("errors.authenticationError.description"),
        duration: null,
      })
    } else {
      notification.error({
        message: t("errors.authenticationError.title"),
        description: res.details,
        duration: null,
      })
    }
  }

  const { signIn: googleSignIn, loaded } = useGoogleLogin({
    onSuccess: handleGoogleLoginSuccess,
    onFailure: handleGoogleLoginFailure,
    clientId,
    isSignedIn: true, // if true, will call onSuccess callback on load to keep the user signed in.
    prompt: "select_account", // always ask for which account to use
  })

  const loginLoading = useMemo(() => !loaded || isServerValidatingLogin, [
    loaded,
    isServerValidatingLogin,
  ])

  return (
    <Container>
      <Row justify="center">
        <Col xs={24} lg={8}>
          <Card>
            <Title level={2}>{t("pages.Accounts.login.title")}</Title>

            <Divider />

            <Button
              htmlType="submit"
              type="primary"
              size="large"
              block
              loading={loginLoading}
              onClick={googleSignIn}
            >
              <GoogleOutlined />
              {t("pages.Accounts.login.google")}
            </Button>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default Login
