/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserActivationDto
 */
export interface UserActivationDto {
    /**
     * 
     * @type {string}
     * @memberof UserActivationDto
     */
    userInviteCode?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserActivationDto
     */
    isDeactivated?: boolean;
}

export function UserActivationDtoFromJSON(json: any): UserActivationDto {
    return UserActivationDtoFromJSONTyped(json, false);
}

export function UserActivationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserActivationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userInviteCode': !exists(json, 'userInviteCode') ? undefined : json['userInviteCode'],
        'isDeactivated': !exists(json, 'isDeactivated') ? undefined : json['isDeactivated'],
    };
}

export function UserActivationDtoToJSON(value?: UserActivationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userInviteCode': value.userInviteCode,
        'isDeactivated': value.isDeactivated,
    };
}


