import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { api } from "api"
import { FeatureState } from "models/FeatureState"
import { clearStorage, setTokenToStorage } from "./utils"
import { AppThunk, RootState } from "app/store"
import { resetStore } from "app/storeUtils"
import { UserProfileDto } from "api/generated/namecheck"

interface AuthState {
  user?: UserProfileDto
  featureState: FeatureState
}

const initialState: AuthState = {
  user: undefined, // TODO: use getUserFromStorage() kellene?,
  featureState: FeatureState.Initial,
}

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: () => initialState,
    logInStart(state) {
      state.featureState = FeatureState.Loading
    },
    logInSuccess(
      state,
      { payload }: PayloadAction<UserProfileDto | undefined>
    ) {
      state.user = payload
      state.featureState = FeatureState.Success
    },
    logInFailure(state) {
      state.featureState = FeatureState.Error
    },
  },
})

export const authSliceReducer = authSlice.reducer

/**
 * SELECTORS
 */

const userIsLoggedInSelector = (state: RootState): boolean => !!state.auth.user

const authIsLoadingSelector = (state: RootState): boolean =>
  state.auth.featureState === FeatureState.Loading

const userSelector = (state: RootState): UserProfileDto | undefined =>
  state.auth.user

export const authSliceSelectors = {
  userIsLoggedIn: userIsLoggedInSelector,
  authIsLoading: authIsLoadingSelector,
  user: userSelector,
}

/**
 * ACTIONS
 */

const { logInStart, logInSuccess, logInFailure, reset } = authSlice.actions

const logIn = (payload: string): AppThunk => async dispatch => {
  dispatch(logInStart())

  return api.namecheck.account
    .googleAuthenticate({
      externalAuthDto: {
        idToken: payload,
      },
    })
    .then((data: any) => {
      dispatch(logInSuccess(data.profile))

      if (data.token) {
        setTokenToStorage(data.token)
      }
    })
    .catch((error: any) => {
      dispatch(logInFailure())

      return Promise.reject(error)
    })
}

const logOut = (): AppThunk => async (dispatch, getState) => {
  const { auth } = getState()

  if (!auth.user) {
    return
  }

  // Reset whole store (all slices)
  dispatch(resetStore())

  clearStorage()
}

export const authSliceActions = {
  logIn,
  logOut,
  reset,
}
