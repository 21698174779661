/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PartnerActivationDto
 */
export interface PartnerActivationDto {
    /**
     * 
     * @type {number}
     * @memberof PartnerActivationDto
     */
    partnerId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PartnerActivationDto
     */
    isDeactivated?: boolean;
}

export function PartnerActivationDtoFromJSON(json: any): PartnerActivationDto {
    return PartnerActivationDtoFromJSONTyped(json, false);
}

export function PartnerActivationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PartnerActivationDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'partnerId': !exists(json, 'partnerId') ? undefined : json['partnerId'],
        'isDeactivated': !exists(json, 'isDeactivated') ? undefined : json['isDeactivated'],
    };
}

export function PartnerActivationDtoToJSON(value?: PartnerActivationDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'partnerId': value.partnerId,
        'isDeactivated': value.isDeactivated,
    };
}


