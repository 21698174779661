import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { api } from "api"
import { FeatureState } from "models/FeatureState"
import { AppThunk, RootState } from "app/store"
import {
  AllowNameCheckRequest,
  CheckStatus,
  GetNameCheckRequest,
  NameCheckDataDto,
  NameCheckRequest,
  NameCheckRequestDto,
  NameCheckResultDto,
} from "api/generated/namecheck"
import { mapToNameCheckData, NameCheckData } from "models/NameCheckData"

interface NameCheckSingleState {
  featureState: FeatureState
  savingState: FeatureState
  item?: NameCheckData
  result?: NameCheckResultDto
}

const initialState: NameCheckSingleState = {
  featureState: FeatureState.Initial,
  savingState: FeatureState.Initial,
  item: undefined,
  result: undefined,
}

const nameCheckSingleSlice = createSlice({
  name: "nameCheckSingle",
  initialState,
  reducers: {
    resetData: () => ({ ...initialState }),
    fetchItemStart(state) {
      state.featureState = FeatureState.Loading
    },
    fetchItemSuccess(state, { payload }: PayloadAction<NameCheckData>) {
      state.item = payload
      state.featureState = FeatureState.Success
    },
    fetchItemFailure(state) {
      state.featureState = FeatureState.Error
    },
    resetSaveState(state) {
      state.savingState = FeatureState.Initial
    },
    saveItemStart(state) {
      state.savingState = FeatureState.Loading
    },
    saveItemSuccess(state, { payload }: PayloadAction<NameCheckResultDto>) {
      state.result = payload
      state.savingState = FeatureState.Success
    },
    saveItemFailure(state) {
      state.savingState = FeatureState.Error
    },
  },
})

export const nameCheckSingleSliceReducer = nameCheckSingleSlice.reducer

/**
 * SELECTORS
 */

const isDataLoading = (state: RootState): boolean =>
  state.nameCheckSingle.featureState === FeatureState.Loading

const itemSelector = (state: RootState): NameCheckData | undefined =>
  state.nameCheckSingle.item

const successful = (state: RootState): boolean =>
  state.nameCheckSingle.item?.status === CheckStatus.Allowed ||
  state.nameCheckSingle.item?.status === CheckStatus.Successful

const actionNeeded = (state: RootState): boolean =>
  state.nameCheckSingle.item?.status === CheckStatus.Pending

const createMode = (state: RootState): boolean =>
  !state.nameCheckSingle.item?.nameCheckGuid

export const nameCheckSingleSliceSelectors = {
  isDataLoading,
  data: itemSelector,
  interviewProfileCreated: successful,
  actionNeeded,
  createMode,
}

/**
 * ACTIONS
 */

const {
  fetchItemStart,
  fetchItemFailure,
  fetchItemSuccess,
  saveItemStart,
  saveItemFailure,
  saveItemSuccess,
  resetData,
  resetSaveState,
} = nameCheckSingleSlice.actions

const loadData = (id?: string): AppThunk => async dispatch => {
  dispatch(fetchItemStart())
  const request: GetNameCheckRequest = { nameCheckGuid: id }
  return api.namecheck.nameCheck
    .getNameCheck(request)
    .then((data: NameCheckDataDto) => {
      const mappedData: NameCheckData = mapToNameCheckData(data)
      dispatch(fetchItemSuccess(mappedData))
    })
    .catch((error: any) => {
      dispatch(fetchItemFailure())

      return Promise.reject(error)
    })
}

const saveData = (
  data: NameCheckData,
  token?: string
): AppThunk<Promise<NameCheckResultDto>> => async dispatch => {
  dispatch(saveItemStart())

  const request: NameCheckRequest = {
    nameCheckRequestDto: {
      ...(data as NameCheckRequestDto),
      recaptchaResponse: token,
    },
  }
  return api.namecheck.nameCheck
    .nameCheck(request)
    .then((data: NameCheckResultDto) => {
      dispatch(saveItemSuccess(data))
      return Promise.resolve(data)
    })
    .catch((error: any) => {
      dispatch(saveItemFailure())

      return Promise.reject(error)
    })
}

const allowNameCheck = (nameCheckGuid?: string): AppThunk => async dispatch => {
  dispatch(fetchItemStart())
  const request: AllowNameCheckRequest = {
    nameCheckGuid: nameCheckGuid ?? "",
  }
  return api.namecheck.nameCheck
    .allowNameCheck(request)
    .then((data: NameCheckDataDto) => {
      const mappedData: NameCheckData = mapToNameCheckData(data)
      dispatch(fetchItemSuccess(mappedData))
    })
    .catch((error: any) => {
      dispatch(fetchItemFailure())

      return Promise.reject(error)
    })
}

const refuseNameCheck = (
  nameCheckGuid?: string
): AppThunk => async dispatch => {
  dispatch(fetchItemStart())
  const request: AllowNameCheckRequest = {
    nameCheckGuid: nameCheckGuid ?? "",
  }
  return api.namecheck.nameCheck
    .refuseNameCheck(request)
    .then((data: NameCheckDataDto) => {
      const mappedData: NameCheckData = mapToNameCheckData(data)
      dispatch(fetchItemSuccess(mappedData))
    })
    .catch((error: any) => {
      dispatch(fetchItemFailure())

      return Promise.reject(error)
    })
}

export const nameCheckSingleSliceActions = {
  loadData,
  resetData,
  saveData,
  resetSaveState,
  allowNameCheck,
  refuseNameCheck,
}
