/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateContactDto,
    CreateContactDtoFromJSON,
    CreateContactDtoFromJSONTyped,
    CreateContactDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreatePartnerDto
 */
export interface CreatePartnerDto {
    /**
     * 
     * @type {Array<CreateContactDto>}
     * @memberof CreatePartnerDto
     */
    contacts?: Array<CreateContactDto> | null;
    /**
     * 
     * @type {string}
     * @memberof CreatePartnerDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreatePartnerDto
     */
    comment?: string | null;
}

export function CreatePartnerDtoFromJSON(json: any): CreatePartnerDto {
    return CreatePartnerDtoFromJSONTyped(json, false);
}

export function CreatePartnerDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePartnerDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contacts': !exists(json, 'contacts') ? undefined : (json['contacts'] === null ? null : (json['contacts'] as Array<any>).map(CreateContactDtoFromJSON)),
        'name': json['name'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
    };
}

export function CreatePartnerDtoToJSON(value?: CreatePartnerDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contacts': value.contacts === undefined ? undefined : (value.contacts === null ? null : (value.contacts as Array<any>).map(CreateContactDtoToJSON)),
        'name': value.name,
        'comment': value.comment,
    };
}


