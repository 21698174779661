/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    HealthReport,
    HealthReportFromJSON,
    HealthReportToJSON,
} from '../models';

/**
 * 
 */
export class LivenessCheckApi extends runtime.BaseAPI {

    /**
     * HealthCheck futtatása a háttérszervizekre.
     */
    async getHealthReportRaw(): Promise<runtime.ApiResponse<HealthReport>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/v1/LivenessCheck`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HealthReportFromJSON(jsonValue));
    }

    /**
     * HealthCheck futtatása a háttérszervizekre.
     */
    async getHealthReport(): Promise<HealthReport> {
        const response = await this.getHealthReportRaw();
        return await response.value();
    }

}
