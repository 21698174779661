/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HealthStatus,
    HealthStatusFromJSON,
    HealthStatusFromJSONTyped,
    HealthStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface HealthReportEntry
 */
export interface HealthReportEntry {
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof HealthReportEntry
     */
    data?: { [key: string]: any; } | null;
    /**
     * 
     * @type {string}
     * @memberof HealthReportEntry
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HealthReportEntry
     */
    duration?: string;
    /**
     * 
     * @type {any}
     * @memberof HealthReportEntry
     */
    exception?: any | null;
    /**
     * 
     * @type {HealthStatus}
     * @memberof HealthReportEntry
     */
    status?: HealthStatus;
    /**
     * 
     * @type {Array<string>}
     * @memberof HealthReportEntry
     */
    tags?: Array<string> | null;
}

export function HealthReportEntryFromJSON(json: any): HealthReportEntry {
    return HealthReportEntryFromJSONTyped(json, false);
}

export function HealthReportEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): HealthReportEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : json['data'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'exception': !exists(json, 'exception') ? undefined : json['exception'],
        'status': !exists(json, 'status') ? undefined : HealthStatusFromJSON(json['status']),
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
    };
}

export function HealthReportEntryToJSON(value?: HealthReportEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data,
        'description': value.description,
        'duration': value.duration,
        'exception': value.exception,
        'status': HealthStatusToJSON(value.status),
        'tags': value.tags,
    };
}


