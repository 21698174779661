/* tslint:disable */
/* eslint-disable */
/**
 * NameCheck API
 * assembly version: 1.0.0.0
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ListSortDirection {
    Ascending = 'Ascending',
    Descending = 'Descending'
}

export function ListSortDirectionFromJSON(json: any): ListSortDirection {
    return ListSortDirectionFromJSONTyped(json, false);
}

export function ListSortDirectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListSortDirection {
    return json as ListSortDirection;
}

export function ListSortDirectionToJSON(value?: ListSortDirection | null): any {
    return value as any;
}

